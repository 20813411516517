// Imports

// App Imports
import {
  PERIOD_LIST_REQUEST,
  PERIOD_LIST_RESPONSE,
  PERIOD_LIST_FAILURE
} from './actions'

// Elements list

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  periods: []
}

// State
export const periods = (state = elementsInitialState, action) => {
  switch (action.type) {
    case PERIOD_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case PERIOD_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        periods: action.periods
      }

    case PERIOD_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        periods: [],
        error: action.error
      }
    default:
      return state
  }
}