import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { routes } from "../../../setup/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { SubHeader } from "../../../helpers/Typography";
import { Tooltip, TablePagination } from "@material-ui/core";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #F0F0EF;
`;

const StyledTableBody = styled(TableBody)`
  background-color: #FBFBFB;
`;

const StyledTableCell = styled(TableCell)`
  && {
    cursor: pointer;
  }
`;

class ProjectByAreaTableComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      projects: [],
      isLoading: true,
      error: false,
      area: undefined,
      orderBy: "",
      desc: false,
      page: 0,
      rowsPerPage: 5
    };
  }

  async componentWillReceiveProps(nextProps) {
    let projects = [];
    if (nextProps.area) {
      nextProps.area.Projects.forEach(project => {
        const payload = {
          id: project.id,
          name: project.name,
          spotlight: project.Spotlight.name,
          step: project.ProjectStep.name,
          impact: project.impact
        }
        projects.push(payload);
      });
      await this.setState({ projects, isLoading: false, area: nextProps.area });
    }
  }

  redirect = id => {
    this.props.history.push(`${routes.projects.base}/${id}`);
    window.scrollTo(0, 0);
  }

  onSort = async (event, sortKey) => {
    const projects = this.state.projects;
    if (this.state.orderBy === sortKey) {
      await this.setState({ desc: !this.state.desc });
    } else {
      await this.setState({ desc: true, orderBy: sortKey });
    }
    if (this.state.desc) {
      if (this.state.orderBy !== "impact")
        projects.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      else
        projects.sort((a, b) => a[sortKey] - b[sortKey]);
    } else {
      if (this.state.orderBy !== "impact")
        projects.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      else
        projects.sort((a, b) => b[sortKey] - a[sortKey]);
    }
    await this.setState({ projects })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { page, rowsPerPage } = this.state;
    return (
      <React.Fragment>
        <SubHeader>Listado de proyectos</SubHeader>
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
          {!this.props.isLoading &&
            this.props.error && (
              <h1>Ocurrió un error intentando recuperar los proyectos</h1>
            )}
        </StyledDiv>
        <Paper style={{ width: "100%", overflowX: "auto", marginTop: 15 }}>
          {this.state.projects.length < 0 &&
            !this.props.isLoading && (
              <h1>No se encontraron proyectos registrados</h1>
            )}
          {this.state.projects.length > 0 &&
            !this.props.isLoading &&
            !this.props.error && (
              <div>
                <Table style={{ minWidth: 700 }}>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: "28%" }} onClick={e => this.onSort(e, 'name')}>
                        <Tooltip placement="top" title="Pulsa aquí para filtrar por nombre de proyecto">
                          <SubHeader>
                            Nombre del proyecto {" "}
                            {this.state.orderBy === "name" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                          </SubHeader>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "24%" }} onClick={e => this.onSort(e, 'spotlight')}>
                        <Tooltip placement="top" title="Pulsa aquí para filtrar por foco del proyecto">
                          <SubHeader>
                            Foco del proyecto {" "}
                            {this.state.orderBy === "spotlight" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                          </SubHeader>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "27%" }} onClick={e => this.onSort(e, 'impact')}>
                        <Tooltip placement="top" title="Pulsa aquí para filtrar por impacto del proyecto">
                          <SubHeader>
                            Impacto del proyecto {" "}
                            {this.state.orderBy === "impact" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                          </SubHeader>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "21%" }} onClick={e => this.onSort(e, 'step')}>
                        <Tooltip placement="top" title="Pulsa aquí para filtrar por etapa del proyecto">
                          <SubHeader>
                            Etapa del proyecto {" "}
                            {this.state.orderBy === "step" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                          </SubHeader>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  {!this.props.isLoading && (
                    <StyledTableBody>
                      {this.state.projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => {
                        return (
                          <TableRow style={{ cursor: 'pointer' }} key={index} hover onClick={() => this.redirect(project.id)} >
                            <TableCell>
                              <strong style={{ fontFamily: "Lato", fontWeight: "bold" }}>{project.name}</strong>
                            </TableCell>
                            <TableCell>
                              <span style={{ fontFamily: "Lato" }}>{project.spotlight}</span>
                            </TableCell>
                            <TableCell>
                              <span style={{ fontFamily: "Lato" }}>$ {project.impact.toLocaleString('es')}</span>
                            </TableCell>
                            <TableCell>
                              <span style={{ fontFamily: "Lato" }}>{project.step}</span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </StyledTableBody>
                  )}
                </Table>
                <TablePagination component="div" count={this.state.projects.length} rowsPerPage={rowsPerPage} page={page}
                  backIconButtonProps={{ 'arial-label': 'Página anterior' }}
                  nextIconButtonProps={{ 'arial-label': 'Página siguiente' }}
                  labelRowsPerPage="Filas por página"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count} proyectos`}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage} />
              </div>
            )}
        </Paper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.area.area,
    isLoading: state.area.isLoading,
    error: state.area.error
  };
}

export default connect(mapStateToProps, {})(withRouter(withCookies(ProjectByAreaTableComponent)));
