// Imports

// App Imports
import {
  DIMENSION_LIST_REQUEST,
  DIMENSION_LIST_RESPONSE,
  DIMENSION_LIST_FAILURE
} from './actions'

// Elements list

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  dimensions: []
}

// State
export const dimensions = (state = elementsInitialState, action) => {
  switch (action.type) {
    case DIMENSION_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case DIMENSION_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        dimensions: action.dimensions
      }

    case DIMENSION_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        dimensions: [],
        error: action.error
      }
    default:
      return state
  }
}