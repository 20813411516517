// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const GET_COMPANY_REQUEST = 'COMPANY/GET_COMPANY_REQUEST';
export const GET_COMPANY_RESPONSE = 'COMPANY/GET_COMPANY_RESPONSE';
export const GET_COMPANY_FAILURE = 'COMPANY/GET_COMPANY_FAILURE';

// Actions

// Get list of elements
export function getById(id, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: GET_COMPANY_REQUEST,
      isLoading
    });
    try {      
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.get(routesApi.companies.getById(id), { headers })
      const response = await request;
      return dispatch({
        type: GET_COMPANY_RESPONSE,
        company: response.data.company
      });
    } catch (error) {
      return dispatch({
        type: GET_COMPANY_FAILURE,
        error: true,
      });
    }
  }
}