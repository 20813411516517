import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import HighchartsMore from 'highcharts-more';
import * as jwtDecode from "jwt-decode";
import Axios from "axios";
import { routesApi, routes } from "../../../setup/routes";
import { Card } from '@material-ui/core';
import { Subtitle } from "../../../helpers/Typography";
import styled from 'styled-components';

HighchartsMore(ReactHighcharts.Highcharts);
const colors = ["#1b2642", "#3a528f", "#50aa99", "#b8131b", "#ee9842", "#8c92a0", "#1b2642", "#3a528f", "#50aa99", "#b8131b", "#ee9842", "#8c92a0"];

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    background-color: #1b2642;
    padding: 20px;
  }
`;
class ProjectPipelineComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: 'xy',
          height: (7.5 / 16 * 100) + '%'
        },
        title: {
          text: "",
          align: "left",
          style: {
            fontFamily: 'Lato',
            fontSize: 22,
            color: "#212F55"
          }
        },
        xAxis: {
          plotLines: [{
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 1.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 2.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 3.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 4.5,
            width: 1
          }],
          min: 1,
          max: 4,
          startOnTick: false,
          minPadding: 0.025,
          maxPadding: 0.025,
          categories: [
            "Detectar",
            "Idear",
            "Diseñar",
            "Pilotear",
            "Escalar",
          ]
        },
        yAxis: {
          startOnTick: false,
          minPadding: 0.025,
          maxPadding: 0.025,
          categories: [],
          endOnTick: true
        },
        plotOptions: {
          bubble: {
            minSize: 5 + "%",
            maxSize: 15 + "%",
          },
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          },
          series: {
            events: {
              click: function (event) {
                props.history.push(`${routes.projects.brief}/${event.point.id}`);
                window.scrollTo(0, 0);
              }
            },
            zMax: 5,
            dataLabels: {
              align: 'center',
              enabled: true,
              rotation: -90,
              format: '{point.acronym}',
              style: {
                fontSize: "10px",
                color: "black",
                textOutline: "none"
              }
            }
          }
        },
        tooltip: {
          useHTML: true,
          headerFormat: '<table>',
          pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
          '<tr><th>Líder del proyecto:</th><td>{point.leader}</td></tr>' +
          '<tr><th>Impacto en régimen:</th><td>${point.impact}</td></tr>',
          footerFormat: '</table>',
          followPointer: true
        },
        credits: "INNSPIRAL LAB",
        series: [],
      },
      periodId: undefined,
      spotlights: [],
      loaded: false,
      currency: "CLP"
    };
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = Axios.post(routesApi.spotlights.base, { id: user.companyId }, { headers });
      const response = await request;
      this.setState({ spotlights: response.data.spotlight })
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillReceiveProps(nextProps) {
    let series = [];
    let yAxis = {
      startOnTick: false,
      minPadding: 0.025,
      maxPadding: 0.05,
      min: 0,
      max: this.state.spotlights.length - 1,
      title: "Focos",
      categories: []
    };
    await this.state.spotlights.forEach((spotlight) => {
      yAxis.categories.push(spotlight.name);
    });
    if (nextProps.company && nextProps.companyAreas && !this.state.loaded) {
      let blocks = [];
      let projectsOriginal = [];
      let maxZSum = 0;
      nextProps.companyAreas.forEach(area => {
        area.Projects.forEach(project => {
          if (project.impact === 0 || project.impact === null) project.z = 1;
          if (nextProps.company.categoryImpact1 > project.impact && project.impact !== 0 && project.impact !== null) project.z = 1.5;
          if (nextProps.company.categoryImpact1 < project.impact && project.impact < nextProps.company.categoryImpact2) project.z = 3;
          if (project.impact > nextProps.company.categoryImpact2) project.z = 5;
          projectsOriginal.push(project);
        });
      });
      this.setState({ currency: nextProps.company.Currency.name });
      this.state.spotlights.forEach((spotlight, index) => {
        this.state.data.xAxis.categories.forEach((step) => {
          let projects = [];
          let projectCount = 0;
          let zSum = 0;
          projectsOriginal.forEach(project => {
            if (project.ProjectStep.name === step && project.Spotlight.name === spotlight.name) {
              project.zSum = zSum;
              project.y = index;
              project.index = projectCount;
              projects.push(project);
              zSum = zSum + project.z;
              projectCount++;
            }
          });
          if (maxZSum < zSum) maxZSum = zSum;
          blocks.push({ spotlight: spotlight.name, step, projects, projectCount });
        });
      });
      nextProps.companyAreas.forEach((area, index) => {
        let projectAreas = { name: area.name, data: [], color: colors[index] };
        area.Projects.forEach(areaProject => {
          blocks.forEach(block => {
            block.projects.forEach(project => {
              if (areaProject.id === project.id) {
                const payload = {
                  x: (project.ProjectStep.id - 2.5) + ((project.z / maxZSum) / 2) + (project.zSum / maxZSum),
                  y: project.y,
                  z: project.z,
                  id: project.id,
                  step: project.ProjectStep.name,
                  impact: `${project.impact.toLocaleString('es')} ${this.state.currency}`,
                  name: project.name,
                  area: area.name,
                  leader: `${project.leader.firstName} ${project.leader.lastName}`,
                  acronym: project.acronym
                }
                projectAreas.data.push(payload);
              }
            })
          });
        });
        series.push(projectAreas);
      });
      const newState = { data: { ...this.state.data, series, yAxis } };
      this.setState(newState);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <Container>
            <Subtitle style={{ color: "white" }}>Portafolio de proyectos por etapa y foco</Subtitle>
          </Container>
          <div style={{ padding: "30px" }}>
            <ReactHighcharts config={this.state.data} ref="chart_grades" />
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyAreas: state.projects.companyProjects,
    company: state.companies.company,
    isLoading: state.projects.isLoading,
    error: state.projects.error
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(ProjectPipelineComponent)));
