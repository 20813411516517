// Imports

// App Imports
import {
  TOKEN_GET_REQUEST,
  TOKEN_GET_RESPONSE,
  TOKEN_GET_FAILURE,
  SET_TOKEN,
  LOGOUT,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESPONSE,
  FORGOT_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_FAILURE,
  RECOVERY_PASSWORD_RESPONSE,
  RECOVERY_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_RESPONSE
} from "./actions";

const userInitialState = {
  isLoading: false,
  error: undefined,
  token: undefined,
  auth: false,
  user: undefined,
  rol: undefined,
  firstLogin: false
};

export const auth = (state = userInitialState, action) => {
  switch (action.type) {
    case TOKEN_GET_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };

    case TOKEN_GET_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        token: action.token,
        auth: action.auth,
        user: { ...action.user },
        rol: action.rol,
        firstLogin: action.firstLogin
      };

    case TOKEN_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        user: { ...action.user },
        auth: action.auth,
        rol: action.rol
      };
    case LOGOUT:
      return {
        ...state,
        token: action.token,
        user: action.user,
        auth: action.auth
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        forgotError: undefined,
        passwordToken: action.passwordToken,
        isLoading: false
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotError: action.error,
        isLoading: false
      };
    case RECOVERY_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case RECOVERY_PASSWORD_RESPONSE:
      return {
        ...state,
        recovery: action.recovery,
        recoveryError: undefined,
        isLoading: false
      };
    case RECOVERY_PASSWORD_FAILURE:
      return {
        ...state,
        recoveryError: action.error,
        isLoading: false
      };
    case CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case CHANGE_USER_PASSWORD_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        changeError: "",
        message: action.message
      };
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        changeError: action.error
      };
    default:
      return state;
  }
};
