import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { create, getState } from "./api/actions";
import * as moment from "moment";
import { withCookies } from "react-cookie";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Title, Text, TextStrong } from "../../../helpers/Typography";
import { Chip } from "@material-ui/core";

const SubmitButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction; row;
    align-content: flex-start;
    margin: 15px;
  }
`;

const CardTextContainer = styled.div`
  && {
    margin-bottom: 15px;
    width: 100%;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const InverseContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction; row;
    justify-content: space-between;
    align-items: center;
  }
`;

const RealizedChip = styled(Chip)`
  && {
    background-color: #11ab99;
    color: #FFFF;
  }
`;

const PendingChip = styled(Chip)`
  && {
    background-color: #FDCC4D;
    color: #FFFF;
  }
`;

const UnrealizedChip = styled(Chip)`
  && {
    background-color: #DD2E44;
    color: #FFFF;
  }
`;

class DetailsComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: ""
    };
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    this.props.getState(token);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ open: nextProps.open });
  }

  handleClose = async () => {
    this.setState({ open: false });
    this.props.close();
  };

  render() {
    return (
      <Dialog open={this.state.open} aria-labelledby="form-dialog-title" onEscapeKeyDown={this.handleClose}>
        <InverseContainer>
          <Button onClick={this.handleClose}>
            <FontAwesomeIcon size="2x" icon={faTimes} />
          </Button>
          <Title style={{ marginLeft: 10, marginTop: 10 }}>{this.props.milestone.name}</Title>
        </InverseContainer>
        {!this.props.isLoading ? (
          <Container>
            <CardTextContainer>
              <TextContainer>
                <div>
                  <TextStrong>Fecha de realización: </TextStrong>{" "}
                  <Text>{moment(this.props.milestone.date).format("D MMMM")}</Text>
                </div>
                <div>
                  {this.props.milestone.MilestoneState.name === "Pendiente" && (<PendingChip label={this.props.milestone.MilestoneState.name} />)}
                  {this.props.milestone.MilestoneState.name === "Realizado" && (<RealizedChip label={this.props.milestone.MilestoneState.name} />)}
                  {this.props.milestone.MilestoneState.name === "No realizado" && (<UnrealizedChip label={this.props.milestone.MilestoneState.name} />)}
                </div>
              </TextContainer>
            </CardTextContainer>
            <CardTextContainer>
              <div>
                <TextStrong>Descripción del hito</TextStrong>
              </div>
              <div>
                <Text style={{ whiteSpace: "pre-line" }} gutterBottom noWrap>{this.props.milestone.description}</Text>
              </div>
            </CardTextContainer>
            <CardTextContainer>
              <div>
                <TextStrong>Resultados esperados</TextStrong>
              </div>
              <div>
                <Text style={{ whiteSpace: "pre-line" }} gutterBottom noWrap>{this.props.milestone.expectedResults}</Text>
              </div>
            </CardTextContainer>
          </Container>
        ) : (
            <StyledCircularProgress size={100} thickness={6} />
          )}
      </Dialog>
    );
  }
}

PropTypes.DetailsComponent = {
  open: PropTypes.bool.isRequired,
  milestone: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    isLoading: state.milestones.isLoading,
    error: state.milestones.error,
    state: state.milestones.state,
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps,
  { create, getState }
)(withCookies(DetailsComponent));
