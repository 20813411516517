// Imports

// App Imports
import {
  INDUSTRY_LIST_REQUEST,
  INDUSTRY_LIST_RESPONSE,
  INDUSTRY_LIST_FAILURE,
  CREATE_INDUSTRY_REQUEST,
  CREATE_INDUSTRY_RESPONSE,
  CREATE_INDUSTRY_FAILURE
} from './actions'

// Elements list

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  list: []
}

// State
export const industries = (state = elementsInitialState, action) => {
  switch (action.type) {
    case INDUSTRY_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case INDUSTRY_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.list
      }

    case INDUSTRY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        errorMessage: action.errorMessage
      }
    case CREATE_INDUSTRY_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }
    case CREATE_INDUSTRY_RESPONSE:
      let list = [...state.list];
      list.splice(list.length, 0, action.industry);
      return {
        ...state,
        isLoading: false,
        error: false,
        list
      }
    case CREATE_INDUSTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        industry: undefined,
        error: action.error,
        errorMessage: action.errorMessage
      }

    default:
      return state
  }
}