import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NavBar from "../../../common/layout/NavBar";
import { Grid, AppBar, Toolbar, Paper, Typography,  Hidden, Icon, withWidth } from '@material-ui/core'
import { Title, SubtitleStrong } from "../../../../helpers/Typography";
import NewProjectForm from "./NewProjectForm";





const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: {
    flexGrow: 1,
  },
});

class NewProject extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
        <React.Fragment>
        <NavBar />
        
        <Grid container style={{backgroundColor:"#F9FAFC"}} >
        
            <div className={classes.root}>
                <AppBar position="static" color="white">
                    <Toolbar>  
                        <Grid item xs={12}>
                            <Grid container direction="column" alignItems="left" style={{marginTop: '3em', marginBottom: '1.5em'}} >
                                <SubtitleStrong>
                                    Bienvenido a Gestión de Portafolio    
                                </SubtitleStrong>
                                <Title style={{color: '#7894D5'}}>
                                    Proyectos
                                </Title>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
           
            <Grid item xs={12}  style={{margin:"3em"}}>
                <Grid container direction="column">  
                    <Paper style={{backgroundColor:"white"}}>
                        <div style={{marginTop:"2.5em", marginLeft:"1em"}}> 
                            <Title style={{marginBottom:"2em"}}>
                                Nuevo proyecto
                            </Title>
                        </div>


                        <Paper style={{backgroundColor:"#F9FAFC", marginTop:"3em", marginLeft:"3em", marginRight:"3em", marginBottom:"3em"}}>
                        <NewProjectForm/>  
                        </Paper> 



                    </Paper> 
                
                </Grid> 
            </Grid>
            </Grid>
      </React.Fragment>
    )
  }
}



NewProject.propTypes = {
    classes: PropTypes.object.isRequired,
  
  
}



export default withStyles(styles)(NewProject);
