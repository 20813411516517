import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as jwtDecode from 'jwt-decode';
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { routes, routesApi } from "../../../setup/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCaretUp,
  faCaretDown,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { Subtitle, SubHeader } from "../../../helpers/Typography";
import axios from 'axios';
import { PropTypes } from 'prop-types';
import {
  Tooltip,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  Card,
  InputLabel,
  TableFooter,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #7792d8;
`;

const StyledTableBody = styled(TableBody)`
  background-color: #FBFBFB;
`;

const StyledTableCell = styled(TableCell)`
  && {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #8c92a1;
    margin-right: 13px;
    &&:hover {
      background-color: #9e9e9e;
    }
  }
`;

const WhiteSubHeader = styled(SubHeader)`
  color: #FFFFFF;
`;

const Container = styled.div`
  && {
    background-color: #1b2642;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
`;

class ProjectTableComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      projects: [],
      projectsFilters: [],
      areas: [],
      orderBy: "",
      desc: false,
      currency: "CLP",
      page: 0,
      rowsPerPage: 10,
      openFilters: false,
      preArea: "",
      preStep: "",
      area: "",
      step: ""
    };
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const areasPromise = axios.post(routesApi.companies.areas, { companyId: user.companyId }, { headers });
      const areas = await areasPromise;
      this.setState({ areas: areas.data.areas, isLoading: false });
    } catch (error) {
      this.setState({ error: true, isLoading: false });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.companyProjects !== state.companyProjects) {
      let projects = [];
      props.companyProjects.forEach(area => {
        area.Projects.forEach(project => {
          const payload = {
            id: project.id,
            name: project.name,
            area: area.name,
            step: project.ProjectStep.name,
            impact: project.impact,
            leader: project.leader
          }
          projects.push(payload);
        })
      });
      return {
        projects,
        projectsFilters: state.projects.length <= 0 ? projects : state.projectsFilters,
        currency: props.company.Currency.name
      };
    }
    return null;
  }

  redirect = id => {
    this.props.history.push(`${routes.projects.brief}/${id}`);
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }

  onSort = async (event, sortKey) => {
    const projectsFilters = this.state.projectsFilters;
    if (this.state.orderBy === sortKey) {
      await this.setState({ desc: !this.state.desc });
    } else {
      await this.setState({ desc: true, orderBy: sortKey });
    }
    if (this.state.desc) {
      if (this.state.orderBy !== "impact")
        projectsFilters.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      else
        projectsFilters.sort((a, b) => a[sortKey] - b[sortKey]);
    } else {
      if (this.state.orderBy !== "impact")
        projectsFilters.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      else
        projectsFilters.sort((a, b) => b[sortKey] - a[sortKey]);
    }
    await this.setState({ projectsFilters })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeFilter = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilterSubmit = () => {
    let projectsFilters = this.state.projects;
    if (this.state.preStep !== "") {
      projectsFilters = projectsFilters.filter(project => project.step === this.state.preStep);
      this.setState({ step: this.state.preStep });
    }
    if (this.state.preArea !== "") {
      projectsFilters = projectsFilters.filter(project => project.area === this.state.preArea);
      this.setState({ area: this.state.preArea });
    }
    this.setState({ projectsFilters, openFilters: false, orderBy: "" });
  };

  render() {
    const { page, rowsPerPage } = this.state;
    return (
      <React.Fragment>
        <Dialog open={this.state.openFilters} onEscapeKeyDown={() => this.setState({ openFilters: false, preArea: this.state.area, preStep: this.state.step })}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {"Seleccione el área o la etapa de los proyectos para filtrarlos, para limpiar los filtros, dejar los dos campos en 'Todos' o en blanco."}
            </DialogContentText>
            {this.state.areas.length && (
              <FormControl style={{ minWidth: 120, marginRight: 10 }}>
                <InputLabel>Área</InputLabel>
                <Select value={this.state.preArea} onChange={this.handleChangeFilter} name="preArea">
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {this.state.areas.map((area, index) => {
                    return <MenuItem key={index} value={area.name}>{area.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            )}
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel>Etapa</InputLabel>
              <Select value={this.state.preStep} onChange={this.handleChangeFilter} name="preStep">
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {/* <MenuItem value="Detectar">Detectar</MenuItem>
                <MenuItem value="Idear">Idear</MenuItem> */}
                <MenuItem value="Diseñar">Diseñar</MenuItem>
                <MenuItem value="Pilotear">Pilotear</MenuItem>
                <MenuItem value="Escalar">Escalar</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <StyledButton variant="contained" color="primary" onClick={this.handleFilterSubmit}>Filtrar</StyledButton>
            <StyledButton variant="contained" color="primary" onClick={() => this.setState({ openFilters: false, preArea: this.state.area, preStep: this.state.step })}>
              Cancelar
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Card>
        <Container>
            <Subtitle style={{marginLeft: 15, color: "white"}}>Listado de proyectos</Subtitle>
            <div>
              <Tooltip placement="top" title="Pulsa aquí para filtrar por área y/o estapa de los proyectos">
                <StyledButton variant="fab" mini color="secondary" aria-label="Filtros" onClick={() => this.setState({ openFilters: true })}>
                  <FontAwesomeIcon icon={faFilter} />
                </StyledButton>
              </Tooltip>
              </div>
            </Container>
          <StyledDiv>
            {this.props.isLoading && (
              <CircularProgress size={100} thickness={6} />
            )}
            {!this.props.isLoading &&
              this.props.error && (
                <h1>Ocurrió un error intentando recuperar los proyectos</h1>
              )}
          </StyledDiv>
          <div style={{ margin: "0px" }}>
            <Paper style={{ width: "100%", overflowX: "auto" }}>
              {this.state.projectsFilters.length <= 0 &&
                !this.props.isLoading && (
                  <SubHeader>No se encontraron proyectos registrados</SubHeader>
                )}
              {this.state.projectsFilters.length > 0 &&
                !this.props.isLoading &&
                !this.props.error && (
                  <div>
                    <Table style={{ minWidth: 700 }}>
                      <StyledTableHead>
                        <TableRow>
                          <StyledTableCell style={{ width: "27%" }} onClick={e => this.onSort(e, 'name')}>
                            <Tooltip placement="top" title="Pulsa aquí para ordenar por nombre de proyecto">
                              <WhiteSubHeader>
                                Nombre del proyecto {" "}
                                {this.state.orderBy === "name" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                              </WhiteSubHeader>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "24%" }} onClick={e => this.onSort(e, 'area')}>
                            <Tooltip placement="top" title="Pulsa aquí para ordenar por área del proyecto">
                              <WhiteSubHeader>
                                Área {" "}
                                {this.state.orderBy === "area" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                              </WhiteSubHeader>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "18%" }} onClick={e => this.onSort(e, 'impact')}>
                            <Tooltip placement="top" title="Pulsa aquí para ordenar por impacto del proyecto">
                              <WhiteSubHeader>
                                Impacto en régimen {" "}
                                {this.state.orderBy === "impact" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                              </WhiteSubHeader>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "15%" }} onClick={e => this.onSort(e, 'step')}>
                            <Tooltip placement="top" title="Pulsa aquí para ordenar por etapa del proyecto">
                              <WhiteSubHeader>
                                Etapa {" "}
                                {this.state.orderBy === "step" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                              </WhiteSubHeader>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "21%" }}>
                            <Tooltip placement="top" title="Pulsa aquí para ordenar por líder del proyecto">
                              <WhiteSubHeader>
                                Líder del proyecto
                              </WhiteSubHeader>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                      </StyledTableHead>
                      {!this.props.isLoading && (
                          <StyledTableBody>
                            {this.state.projectsFilters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => {
                              return (
                                <Tooltip key={index} title="Click aquí para ingresar a los detalles del proyecto" placement="bottom-start">
                                  <TableRow style={{ cursor: 'pointer' }} hover onClick={() => this.redirect(project.id)} >
                                    <TableCell>
                                      <strong style={{ fontFamily: "Lato", fontWeight: "bold" }}>{project.name}</strong>
                                    </TableCell>
                                    <TableCell><span style={{ fontFamily: "Lato" }}>{project.area}</span></TableCell>
                                    <TableCell>
                                      <span style={{ fontFamily: "Lato" }}>
                                        {Number((project.impact / 1000000).toFixed(2)).toLocaleString("es")}MM {this.state.currency}
                                      </span>
                                    </TableCell>
                                    <TableCell><span style={{ fontFamily: "Lato" }}>{project.step}</span></TableCell>
                                    <TableCell><span style={{ fontFamily: "Lato" }}>{project.leader.firstName + " " + project.leader.lastName}</span></TableCell>
                                  </TableRow>
                                </Tooltip>
                              );
                            })}
                          </StyledTableBody>
                      )}
                    </Table>
                    <TablePagination component="div" count={this.state.projectsFilters.length} rowsPerPage={rowsPerPage} page={page}
                      backIconButtonProps={{ 'arial-label': 'Página anterior' }}
                      nextIconButtonProps={{ 'arial-label': 'Página siguiente' }}
                      labelRowsPerPage="Filas por página"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count} proyectos`}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                  </div>
                )}
            </Paper>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyProjects: state.projects.companyProjects,
    company: state.companies.company,
    isLoading: state.projects.isLoading,
    error: state.projects.error
  };
}

ProjectTableComponent.propTypes = {
  companyProjects: PropTypes.array,
  company: PropTypes.object,
  cookies: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
};

export default connect(mapStateToProps, {})(withRouter(withCookies(ProjectTableComponent)));
