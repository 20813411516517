// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const GET_AREA_PROJECTS_REQUEST = 'AREA/GET_AREA_REQUEST';
export const GET_AREA_PROJECTS_RESPONSE = 'AREA/GET_AREA_RESPONSE';
export const GET_AREA_PROJECTS_FAILURE = 'AREA/GET_COMPANY_FAILURE';

// Actions

// Get list of elements
export function getProjects(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: GET_AREA_PROJECTS_REQUEST,
      isLoading
    });
    try {      
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.areas.projects, payload, { headers });
      const response = await request;
      return dispatch({
        type: GET_AREA_PROJECTS_RESPONSE,
        area: response.data.areas[0]
      });
    } catch (error) {
      return dispatch({
        type: GET_AREA_PROJECTS_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}