// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const PROJECT_LIST_REQUEST = 'PROJECT/GET_LIST_REQUEST';
export const PROJECT_LIST_RESPONSE = 'PROJECT/GET_LIST_RESPONSE';
export const PROJECT_LIST_FAILURE = 'PROJECT/GET_LIST_FAILURE';
export const PROJECT_BY_COMPANY_REQUEST = 'PROJECT/GET_BY_COMPANY_REQUEST';
export const PROJECT_BY_COMPANY_RESPONSE = 'PROJECT/GET_BY_COMPANY_RESPONSE';
export const PROJECT_BY_COMPANY_FAILURE = 'PROJECT/GET_BY_COMPANY_FAILURE';
export const PROJECT_BY_SPOTLIGHT_REQUEST = 'PROJECT/GET_BY_SPOTLIGHT_REQUEST';
export const PROJECT_BY_SPOTLIGHT_RESPONSE = 'PROJECT/GET_BY_SPOTLIGHT_RESPONSE';
export const PROJECT_BY_SPOTLIGHT_FAILURE = 'PROJECT/GET_BY_SPOTLIGHT_FAILURE';
export const PROJECT_BY_AREA_REQUEST = 'PROJECT/GET_BY_AREA_REQUEST';
export const PROJECT_BY_AREA_RESPONSE = 'PROJECT/GET_BY_AREA_RESPONSE';
export const PROJECT_BY_AREA_FAILURE = 'PROJECT/GET_BY_AREA_FAILURE';
export const PROJECT_BY_ID_REQUEST = 'PROJECT/GET_BY_ID_REQUEST';
export const PROJECT_BY_ID_RESPONSE = 'PROJECT/GET_BY_ID_RESPONSE';
export const PROJECT_BY_ID_FAILURE = 'PROJECT/GET_BY_ID_FAILURE';
export const CREATE_PROJECT_REQUEST = 'PROJECT/CREATE_REQUEST';
export const CREATE_PROJECT_RESPONSE = 'PROJECT/CREATE_RESPONSE';
export const CREATE_PROJECT_FAILURE = 'PROJECT/CREATE_FAILURE';
export const EDIT_PROJECT_REQUEST = 'PROJECT/EDIT_REQUEST';
export const EDIT_PROJECT_RESPONSE = 'PROJECT/EDIT_RESPONSE';
export const EDIT_PROJECT_FAILURE = 'PROJECT/EDIT_FAILURE';
export const CLEAN_PROJECT = 'PROJECT/CLEAN_PROJECT';

// Actions

// Get list of elements
export function getList(token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PROJECT_LIST_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.get(routesApi.projects.base, { headers });
      const response = await request;
      return dispatch({
        type: PROJECT_LIST_RESPONSE,
        list: response.data.projects
      });
    } catch (error) {
      return dispatch({
        type: PROJECT_LIST_FAILURE,
        error: true,
      });
    }
  }
}

export function getByCompany(companyId, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PROJECT_BY_COMPANY_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.projects.company, { companyId }, { headers });
      const response = await request;
      return dispatch({
        type: PROJECT_BY_COMPANY_RESPONSE,
        companyProjects: response.data.projects
      });
    } catch (error) {
      return dispatch({
        type: PROJECT_BY_COMPANY_FAILURE,
        error: true,
      });
    }
  }
}

export function getBySpotlight(companyId, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PROJECT_BY_SPOTLIGHT_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.projects.spotlight, { companyId }, { headers });
      const response = await request;
      return dispatch({
        type: PROJECT_BY_SPOTLIGHT_RESPONSE,
        spotlightProjects: response.data.projects
      });
    } catch (error) {
      return dispatch({
        type: PROJECT_BY_SPOTLIGHT_FAILURE,
        error: true,
      });
    }
  }
}

export function getByArea(companyId, areaId, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PROJECT_BY_AREA_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.projects.area, { companyId, areaId }, { headers });
      const response = await request;
      return dispatch({
        type: PROJECT_BY_AREA_RESPONSE,
        areaProjects: response.data.projects
      });
    } catch (error) {
      return dispatch({
        type: PROJECT_BY_AREA_FAILURE,
        error: true,
      });
    }
  }
}

export function getById(id, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PROJECT_BY_ID_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const requestProject = axios.get(routesApi.projects.getById(id), { headers });
      const responseProject = await requestProject;
      const requestPeriod = axios.get(routesApi.period.base, { headers });
      const responsePeriod = await requestPeriod;
      return dispatch({
        type: PROJECT_BY_ID_RESPONSE,
        project: responseProject.data.project,
        period: responsePeriod.data.periods
      })
    } catch (error) {
      return dispatch({
        type: PROJECT_LIST_FAILURE,
        error: true,
      });
    }
  }
}

export function cleanProject() {
  return dispatch => {
    dispatch({
      type: CLEAN_PROJECT
    });
  }
}

export function create(project, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: CREATE_PROJECT_REQUEST,
      isLoading
    });
    try {
      // TODO: Falta implementar JWT
      const payload = { project };
      const request = axios.post(routesApi.projects.base, payload);
      const response = await request;
      dispatch({
        type: CREATE_PROJECT_RESPONSE,
        project: response.data.project
      });
    } catch (error) {
      return dispatch({
        type: CREATE_PROJECT_FAILURE,
        error: true,
      });
    }
  }
}

export function edit(project, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: EDIT_PROJECT_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const update = await axios.put(routesApi.projects.base, project, { headers });
      const request = axios.get(routesApi.projects.getById(project.id), { headers });
      const response = await request;
      dispatch({
        type: EDIT_PROJECT_RESPONSE,
        project: response.data.project
      });
    } catch (error) {
      return dispatch({
        type: EDIT_PROJECT_FAILURE,
        error: true,
      });
    }
  }
}