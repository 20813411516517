import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { recoveryPassword } from "./api/actions";
import {
  FormControl,
  FormLabel,
  FormGroup,
  TextField,
  Button,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { routes } from "../../../setup/routes";
import styled from "styled-components";

const StyledForm = styled.form`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    margin-top: 16px;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #11AB99;
    margin-top: 16px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  && {
    color: red;
  }
`;

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }

  handleClose = () => {
    this.props.redirect();
  };

  render() {
    return (
      <Dialog open={this.state.open}>
        <DialogTitle id="alert-dialog-title">{"¡Exito!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¡Contraseña recuperada exitósamente!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Ir al inicio de sesión
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class RecoveryPasswordComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      payload: {
        token: "",
        newPassword: "",
        repeatPassword: ""
      },
      error: ""
    };
  }

  handleChange = e => {
    this.setState({
      payload: { ...this.state.payload, [e.target.name]: e.target.value }
    });
  };

  redirect = () => {
    this.props.history.push(routes.login);
  }

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.payload.newPassword !== this.state.payload.repeatPassword) {
      await this.setState({ error: "Las contraseñas ingresadas no coinciden" });
      return;
    } else {
      await this.setState({ error: "" });
      this.props.recoveryPassword(this.state.payload);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.recovery) {
      this.props.history.push({
        pathname: routes.login,
        state: { message: "¡Clave recuperada, inicia sesión!" }
      });
    }
  }

  async componentDidMount() {
    if(this.props.auth === false){
      this.props.history.push(routes.home);
    }else{
      const token = this.props.history.location.pathname.replace(
        "/recovery-password/",
        ""
      );
      await this.setState({ payload: { ...this.state.payload, token } });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.recovery && <Modal open={true} redirect={this.redirect} />}
        <StyledForm onSubmit={this.onSubmit} method="post">
          <FormControl component="fieldset">
            <FormLabel component="legend">Recuperar contraseña</FormLabel>
            <FormGroup>
              <StyledTextField
                label="Token"
                type="text"
                name="token"
                id="token"
                disabled
                value={this.state.payload.token}
              />
              <StyledTextField
                label="Nueva contraseña"
                type="password"
                name="newPassword"
                id="newPassword"
                required
                onChange={this.handleChange}
              />
              <StyledTextField
                label="Repita la contraseña"
                type="password"
                name="repeatPassword"
                id="repeatPassword"
                required
                onChange={this.handleChange}
              />
              {this.state.error && (
                <StyledFormHelperText>
                  {this.state.error}
                </StyledFormHelperText>
              )}
              {this.props.error && (
                <StyledFormHelperText>
                  {this.props.error}
                </StyledFormHelperText>
              )}
              <StyledButton
                type="submit"
                color="primary"
                variant="contained"
              >
                Recuperar contraseña
              </StyledButton>
            </FormGroup>
          </FormControl>
        </StyledForm>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.recoveryError,
    recovery: state.auth.recovery,
    auth: state.auth.auth
  };
}

export default connect(
  mapStateToProps,
  { recoveryPassword }
)(withRouter(RecoveryPasswordComponent));
