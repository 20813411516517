// Imports
import axios from "axios";
import { routesApi } from "../../../../setup/routes";

// Actions Types
export const ECONOMIC_INFORMATION_REQUEST = "ECONOMIC_INFORMATION_REQUEST";
export const ECONOMIC_INFORMATION_RESPONSE = "ECONOMIC_INFORMATION_RESPONSE";
export const ECONOMIC_INFORMATION_FAILURE = "ECONOMIC_INFORMATION_FAILURE";
export const REGISTER_ECONOMIC_INFORMATION_REQUEST = "REGISTER_ECONOMIC_INFORMATION_REQUEST";
export const REGISTER_ECONOMIC_INFORMATION_RESPONSE = "REGISTER_ECONOMIC_INFORMATION_RESPONSE";
export const REGISTER_ECONOMIC_INFORMATION_FAILURE = "REGISTER_ECONOMIC_INFORMATION_FAILURE";
export const MONTH_ECONOMIC_INFORMATION_REQUEST = "MONTH_ECONOMIC_INFORMATION_REQUEST";
export const MONTH_ECONOMIC_INFORMATION_RESPONSE = "MONTH_ECONOMIC_INFORMATION_RESPONSE";
export const MONTH_ECONOMIC_INFORMATION_FAILURE = "MONTH_ECONOMIC_INFORMATION_FAILURE";
export const ADD_MONTHLY_BILLING_REQUEST = "ADD_MONTHLY_BILLING_REQUEST";
export const ADD_MONTHLY_BILLING_RESPONSE = "ADD_MONTHLY_BILLING_RESPONSE";
export const ADD_MONTHLY_BILLING_FAILURE = "ADD_MONTHLY_BILLING_FAILURE";

// Actions

// export function getByProject(projectId, periodId, token, isLoading = true) {
//   return async dispatch => {
//     dispatch({
//       type: ECONOMIC_INFORMATION_REQUEST,
//       isLoading
//     });
//     try {
//       const headers = { Authorization: `Bearer ${token}` };
//       const payload = { projectId, periodId };
//       const request = axios.post(routesApi.economicAnalysis.base, payload, {
//         headers
//       });
//       const response = await request;
//       return dispatch({
//         type: ECONOMIC_ANALYSIS_RESPONSE,
//         economicAnalysis: response.data.economicAnalysis
//       });
//     } catch (error) {
//       return dispatch({
//         type: ECONOMIC_ANALYSIS_FAILURE,
//         error: true,
//         errorMessage: error.response.data.error
//       });
//     }
//   };
// }

export function create(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: REGISTER_ECONOMIC_INFORMATION_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.economicInformation.base, payload, { headers });
      const response = await request;
      return dispatch({
        type: REGISTER_ECONOMIC_INFORMATION_RESPONSE,
        created: response.data.economicInformation
      });
    } catch (error) {
      return dispatch({
        type: REGISTER_ECONOMIC_INFORMATION_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function addMonthlyBilling(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: ADD_MONTHLY_BILLING_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.put(routesApi.economicInformation.addMonthlyBilling, payload, { headers });
      const response = await request;
      return dispatch({
        type: ADD_MONTHLY_BILLING_RESPONSE,
        rowsModified: response.data.addBillingPerMonth
      });
    } catch (error) {
      return dispatch({
        type: ADD_MONTHLY_BILLING_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}
