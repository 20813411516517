import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import * as moment from "moment";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import styled from "styled-components";
import { SubHeader } from "../../../helpers/Typography";
const actualYear = moment().format("YYYY");
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();
const Container = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class AreaTotalBillingGraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: "line"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
          ]
        },
        yAxis: {
          labels: {
            format: "$ {value:,.0f}"
          },
          title: {
            visible: false,
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "$ " + (this.y / 1000000).toFixed(2).replace(".", ",") + "M";
              },
            },
            enableMouseTracking: true
          }
        },
        credits: "INNSPIRAL LAB",
        series: []
      },
      periodId: undefined
    };
  }

  async componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedAcumulate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let last = 0;
    if (nextProps.periods) {
      nextProps.periods.map(async period => {
        if (period.year === `Año ${actualYear}`)
          await this.setState({ periodId: period.id });
      });
    }
    if (nextProps.company) {
      last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      await this.setState({ initialMonth: nextProps.company.InitialMonth, currency: nextProps.company.Currency });
      let xAxis = {
        categories: []
      }
      for (let index = 0; index < 12; index++) {
        xAxis.categories.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      }
      const newState = { data: { ...this.state.data, xAxis } };
      this.setState(newState);
    }
    if (nextProps.area && this.state.periodId) {
      nextProps.area.Projects.forEach(project => {
        project.EconomicAnalyses.forEach(economicAnalysis => {
          if (economicAnalysis.periodId === this.state.periodId) {
            if (economicAnalysis.analysisTypeId === 1) {
              realBilling[0] = realBilling[0] + economicAnalysis.month1;
              realBilling[1] = realBilling[1] + economicAnalysis.month2;
              realBilling[2] = realBilling[2] + economicAnalysis.month3;
              realBilling[3] = realBilling[3] + economicAnalysis.month4;
              realBilling[4] = realBilling[4] + economicAnalysis.month5;
              realBilling[5] = realBilling[5] + economicAnalysis.month6;
              realBilling[6] = realBilling[6] + economicAnalysis.month7;
              realBilling[7] = realBilling[7] + economicAnalysis.month8;
              realBilling[8] = realBilling[8] + economicAnalysis.month9;
              realBilling[9] = realBilling[9] + economicAnalysis.month10;
              realBilling[10] = realBilling[10] + economicAnalysis.month11;
              realBilling[11] = realBilling[11] + economicAnalysis.month12;
            } else {
              estimatedBilling[0] = estimatedBilling[0] + economicAnalysis.month1;
              estimatedBilling[1] = estimatedBilling[1] + economicAnalysis.month2;
              estimatedBilling[2] = estimatedBilling[2] + economicAnalysis.month3;
              estimatedBilling[3] = estimatedBilling[3] + economicAnalysis.month4;
              estimatedBilling[4] = estimatedBilling[4] + economicAnalysis.month5;
              estimatedBilling[5] = estimatedBilling[5] + economicAnalysis.month6;
              estimatedBilling[6] = estimatedBilling[6] + economicAnalysis.month7;
              estimatedBilling[7] = estimatedBilling[7] + economicAnalysis.month8;
              estimatedBilling[8] = estimatedBilling[8] + economicAnalysis.month9;
              estimatedBilling[9] = estimatedBilling[9] + economicAnalysis.month10;
              estimatedBilling[10] = estimatedBilling[10] + economicAnalysis.month11;
              estimatedBilling[11] = estimatedBilling[11] + economicAnalysis.month12;
              estimatedAcumulate[0] = estimatedAcumulate[0] + economicAnalysis.month1;
              estimatedAcumulate[1] = estimatedAcumulate[1] + economicAnalysis.month2;
              estimatedAcumulate[2] = estimatedAcumulate[2] + economicAnalysis.month3;
              estimatedAcumulate[3] = estimatedAcumulate[3] + economicAnalysis.month4;
              estimatedAcumulate[4] = estimatedAcumulate[4] + economicAnalysis.month5;
              estimatedAcumulate[5] = estimatedAcumulate[5] + economicAnalysis.month6;
              estimatedAcumulate[6] = estimatedAcumulate[6] + economicAnalysis.month7;
              estimatedAcumulate[7] = estimatedAcumulate[7] + economicAnalysis.month8;
              estimatedAcumulate[8] = estimatedAcumulate[8] + economicAnalysis.month9;
              estimatedAcumulate[9] = estimatedAcumulate[9] + economicAnalysis.month10;
              estimatedAcumulate[10] = estimatedAcumulate[10] + economicAnalysis.month11;
              estimatedAcumulate[11] = estimatedAcumulate[11] + economicAnalysis.month12;
            }
          }
        });
      });
    }
    realBilling.reduce((x, y, index) => (realBilling[index] = x + y));
    estimatedBilling.reduce((x, y, index) => (estimatedBilling[index] = x + y));
    const bestEstimated = [realBilling[last]];
    let acumulated = realBilling[last];
    estimatedAcumulate.forEach((value, index) => {
      if (index > last) {
        acumulated = acumulated + value;
        bestEstimated.push(acumulated);
      }
    });
    realBilling.forEach((real, index) => {
      if (index > last) {
        realBilling[index] = null;
      }
    });
    const series = [
      { name: "Real", data: realBilling, color: "#8c92a1" },
      { name: "Proyectado", data: estimatedBilling, color: "#11AB99" }
    ];
    series.push({
      name: "Mejor estimado",
      data: bestEstimated,
      pointStart: last,
      color: "#ba0f0f",
      zones: [{
        dashStyle: 'dot'
      }]
    });
    const newState = { data: { ...this.state.data, series } };
    this.setState(newState);
  }

  handleChange = async event => {
    await this.setState({ periodId: event.target.value });
    let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (this.props.area && this.state.periodId) {
      this.props.area.Projects.forEach(project => {
        project.EconomicAnalyses.forEach(economicAnalysis => {
          if (economicAnalysis.periodId === this.state.periodId) {
            if (economicAnalysis.analysisTypeId === 1) {
              realBilling[0] = realBilling[0] + economicAnalysis.month1;
              realBilling[1] = realBilling[1] + economicAnalysis.month2;
              realBilling[2] = realBilling[2] + economicAnalysis.month3;
              realBilling[3] = realBilling[3] + economicAnalysis.month4;
              realBilling[4] = realBilling[4] + economicAnalysis.month5;
              realBilling[5] = realBilling[5] + economicAnalysis.month6;
              realBilling[6] = realBilling[6] + economicAnalysis.month7;
              realBilling[7] = realBilling[7] + economicAnalysis.month8;
              realBilling[8] = realBilling[8] + economicAnalysis.month9;
              realBilling[9] = realBilling[9] + economicAnalysis.month10;
              realBilling[10] = realBilling[10] + economicAnalysis.month11;
              realBilling[11] = realBilling[11] + economicAnalysis.month12;
            } else {
              estimatedBilling[0] = estimatedBilling[0] + economicAnalysis.month1;
              estimatedBilling[1] = estimatedBilling[1] + economicAnalysis.month2;
              estimatedBilling[2] = estimatedBilling[2] + economicAnalysis.month3;
              estimatedBilling[3] = estimatedBilling[3] + economicAnalysis.month4;
              estimatedBilling[4] = estimatedBilling[4] + economicAnalysis.month5;
              estimatedBilling[5] = estimatedBilling[5] + economicAnalysis.month6;
              estimatedBilling[6] = estimatedBilling[6] + economicAnalysis.month7;
              estimatedBilling[7] = estimatedBilling[7] + economicAnalysis.month8;
              estimatedBilling[8] = estimatedBilling[8] + economicAnalysis.month9;
              estimatedBilling[9] = estimatedBilling[9] + economicAnalysis.month10;
              estimatedBilling[10] = estimatedBilling[10] + economicAnalysis.month11;
              estimatedBilling[11] = estimatedBilling[11] + economicAnalysis.month12;
            }
          }
        });
      });
    }
    realBilling.reduce((x, y, index) => (realBilling[index] = x + y));
    estimatedBilling.reduce((x, y, index) => (estimatedBilling[index] = x + y));
    const series = [
      { name: "Real", data: realBilling, color: "#8c92a1" },
      { name: "Proyectado", data: estimatedBilling, color: "#11AB99" }
    ];
    const newState = { data: { ...this.state.data, series } };
    this.setState(newState);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.periodId && (
          <Container>
            <SubHeader>Facturación real vs presupuestado de los proyectos</SubHeader>
            <FormControl>
              <InputLabel>Periodo</InputLabel>
              <Select value={this.state.periodId} onChange={this.handleChange}>
                {this.props.periods.map((period, index) => {
                  return (
                    <MenuItem key={index} value={period.id}>
                      {period.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Container>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.data.series.length && !this.props.isLoading ? (
          <ReactHighcharts config={this.state.data} ref="chart_grades" />
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
      </React.Fragment>
    );
  }
}

// Component State
function mapStateToProps(state) {
  return {
    company: state.companies.company,
    area: state.area.area,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    companyProjects: state.projects.companyProjects
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(AreaTotalBillingGraphComponent)));
