import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputAdornment
} from "@material-ui/core";

class EditProjectImpact extends Component {
  constructor(props) {
    super();
    this.state = {
      impact: props.impact === null ? 0 : props.impact
    };
  }

  onSubmit = () => {
    const { impact } = this.state;
    const states = { impact: Number(impact), editImpact: false };
    this.props.changeState(states);
  }

  render() {
    const { impact } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} style={{ minWidth: "600px" }}>
        <DialogTitle>Editar impacto en régimen</DialogTitle>
        <DialogContent>
          <TextField
            value={impact}
            name="impact"
            type="number"
            fullWidth
            onChange={e => this.setState({ impact: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            style={{ color: "#009688" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={this.onSubmit}
            color="primary"
            style={{ color: "#009688" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditProjectImpact;
