// Imports
import React from "react";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { withRouter } from 'react-router-dom';
import { routes } from "../../setup/routes";
import * as jwtDecode from "jwt-decode";
// Component
class Home extends React.Component {

  componentDidMount() {
    if (typeof this.props.cookies.get("token") === 'undefined') {
      this.props.history.push(routes.login);
    } else {
      const token = this.props.cookies.get("token");
      const user = jwtDecode(token).user
      switch (user.rol) {
        case "Administrador":
          this.props.history.push(routes.home)
          break;
        case "Gerente de Innovación":
          this.props.history.push(routes.company.dashboard)
          break;
        case "Gerente de Área":
          this.props.history.push(routes.company.dashboard)
          break;
        case "Líder de proyecto":
          this.props.history.push(routes.company.dashboard)
          break;
        default:
          console.log("Error");
          break;
      }
    }
  }

  render() {
    return (
      <div>
        <h1>Home</h1>
        <p>
          Cur historia peregrinationes? Nunquam carpseris victrix. A falsis,
          classis alter rector. Adelphis velox cedrium est. Cadunt sed mire
          ducunt ad albus onus. Agripetas manducare in varius rugensis civitas!
        </p>
        {this.props.firstLogin && (
          <div>
            <p>Este es tu primer inicio de sesión</p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    firstLogin: state.auth.firstLogin,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withCookies(withRouter(Home)));
