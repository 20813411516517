import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Divider, Card, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table, TableHead, TableBody, TableCell, TableRow, Dialog, DialogTitle, DialogActions, Button, DialogContent, DialogContentText, Stepper, Step, StepLabel, Tooltip } from '@material-ui/core';
import { SubHeader, Subtitle, Text, TextP2 } from '../../../helpers/Typography';
import styled from 'styled-components';
import moment from "moment";
import { edit } from './api/actions';
import { withCookies } from 'react-cookie';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepForward, faCircle } from '@fortawesome/free-solid-svg-icons';
import { icon } from '../../../helpers/StateIcon';

const StyledButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-right: 13px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledTable = styled(Table)`
  && {
    min-width: 300px;
  }
`;

const StyledDiv = styled.div`
  && {
    overflow-x: auto;
    width: 100%
  }
`;

const styles = () => ({
  expansionPanelSummary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#1b2642"
  },
  expanded: {margin: '0px'},
  tableCell: {
    padding: '4px 10px 4px 10px',
  }
});



class ProjectMilestoneTable extends Component {
  constructor() {
    super();
    this.state = {
      project: undefined,
      confirmDialog: false,
      open: 0,
      error: false,
      reload: true
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.reload || props.project !== state.project) {
      return {
        project: props.project,
        // milestonesProjects: props.project.milestonesProjects,
        reload: false
      };
    }
    return null;
  }

  validateStep = (actualStep, step) => {
    if (actualStep === step) {
      return { text: "En desarrollo", color: "#FDC500", icon: icon(2) }
    } else if (actualStep === "Pilotear" && step === "Diseñar") {
      return { text: "Completado", color: "#518C23", icon: icon(3) }
    } else {
      return { text: "Por desarrollar", color: "#5c5f66", icon: icon(1) }
    }
  }

  changeState = () => {
    const token = this.props.cookies.get("token");
    const payload = {
      ...this.state.project,
      changeStep: true,
      projectStepId: this.state.project.ProjectStep.id + 1,
      impact: this.state.project.impact,
      implementationDate: this.state.project.implementationDate === null ? null : moment(this.state.project.implementationDate).format("YYYY-MM-DD"),
      problem: this.state.project.problem,
      solution: this.state.project.solution,
      objetives: this.state.project.objetives,
      benefits: this.state.project.benefits,
      areaId: this.state.project.Area.id,
      spotlightId: this.state.project.Spotlight.id
    };
    console.log({payload});
    try {
      this.props.edit(payload, token);
      this.props.reload();
      this.setState({ confirmDialog: false, reload: true });
    } catch (error) {
      this.setState({ error: true });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <Dialog open={this.state.confirmDialog} onClose={() => this.setState({ confirmDialog: false })} onEscapeKeyDown={() => this.setState({ confirmDialog: false, error: false })}>
          <DialogTitle>¿Estás seguro de pasar el proyecto a la siguiente etapa?</DialogTitle>
          <DialogContent>Atención: Al finalizar la etapa el estado de todos los hitos será completado</DialogContent>
          {this.state.error && (
            <DialogContent>
              <DialogContentText style={{ color: "red" }}>Ocurrió un error pasando el proyecto a la siguiente etapa</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <StyledButton variant="contained" color="primary" onClick={this.changeState}>Confirmar</StyledButton>
            <StyledButton variant="contained" color="primary" onClick={() => this.setState({ confirmDialog: false, error: false })}>
              Cancelar
            </StyledButton>
          </DialogActions>
        </Dialog>
        {this.state.project && (
          <div style={{ height: 810, cursor: 'default' }}>
            <ExpansionPanel expanded={true} classes={{expanded: classes.expanded}} style={{cursor: 'default'}}>
              <ExpansionPanelSummary className={classes.expansionPanelSummary}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  <Subtitle style={{ color: "white" }}>Etapas del proyecto</Subtitle>
                  {/* <Stepper alternativeLabel activeStep={this.state.project.ProjectStep.id - 4}>
                    <Step>
                      <StepLabel>Diseñar</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Pilotear</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Escalar</StepLabel>
                    </Step>
                  </Stepper> */}
                </div>
              </ExpansionPanelSummary>
            </ExpansionPanel>
            {this.state.project.milestonesProjects.map((milestoneProject, index) => (
              <div key={index}>{(milestoneProject.step === "Diseñar" || milestoneProject.step === "Pilotear")  && (
              <ExpansionPanel expanded={true} classes={{expanded: classes.expanded}}>
                <ExpansionPanelSummary>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "baseline" }}  >
                    <div>
                    {/* <span style={{backgroundColor: "#000000", textAlign: "center"}}>
                      {this.validateStep(this.state.project.ProjectStep.name, milestoneProject.step).icon}
                      </span> */}
                      <SubHeader>{(index + 1) + ". " + milestoneProject.step}</SubHeader>
                    </div>
                    <div>
                      <SubHeader style={{ color: this.validateStep(this.state.project.ProjectStep.name, milestoneProject.step).color }}>{this.validateStep(this.state.project.ProjectStep.name, milestoneProject.step).text}</SubHeader>
                    </div>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <StyledDiv>
                    <StyledTable style={{ tableLayout: 'auto' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <Text>Hito</Text>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Text>Estado</Text>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Text>Deadline {"  "}</Text>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Text>Fecha Real</Text>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {milestoneProject.milestones.map((milestone, mIndex) => (
                          <TableRow key={mIndex}>
                            <TableCell className={classes.tableCell}>
                              <TextP2>{milestone.milestone}</TextP2></TableCell>
                            <TableCell className={classes.tableCell}>
                              <Tooltip title={milestone.state} placement="right">
                                <FontAwesomeIcon style={{ color: milestone.color }} icon={faCircle} />
                              </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextP2 style={{ display: "inline", whiteSpace: "nowrap" }}>{moment(milestone.deadline).format("DD-MM-YYYY")}</TextP2></TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextP2 style={{ display: "inline", whiteSpace: "nowrap" }}>{moment(milestone.realDate).format("DD-MM-YYYY")}</TextP2></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                    <div style={{display: 'flex'}}>
                      {this.state.project.ProjectStep.name === milestoneProject.step && (
                        <Button style={{ marginLeft: 'auto', marginTop: '3px', textTransform: 'none'}} variant="contained" color="primary" onClick={() => this.setState({ confirmDialog: true })} >Finalizar etapa</Button>
                      )} 
                    </div>
                  </StyledDiv>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              )}</div>
            ))}
            <ExpansionPanel expanded={true} classes={{expanded: classes.expanded}}>
              <ExpansionPanelSummary>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "baseline" }}  >
                  <div>
                    {/* <span style={{backgroundColor: "#000000", textAlign: "center"}}>
                      {icon(3)}
                    </span> */}
                    <SubHeader>{"3. Escalar"}</SubHeader>
                  </div>
                  <SubHeader style={{ color: "#5c5f66" }}>{"Por desarrollar"}</SubHeader>
                </div>
              </ExpansionPanelSummary>
            </ExpansionPanel>
          </div>
        )}
      </Card>
    );
  }
}

ProjectMilestoneTable.propTypes = {
  edit: PropTypes.func,
  reload: PropTypes.func,
  classes: PropTypes.object,
  project: PropTypes.object,
  cookies: PropTypes.object
}

function mapStateToProps(state) {
  return {
    project: state.projects.project
  };
}

export default connect(
  mapStateToProps, { edit }
)(withCookies(withStyles(styles)(ProjectMilestoneTable)));