import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  MenuItem
} from "@material-ui/core";
import { routesApi } from "../../../setup/routes";
import axios from "axios";
import { withCookies } from "react-cookie";
import * as moment from 'moment';

class EditActivityModal extends Component {
  constructor(props) {
    super();
    this.state = {
      id: props.activity.id,
      name: props.activity.name,
      milestoneProjectId: props.activity.MilestoneProject.id,
      comment: props.activity.comment,
      deadline: moment(props.activity.deadline).format("YYYY-MM-DD") ,
      token: props.cookies.get("token"),
      created: false,
      loading: false,
      error: false,
    };
  }

  onSubmit = async () => {
    this.setState({ loading: true });
    const { id, name, milestoneProjectId, comment, token } = this.state;
    const payload = { id, name, comment, milestoneProjectId, type: 'changeInfo' };
    const headers = { Authorization: `Bearer ${token}` };
    try {
      await axios.put(routesApi.projects.activities, payload, { headers });
      this.setState({ created: true, loading: false });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  validateForm = () => {
    const { name, milestoneProjectId } = this.state;
    if (name !== "" && milestoneProjectId !== 0) return false;
    return true;
  };

  endActivity = async () => {
    const { id, token } = this.state;
    const payload = { id, type: 'doneActivity' };
    const headers = { Authorization: `Bearer ${token}` };
    try {
      await axios.put(routesApi.projects.activities, payload, { headers });
      this.setState({ created: true, loading: false });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  render() {
    const { open, onClose } = this.props;
    const { name, milestoneProjectId, comment, created, loading, error } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <Dialog open={loading} style={{ minWidth: "600px" }}>
            <DialogTitle>Actualizando la actividad</DialogTitle>
          </Dialog>
        )}
        {error && (
          <Dialog open={error} style={{ minWidth: "600px" }}>
            <DialogTitle>Ocurrió un error actualizando la actividad</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.setState({error: false})}
                color="primary"
                style={{ color: "#009688" }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {created && (
          <Dialog open={created} style={{ minWidth: "600px" }}>
            <DialogTitle>Actividad actualizada con éxito</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => window.location.reload()}
                color="primary"
                style={{ color: "#009688" }}
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {(!created || !loading || !error) && (
          <Dialog open={open} style={{ minWidth: "600px" }}>
          <DialogTitle>Actualizar status de actividad</DialogTitle>
          <DialogContent>
            <Grid container justify="center" spacing={40}>
              <Grid item xs={10}>
                <TextField
                  value={name}
                  label="Título de la actividad"
                  name="title"
                  type="text"
                  fullWidth
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <TextField
                  value={milestoneProjectId}
                  label="Hito asociado"
                  name="title"
                  type="text"
                  fullWidth
                  select
                  onChange={e =>
                    this.setState({ milestoneProjectId: e.target.value })
                  }
                >
                  {this.props.milestoneProjects.map(milestoneProject => (
                    <MenuItem
                      key={milestoneProject.id}
                      value={milestoneProject.id}
                    >
                      {milestoneProject.milestone}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={comment}
                  name="comment" 
                  label="Comentarios"
                  multiline
                  fullWidth
                  onChange={e =>
                    this.setState({ comment: e.target.value })
                  }
                />
              </Grid>
              {this.props.activity.milestoneStateId !== 4 && 
                <Button 
                  onClick={() => this.endActivity()} 
                  variant="contained" 
                  style={{ backgroundColor: "#009688", color: "#FFFF" }}
                >
                  Finalizar actividad
                </Button>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="primary"
              style={{ color: "#009688" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={this.onSubmit}
              color="primary"
              style={{ color: "#009688" }}
              disabled={this.validateForm()}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default withCookies(EditActivityModal);
