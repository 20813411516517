import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  AppBar,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  Button
} from "@material-ui/core";
import styled from "styled-components";
import { Text, TextStrong } from "../../../helpers/Typography";
import { withStyles } from "@material-ui/core/styles";

const StyledTabs = styled(Tabs)`
  && {
    margin: 0;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-width: 50px;
    text-transform: initial;
  }
`;

const CardMargin = styled.div`
  && {
    margin: 4%;
  }
`;

const styles = theme => ({
  tabIndicator: {
    backgroundColor: "#1b2642"
    // top: 0
  },
  root: {
    backgroundColor: "#7792d8",
    color: "#FFFFFF",
    opacity: 1
  },
  selected: {
    backgroundColor: "#FFFFFF",
    color: "#000000"
  }
});

class ProjectInformationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: undefined,
      value: 0,
      file: undefined
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.project !== state.project) {
      return {
        project: props.project
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({ project: undefined });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card style={{ height: 300 }}>
        <div>
          {!this.state.project ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <CircularProgress size={100} thickness={6} />{" "}
            </div>
          ) : (
            <div>
              <AppBar
                color="inherit"
                elevation={0}
                position="static"
                style={{ width: "100%", boxShadow: "none" }}
              >
                <StyledTabs
                  classes={{ indicator: classes.tabIndicator }}
                  value={this.state.value}
                  onChange={this.handleChange}
                  variant="fullWidth"
                >
                  <StyledTab
                    classes={{ selected: classes.selected, root: classes.root }}
                    label="Equipo"
                  />
                  <StyledTab
                    classes={{ selected: classes.selected, root: classes.root }}
                    label="Acerca de"
                  />
                </StyledTabs>
              </AppBar>
              <CardMargin style={{ minHeight: 123 }}>
                <div>
                  {this.state.value === 0 && (
                    <Grid container direction="column" justify="space-between">
                      <Grid item xs={"auto"}>
                        <div>
                          <TextStrong>Lider de proyecto: </TextStrong>
                          <Text>
                            {this.state.project.leader.firstName +
                              " " +
                              this.state.project.leader.lastName}
                          </Text>
                        </div>
                        <div>
                          <TextStrong>Sponsor: </TextStrong>
                          <Text>
                            {this.state.project.sponsor.firstName +
                              " " +
                              this.state.project.sponsor.lastName}
                          </Text>
                        </div>
                        <div>
                          <TextStrong>Equipo: </TextStrong>
                          <Text>{this.state.project.team}</Text>
                        </div>
                      </Grid>
                      {this.props.edit && (
                        <Grid item xs={"auto"}>
                          <Grid container justify="flex-end">
                            <Button 
                              style={{ backgroundColor: "#009688", color: "#FFFF" }} 
                              onClick={() => this.props.openEditDetails("team")}
                            >
                              Editar
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {this.state.value === 1 && (
                    <Grid container direction="column" justify="space-between">
                      <Grid item xs={"auto"}>
                        <div>
                          <TextStrong>Foco de Innovación: </TextStrong>
                          <Text>{this.state.project.Spotlight.name}</Text>
                        </div>
                        {this.state.project.filters.map((filter, index) => (
                          <div key={index}>
                            <TextStrong>{filter.name}: </TextStrong>
                            {filter.options.map((option, findex) => (
                              <Text key={findex}>{option.name}</Text>
                            ))}
                          </div>
                        ))}
                      </Grid>
                      {this.props.edit && (
                        <Grid item xs={"auto"}>
                          <Grid container justify="flex-end">
                          <Button 
                              style={{ backgroundColor: "#009688", color: "#FFFF" }} 
                              onClick={() => this.props.openEditDetails("about")}
                            >
                              Editar
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </div>
              </CardMargin>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

ProjectInformationComponent.propTypes = {
  project: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    isLoading: state.projects.isLoading,
    error: state.projects.error
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(ProjectInformationComponent)
);
