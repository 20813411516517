import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import * as moment from "moment";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Card
} from "@material-ui/core";
import styled from "styled-components";
import { Subtitle, TextStrong } from "../../../helpers/Typography";
const actualYear = moment().format("YYYY");
const colors = ["#1b2642", "#3a528f", "#50aa99", "#b8131b", "#ee9842", "#8c92a0"];

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    background-color: #1b2642;
    padding: 20px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class StackedAreaGraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: 'area'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: ["Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"],
          tickmarkPlacement: 'on',
          title: {
            enabled: false
          }
        },
        yAxis: {
          title: {
            text: 'Dólares'
          },
          labels: {
            formatter: function () {
              return "$ " + (this.value / 1000000).toFixed(1).replace(".", ",") + "M";
            }
          }
        },
        tooltip: {
          // split: true,
          valueSuffix: ' dólares'
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            fillOpacity: 0.75,
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666'
            }
          }
        },
        series: [],
        credits: ""
      },
      periodId: undefined
    };
  }

  async componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.company) {
      let xAxis = {
        categories: []
      }
      for (let index = 0; index < 12; index++) {
        xAxis.categories.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMM"));
      }
      const newState = { data: { ...this.state.data, xAxis } };
      await this.setState(newState);
    }
    if (nextProps.periods) {
      nextProps.periods.map(async period => {
        if (period.year === `Año ${actualYear}`)
          await this.setState({ periodId: period.id });
      });
    }
    const series = [];
    const projects = [];
    await this.setState({ periodId: nextProps.periodId });
    if (nextProps.companyProjects && this.state.periodId) {
      nextProps.companyProjects.forEach(area => {
        area.Projects.forEach(project => {
          project.EconomicAnalyses.forEach(economicAnalysis => {
            let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            if (economicAnalysis.periodId === this.state.periodId) {
              if (economicAnalysis.analysisTypeId === 1) {
                realBilling[0] = realBilling[0] + economicAnalysis.month1;
                realBilling[1] = realBilling[1] + economicAnalysis.month2;
                realBilling[2] = realBilling[2] + economicAnalysis.month3;
                realBilling[3] = realBilling[3] + economicAnalysis.month4;
                realBilling[4] = realBilling[4] + economicAnalysis.month5;
                realBilling[5] = realBilling[5] + economicAnalysis.month6;
                realBilling[6] = realBilling[6] + economicAnalysis.month7;
                realBilling[7] = realBilling[7] + economicAnalysis.month8;
                realBilling[8] = realBilling[8] + economicAnalysis.month9;
                realBilling[9] = realBilling[9] + economicAnalysis.month10;
                realBilling[10] = realBilling[10] + economicAnalysis.month11;
                realBilling[11] = realBilling[11] + economicAnalysis.month12;
                projects.push({ name: project.name, projectTotal: realBilling.reduce((x, y) => x + y), realBilling: realBilling });
              }
            }
          });
        });
      });
      projects.sort((a, b) => b.projectTotal - a.projectTotal);
      const others = [];
      projects.forEach((project, index) => {
        if (index < 5) {
          const data = [];
          const payload = { name: project.name, data: [], marker: { symbol: "circle" }, color: colors[index] };
          project.realBilling.forEach((value, index) => {
            const monthsSliced = project.realBilling.slice(0, index + 1);
            data[index] = monthsSliced.reduce((x, y) => x + y);
            payload.data = data;
          });
          series.push(payload);
        } else {
          const payload = { name: project.name, data: project.realBilling, marker: { symbol: "circle" } };
          others.push(payload);
          
        }
      });
      const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      others.forEach(other => {
        months[0] = other.data[0] + months[0];
        months[1] = other.data[1] + months[1];
        months[2] = other.data[2] + months[2];
        months[3] = other.data[3] + months[3];
        months[4] = other.data[4] + months[4];
        months[5] = other.data[5] + months[5];
        months[6] = other.data[6] + months[6];
        months[7] = other.data[7] + months[7];
        months[8] = other.data[8] + months[8];
        months[9] = other.data[9] + months[9];
        months[10] = other.data[10] + months[10];
        months[11] = other.data[11] + months[11];
      });
      const data = [];
      months.forEach((month, index) => {
        const monthsSliced = months.slice(0, index + 1);
        data[index] = monthsSliced.reduce((x, y) => x + y);
      });
      const payload = { name: "Otros", data, marker: { symbol: "circle" } };
      await series.splice(series.length, 0, payload);
      const newState = { data: { ...this.state.data, series } };
      await this.setState(newState);
    }
  }

  handleChange = async event => {
    await this.setState({ periodId: event.target.value });
    const series = [];
    const projects = [];
    if (this.props.companyProjects && this.state.periodId) {
      this.props.companyProjects.forEach(area => {
        area.Projects.forEach(project => {
          project.EconomicAnalyses.forEach(economicAnalysis => {
            let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            if (economicAnalysis.periodId === this.state.periodId) {
              if (economicAnalysis.analysisTypeId === 1) {
                realBilling[0] = realBilling[0] + economicAnalysis.month1;
                realBilling[1] = realBilling[1] + economicAnalysis.month2;
                realBilling[2] = realBilling[2] + economicAnalysis.month3;
                realBilling[3] = realBilling[3] + economicAnalysis.month4;
                realBilling[4] = realBilling[4] + economicAnalysis.month5;
                realBilling[5] = realBilling[5] + economicAnalysis.month6;
                realBilling[6] = realBilling[6] + economicAnalysis.month7;
                realBilling[7] = realBilling[7] + economicAnalysis.month8;
                realBilling[8] = realBilling[8] + economicAnalysis.month9;
                realBilling[9] = realBilling[9] + economicAnalysis.month10;
                realBilling[10] = realBilling[10] + economicAnalysis.month11;
                realBilling[11] = realBilling[11] + economicAnalysis.month12;
                projects.push({ name: project.name, projectTotal: realBilling.reduce((x, y) => x + y), realBilling: realBilling });
              }
            }
          });
        });
      });
      projects.sort((a, b) => b.projectTotal - a.projectTotal);
      const others = [];
      projects.forEach((project, index) => {
        if (index < 5) {
          const data = [];
          const payload = { name: project.name, data: [], marker: { symbol: "circle" } };
          project.realBilling.forEach((value, index) => {
            const monthsSliced = project.realBilling.slice(0, index + 1);
            data[index] = monthsSliced.reduce((x, y) => x + y);
            payload.data = data;
          });
          series.push(payload);
        } else {
          const payload = { name: project.name, data: project.realBilling, marker: { symbol: "circle" } };
          others.push(payload);
        }
      });
      const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      others.forEach(other => {
        months[0] = other.data[0] + months[0];
        months[1] = other.data[1] + months[1];
        months[2] = other.data[2] + months[2];
        months[3] = other.data[3] + months[3];
        months[4] = other.data[4] + months[4];
        months[5] = other.data[5] + months[5];
        months[6] = other.data[6] + months[6];
        months[7] = other.data[7] + months[7];
        months[8] = other.data[8] + months[8];
        months[9] = other.data[9] + months[9];
        months[10] = other.data[10] + months[10];
        months[11] = other.data[11] + months[11];
      });
      const data = [];
      months.forEach((month, index) => {
        const monthsSliced = months.slice(0, index + 1);
        data[index] = monthsSliced.reduce((x, y) => x + y);
      });
      const payload = { name: "Otros", data, marker: { symbol: "circle" } };
      await series.splice(series.length, 0, payload);
      const newState = { data: { ...this.state.data, series } };
      await this.setState(newState);
    }
  };

  render() {
    const period = this.props.periods.filter(p => p.id === this.props.periodId);
    return (
      <React.Fragment>
        <Card>
        {this.state.periodId && (
          <Container>
            <Subtitle style={{color: "white"}}>Aporte por proyecto</Subtitle>
            <TextStrong style={{color: "white"}}>{period[0].year}</TextStrong>
          </Container>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.data.series.length && !this.props.isLoading ? (
          <div style={{padding: "30px"}}>
            <ReactHighcharts config={this.state.data} ref="chart_grades" />
          </div>
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

// Component State
function mapStateToProps(state) {
  return {
    company: state.companies.company,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    companyProjects: state.projects.companyProjects
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(StackedAreaGraphComponent)));
