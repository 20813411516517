// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getList } from "./api/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DetailsComponent from "./DetailsComponent";
import EditComponent from "./EditComponent";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { getById } from '../project/api/actions';
import { routes } from "../../../setup/routes";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: false,
      edit: false,
      milestone: undefined
    };
  }

  componentDidMount() {
    const id = this.props.history.location.pathname.replace("/milestones/", "");
    const token = this.props.cookies.get("token");
    this.props.getList(id, token);
    this.props.getById(id, token);
  }

  handleClose() {
    this.setState({ details: false, edit: false });
  }

  render() {
    return (
      <React.Fragment>
        <Button onClick={() => this.props.history.goBack()}>Volver</Button>
        {this.state.milestone && (
          <div>
            <DetailsComponent
              open={this.state.details}
              close={() => this.handleClose}
              milestone={this.state.milestone}
            />
            <EditComponent
              open={this.state.edit}
              close={() => this.handleClose}
              milestone={this.state.milestone}
              project={this.props.project}
            />
          </div>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
          {!this.props.isLoading &&
            this.props.error && (
              <h1>Ocurrió un error intentando recuperar las industrias</h1>
            )}
        </StyledDiv>
        <Paper style={{ width: "100%", overflowX: 'auto' }}>
          {this.props.list.length < 0 &&
            !this.props.isLoading && <h1>No hay hitos registradas</h1>}
          {this.props.list.length > 0 &&
            !this.props.isLoading &&
            !this.props.error && (
              <Table style={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Nombre del hito</TableCell>
                    <TableCell>Estado del hito</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                {!this.props.isLoading && (
                  <TableBody>
                    {this.props.list.map((milestone, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{milestone.name}</TableCell>
                          <TableCell>{milestone.MilestoneState.name}</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() => this.setState({
                                milestone,
                                details: true,
                                edit: false
                              })}
                            >
                              Ver
                            </Button>
                            <Button
                              size="small"
                              onClick={() => this.setState({
                                milestone,
                                details: false,
                                edit: true
                              })}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            )}
        </Paper>
      </React.Fragment>
    );
  }
}

ListComponent.propTypes = {
  list: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    list: state.milestones.list,
    isLoading: state.milestones.isLoading,
    error: state.milestones.error,
    project: state.projects.project
  };
}

export default connect(
  mapStateToProps,
  { getList, getById }
)(withRouter(withCookies(ListComponent)));
