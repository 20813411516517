import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardContent, Typography } from "@material-ui/core";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import moment from "moment";
const actualYear = moment().format("YYYY");
const actualMonth = moment().format("MM");

const StyledCard = styled(Card)`
  margin: 15px;
  width: 225px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction; row;
  justify-content: center;
`;

const CenterTypography = styled(Typography)`
  text-align: center;
`;

class AreaKPIComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpis: []
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    let kpis = [];
    let realBillingProjects = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let realBillingCompany = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let projects = 0;
    let escalate = 0;
    if (nextProps.company && nextProps.periods) {
      const period = nextProps.periods.filter(period => period.year.includes(actualYear));
      let periodId = period[0].id;
      if ((nextProps.company.InitialMonth.id - actualMonth) <= 0)
        periodId = periodId - 1;
      console.log({periodId, test: nextProps.company.InitialMonth.id - actualMonth});
      
      if (nextProps.company.EconomicInformations) {
        nextProps.company.EconomicInformations.forEach(economicInformation => {
          if (economicInformation.analysisTypeId === 1 && economicInformation.periodId === periodId) {
            realBillingCompany[0] = realBillingCompany[0] + economicInformation.month1;
            realBillingCompany[1] = realBillingCompany[1] + economicInformation.month2;
            realBillingCompany[2] = realBillingCompany[2] + economicInformation.month3;
            realBillingCompany[3] = realBillingCompany[3] + economicInformation.month4;
            realBillingCompany[4] = realBillingCompany[4] + economicInformation.month5;
            realBillingCompany[5] = realBillingCompany[5] + economicInformation.month6;
            realBillingCompany[6] = realBillingCompany[6] + economicInformation.month7;
            realBillingCompany[7] = realBillingCompany[7] + economicInformation.month8;
            realBillingCompany[8] = realBillingCompany[8] + economicInformation.month9;
            realBillingCompany[9] = realBillingCompany[9] + economicInformation.month10;
            realBillingCompany[10] = realBillingCompany[10] + economicInformation.month11;
            realBillingCompany[11] = realBillingCompany[11] + economicInformation.month12;
          }
        });
      }
      if (nextProps.area) {
        nextProps.area.Projects.forEach(project => {
          projects++;
          if (project.ProjectStep.name === "Escalar") escalate++;
          project.EconomicAnalyses.forEach(economicAnalysis => {
            if (economicAnalysis.analysisTypeId === 1 && economicAnalysis.periodId === periodId) {
              realBillingProjects[0] = realBillingProjects[0] + economicAnalysis.month1;
              realBillingProjects[1] = realBillingProjects[1] + economicAnalysis.month2;
              realBillingProjects[2] = realBillingProjects[2] + economicAnalysis.month3;
              realBillingProjects[3] = realBillingProjects[3] + economicAnalysis.month4;
              realBillingProjects[4] = realBillingProjects[4] + economicAnalysis.month5;
              realBillingProjects[5] = realBillingProjects[5] + economicAnalysis.month6;
              realBillingProjects[6] = realBillingProjects[6] + economicAnalysis.month7;
              realBillingProjects[7] = realBillingProjects[7] + economicAnalysis.month8;
              realBillingProjects[8] = realBillingProjects[8] + economicAnalysis.month9;
              realBillingProjects[9] = realBillingProjects[9] + economicAnalysis.month10;
              realBillingProjects[10] = realBillingProjects[10] + economicAnalysis.month11;
              realBillingProjects[11] = realBillingProjects[11] + economicAnalysis.month12;
            }
          });
        });
      }
    }
    let totalProjects = realBillingProjects.reduce(
      (x, y, index) => (realBillingProjects[index] = x + y)
    );
    let totalCompany = realBillingCompany.reduce(
      (x, y, index) => (realBillingCompany[index] = x + y)
    );
    // Total innovación / Total empresa
    const i3 = ((totalProjects / totalCompany) * 100).toFixed(2).replace(".", ",") + "%"
    totalProjects = "$ " + (totalProjects / 1000000).toFixed(1).replace(".", ",") + "M";
    kpis.push(
      { number: totalProjects, label: "Acumulado a la fecha" },
      { number: i3, label: "Aporte al índice i3" },
      { number: projects, label: "Proyectos en total" },
      { number: escalate, label: "Proyectos en etapa escalar" }
    );
    this.setState({ kpis });
  }

  render() {
    return (
      <React.Fragment>
        <CardContainer>
          {this.state.kpis.map((kpi, index) => {
            return (
              <StyledCard key={index}>
                <CardContent>
                  <CenterTypography style={{ color: "#212F55" }} variant="display1">
                    {kpi.number}
                  </CenterTypography>
                  <CenterTypography variant="body1">
                    {kpi.label}
                  </CenterTypography>
                </CardContent>
              </StyledCard>
            );
          })}
        </CardContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.area.area,
    company: state.companies.company,
    periods: state.periods.periods
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(AreaKPIComponent)));
