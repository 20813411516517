import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  MenuItem
} from "@material-ui/core";
import * as moment from "moment";

const years = [
  { label: "2013", value: 2013 },
  { label: "2014", value: 2014 },
  { label: "2015", value: 2015 },
  { label: "2016", value: 2016 },
  { label: "2017", value: 2017 },
  { label: "2018", value: 2018 },
  { label: "2019", value: 2019 },
  { label: "2020", value: 2020 },
  { label: "2021", value: 2021 },
  { label: "2022", value: 2022 },
  { label: "2023", value: 2023 },
  { label: "2024", value: 2024 },
  { label: "2025", value: 2025 },
  { label: "2026", value: 2026 },
  { label: "2027", value: 2027 },
  { label: "2028", value: 2028 },
  { label: "2029", value: 2029 }
];
const months = [
  { label: "Enero", value: 1 },
  { label: "Febrero", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Abril", value: 4 },
  { label: "Mayo", value: 5 },
  { label: "Junio", value: 6 },
  { label: "Julio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Septiembre", value: 9 },
  { label: "Octubre", value: 10 },
  { label: "Noviembre", value: 11 },
  { label: "Diciembre", value: 12 }
];

class EditEstimatedRelease extends Component {
  constructor(props) {
    super();
    this.state = {
      month: Number(moment(props.date).format("MM")),
      year: Number(moment(props.date).format("YYYY"))
    };
  }

  onSubmit = () => {
    const { month, year } = this.state;
    const implementationDate = `${year}-${month}-15`;
    const states = { implementationDate, editReleaseDate: false };
    this.props.changeState(states);
  }

  render() {
    const { month, year } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} style={{ minWidth: "600px" }}>
        <DialogTitle>Editar fecha estimada de lanzamiento</DialogTitle>
        <DialogContent>
          <Grid container spacing={16}>
            <Grid item xs={6}>
            <TextField
              id="year"
              name="year"
              select
              fullWidth={true}
              label="Año"
              value={year}
              onChange={e => this.setState({ year: e.target.value })}
            >
              {years.map(option => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="month"
              name="month"
              select
              fullWidth={true}
              label="Mes"
              value={month}
              onChange={e => this.setState({ month: e.target.value })}
            >
              {months.map(option => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            style={{ color: "#009688" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={this.onSubmit}
            color="primary"
            style={{ color: "#009688" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditEstimatedRelease;
