// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const PERIOD_LIST_REQUEST = 'PERIOD/GET_LIST_REQUEST';
export const PERIOD_LIST_RESPONSE = 'PERIOD/GET_LIST_RESPONSE';
export const PERIOD_LIST_FAILURE = 'PERIOD/GET_LIST_FAILURE';

// Actions

// Get list of elements
export function getList(token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: PERIOD_LIST_REQUEST,
      isLoading
    });
    try {      
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.get(routesApi.period.base, { headers })
      const response = await request;
      return dispatch({
        type: PERIOD_LIST_RESPONSE,
        periods: response.data.periods
      });
    } catch (error) {
      return dispatch({
        type: PERIOD_LIST_FAILURE,
        error: true
      });
    }
  }
}