import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as moment from "moment";
import { withCookies } from "react-cookie";
import MenuItem from "@material-ui/core/MenuItem";
import { getList } from "../period/api/actions";
import { addMonthlyBilling } from "../economicInformation/api/actions";
import { getById as companyById } from "../company/api/actions";
import * as jwtDecode from "jwt-decode";
const spanishMonths = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const SubmitButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-content: flex-start;
  }
`;

const ContainerContent = styled.div`
  && {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  && {
    background-color: #ba0f0f;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #eab6b8;
    }
  }
`;

class CompanyMonthlyBillingComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: "",
      periods: undefined,
      spanishMonth: spanishMonths[parseInt(moment().format("M"), 10) - 2],
      billing: {
        monthValue: 0,
        month: undefined,
        periodId: 0,
        companyId: 0
      }
    };
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    this.props.getList(token);
    this.props.companyById(user.companyId, token);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.company) {
      let last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(this.props.month, 10) + 1) % 12
      if (last === 0)
        last = 12;
      const periodId = nextProps.periods.find(period => {
        return period.id === nextProps.periodId
      });
      await this.setState({
        open: nextProps.open,
        billing: {
          ...this.state.billing,
          month: last,
          periodId,
          companyId: nextProps.company.id
        }
      });
    }
  }

  handleChange = name => async event => {
    const billing = {
      billing: { ...this.state.billing, [name]: parseInt(event.target.value, 10) }
    };
    await this.setState(billing);
  };

  handleClose = async () => {
    this.setState({ open: false });
    this.props.close();
  };

  onSubmit = async e => {
    e.preventDefault();
    const token = this.props.cookies.get("token");
    const payload = {
      periodId: this.state.billing.periodId.id,
      monthValue: this.state.billing.monthValue,
      month: `month${this.state.billing.month}`,
      companyId: this.state.billing.companyId
    };
    const request = this.props.addMonthlyBilling(payload, token);
    const response = await request;
    this.props.close();
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        aria-labelledby="form-dialog-title"
        onEscapeKeyDown={this.handleClose}
      >
        {!this.props.isLoading ? (
          <div>
            <DialogTitle id="form-dialog-title">
              Agregar {this.props.indicator} real del mes pasado a la compañia
            </DialogTitle>
            <DialogContent>
              <form onSubmit={this.onSubmit}>
                {this.state.billing.periodId && (
                  <Container>
                    <ContainerContent>
                      <TextField
                        select
                        label="Periodo"
                        fullWidth
                        disabled
                        value={this.state.billing.periodId.id}
                      >
                        <MenuItem value={this.state.billing.periodId.id}>
                          {this.state.billing.periodId.year}
                        </MenuItem>
                      </TextField>
                      <TextField
                        placeholder="Ingresa aquí el monto"
                        margin="dense"
                        id={this.state.month}
                        label={this.state.spanishMonth}
                        type="number"
                        value={this.state.value}
                        onChange={this.handleChange("monthValue")}
                        required
                        fullWidth
                      />
                    </ContainerContent>
                  </Container>
                )}
                <DialogActions>
                  <CancelButton onClick={this.props.close}>
                    Cancelar
                  </CancelButton>
                  <SubmitButton type="submit">Ingresar</SubmitButton>
                </DialogActions>
              </form>
            </DialogContent>
          </div>
        ) : (
            <StyledCircularProgress size={100} thickness={6} />
          )}
      </Dialog>
    );
  }
}

PropTypes.CompanyMonthlyBillingComponent = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  indicator: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    periods: state.periods.periods,
    token: state.auth.token,
    company: state.companies.company,
  };
}

export default connect(
  mapStateToProps,
  { getList, addMonthlyBilling, companyById }
)(withCookies(CompanyMonthlyBillingComponent));
