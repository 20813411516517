import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { create, getState } from "./api/actions";
import * as moment from "moment";
import { withCookies } from "react-cookie";

const datetime = moment().format("YYYY-MM-DD");

const SubmitButton = styled(Button)`
  && {
    width: 45%;
    background-color: #11ab99;
    margin-top: 16px;
    margin-right: 5px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  && {
    color: #ba0f0f;
  }
`;

const CancelButton = styled(Button)`
  && {
    width: 45%;
    background-color: #ba0f0f;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #eab6b8;
    }
  }
`;

const ButtonsContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

class CreateMilestone extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: "",
      name: "",
      description: "",
      expectedResults: "",
      date: datetime,
      projectId: "",
      milestoneStateId: 1
    };
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    this.props.getState(token);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ open: nextProps.open });
  }

  handleChange = name => async event => {
    await this.setState({ [name]: event.target.value });
    console.log(this.state.date);
  };

  handleClose = async () => {
    this.setState({ open: false });
    this.props.close();
  };

  onSubmit = async () => {
    const token = this.props.cookies.get("token");
    const payload = {
      name: this.state.name,
      description: this.state.description,
      expectedResults: this.state.expectedResults,
      date: this.state.date,
      projectId: this.props.project.id,
      milestoneStateId: this.state.milestoneStateId
    }
    const request = this.props.create(payload, token);
    const response = await request;
    await this.props.close();
    await this.setState({ open: false, error: "", name: "", description: "", expectedResults: "", date: datetime, projectId: "", milestoneStateId: 1 });
  };

  render() {
    return (
      <Dialog open={this.state.open} aria-labelledby="form-dialog-title" onEscapeKeyDown={this.handleClose}>
        {!this.props.isLoading ? (
          <div>
            <DialogTitle id="form-dialog-title">
              Agregar un hito
            </DialogTitle>
            <DialogContent>
              <span>
                {this.props.project ? this.props.project.name : ""}
              </span>
              <DialogContentText>
                {
                  "Para agregar una nuevo hito, ingresa un título, una descripción, la fecha de realización de este, y el estado en que se encuentra"
                }
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre del hito"
                type="text"
                value={this.state.name}
                onChange={this.handleChange("name")}
                fullWidth
              />
              <TextField
                margin="dense"
                id="description"
                label="Descripción del hito"
                type="text"
                value={this.state.description}
                multiline
                rowsMax="4"
                onChange={this.handleChange("description")}
                fullWidth
              />
              <TextField
                margin="dense"
                id="expectedResults"
                label="Resultados esperados"
                type="text"
                value={this.state.expectedResults}
                multiline
                rowsMax="4"
                onChange={this.handleChange("expectedResults")}
                fullWidth
              />
              <TextField
                margin="dense"
                id="date"
                label="Fecha del hito"
                type="date"
                value={this.state.date}
                onChange={this.handleChange("date")}
                fullWidth
              />
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="project-native-helper">
                  Proyecto
                </InputLabel>
                <NativeSelect disabled>
                  <option
                    value={this.props.project ? this.props.project.id : 0}
                  >
                    {this.props.project ? this.props.project.name : ""}
                  </option>
                </NativeSelect>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="project-native-helper">
                  Proyecto
                </InputLabel>
                {this.props.state && (
                  <NativeSelect
                    id="milestoneStateId"
                    margin="dense"
                    value={this.state.milestoneStateId}
                    onChange={this.handleChange("milestoneStateId")}
                  >
                    {this.props.state.map((state, index) => {
                      return (
                        <option key={index} value={state.id}>
                          {state.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
                )}
              </FormControl>
              <StyledFormHelperText>{this.state.error}</StyledFormHelperText>
              <ButtonsContainer>
                <SubmitButton onClick={() => this.onSubmit()} color="primary">
                  Agregar
                </SubmitButton>
                <CancelButton onClick={() => this.handleClose()} color="primary">
                  Cancelar
                </CancelButton>
              </ButtonsContainer>
            </DialogContent>
          </div>
        ) : (
            <StyledCircularProgress size={100} thickness={6} />
          )}
      </Dialog>
    );
  }
}

PropTypes.CreateMilestone = {
  open: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    isLoading: state.milestones.isLoading,
    error: state.milestones.error,
    state: state.milestones.state,
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps,
  { create, getState }
)(withCookies(CreateMilestone));
