import React from 'react';
import ListComponent from '../../common/industry/ListComponent';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { create } from '../../common/industry/api/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withCookies } from "react-cookie";

const SubmitButton = styled(Button)`
  && {
    background-color: #11AB99;
    margin-top: 16px;
    color: #FFFF;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  &&{
    color: #ba0f0f;
  }
`;

const CancelButton = styled(Button)`
  && {
    background-color: #ba0f0f;
    margin-top: 16px;
    color: #FFFF;
    &&:hover {
      background-color: #eab6b8;
    }
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
&& {
  background-color: #11AB99;
  margin-top: 16px;
  max-height: 50px;
  max-width: 50px;
  color: #FFFF;
  &&:hover {
    background-color: #89d6c7;
  }
}`

class IndustriesList extends React.Component {
  constructor() {
    super();
    this.state = {
      create: false,
      error: "",
      name: ""
    }
  }

  async onSubmit() {
    this.setState({ error: "" });
    if (this.state.name.length <= 4) {
      this.setState({ error: "El nombre de la industria debe tener como mínimo 5 caracteres." });
      return;
    }
    try {
      const token = this.props.cookies.get("token");
      const request = this.props.create(this.state.name, token);
      const response = await request;
      this.setState({ create: false });
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    return <React.Fragment>
      <Dialog
        open={this.state.create}
        aria-labelledby="form-dialog-title"
      >
        {!this.props.isLoading ? (
          <div>
            <DialogTitle id="form-dialog-title">Registrar una nueva industria</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para agregar una nueva industria, ingrese su nombre y luego de click en el registrar.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre de la industria"
                type="text"
                value={this.state.name}
                onChange={this.handleChange('name')}
                fullWidth
              />
              <StyledFormHelperText>
                {this.state.error}
              </StyledFormHelperText>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={() => this.setState({ create: false })} color="primary">
                Cancelar
            </CancelButton>
              <SubmitButton onClick={() => this.onSubmit()} color="primary">
                Registrar
            </SubmitButton>
            </DialogActions>
          </div>
        ) : <StyledCircularProgress size={100} thickness={6} />}
      </Dialog>
      <Container>
        <h1>Listado de industrias</h1>
        <StyledButton onClick={() => this.setState({ create: true })} variant="fab">
          <FontAwesomeIcon icon={faPlus} />
        </StyledButton>
      </Container>
      <ListComponent />
    </React.Fragment>
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.industries.isLoading,
    error: state.industries.error
  }
}

export default connect(mapStateToProps, { create })(withCookies(IndustriesList));