import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withCookies } from "react-cookie";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";
import { Subtitle } from "../../../helpers/Typography";
import { edit } from './api/actions';
import { getById } from "../project/api/actions";

const lastMonth = moment().subtract(1, "month");
const actualYear = moment().format("YYYY");
const actualDay = moment().format("DD");
const today = moment().format("YYYY-MM-DD");

const SubmitButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-content: flex-start;
  }
`;

const ContainerContent = styled.div`
  && {
    width: 100%;
  }
`;

// Se puede usar para notificar al usuario de errores dentro del formulario.
const StyledFormHelperText = styled(FormHelperText)`
  && {
    color: #ba0f0f;
  }
`;

const CancelButton = styled(Button)`
  && {
    background-color: #ba0f0f;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #eab6b8;
    }
  }
`;

class EditRealBillingComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: "",
      periods: undefined,
      billing: {
        month1: 0,
        month2: 0,
        month3: 0,
        month4: 0,
        month5: 0,
        month6: 0,
        month7: 0,
        month8: 0,
        month9: 0,
        month10: 0,
        month11: 0,
        month12: 0,
        periodId: 1
      },
      month: "",
      year: ""
    };
  }

  componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    const periods = [];
    let billing;
    if (nextProps.project && nextProps.periods && nextProps.company) {
      let first = nextProps.project.EconomicAnalyses.find(e => e.AnalysisTypeId === 1);
      billing = {
        id: first.id,
        projectName: nextProps.project.name,
        sponsorEmail: nextProps.project.sponsor.email,
        projectId: first.projectId,
        month1: first.month1 === null ? 0 : first.month1,
        month2: first.month2 === null ? 0 : first.month2,
        month3: first.month3 === null ? 0 : first.month3,
        month4: first.month4 === null ? 0 : first.month4,
        month5: first.month5 === null ? 0 : first.month5,
        month6: first.month6 === null ? 0 : first.month6,
        month7: first.month7 === null ? 0 : first.month7,
        month8: first.month8 === null ? 0 : first.month8,
        month9: first.month9 === null ? 0 : first.month9,
        month10: first.month10 === null ? 0 : first.month10,
        month11: first.month11 === null ? 0 : first.month11,
        month12: first.month12 === null ? 0 : first.month12,
        periodId: first.PeriodId
      }
      nextProps.project.EconomicAnalyses.forEach(async economicAnalysis => {
        if (economicAnalysis.AnalysisTypeId === 1) {
          nextProps.periods.forEach(period => {
            if (period.id === economicAnalysis.PeriodId) {
              periods.push(period);
            }
          })
        }
      });
      const months = [];
      for (let index = 0; index < 12; index++)
        months.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      await this.setState({
        open: nextProps.open,
        periods: periods,
        months,
        billing,
        month: lastMonth.format("MMMM").toLowerCase(),
        year: lastMonth.format("YYYY")
      });
    }
  }

  handleChange = name => async event => {
    if (name === "periodId") {
      let billing;
      this.props.project.EconomicAnalyses.map(async economicAnalysis => {
        if (economicAnalysis.PeriodId === event.target.value && economicAnalysis.AnalysisTypeId === 1) {
          billing = {
            id: economicAnalysis.id,
            projectName: this.props.project.name,
            sponsorEmail: this.props.project.sponsor.email,
            projectId: economicAnalysis.projectId,
            month1: economicAnalysis.month1 === null ? 0 : economicAnalysis.month1,
            month2: economicAnalysis.month2 === null ? 0 : economicAnalysis.month2,
            month3: economicAnalysis.month3 === null ? 0 : economicAnalysis.month3,
            month4: economicAnalysis.month4 === null ? 0 : economicAnalysis.month4,
            month5: economicAnalysis.month5 === null ? 0 : economicAnalysis.month5,
            month6: economicAnalysis.month6 === null ? 0 : economicAnalysis.month6,
            month7: economicAnalysis.month7 === null ? 0 : economicAnalysis.month7,
            month8: economicAnalysis.month8 === null ? 0 : economicAnalysis.month8,
            month9: economicAnalysis.month9 === null ? 0 : economicAnalysis.month9,
            month10: economicAnalysis.month10 === null ? 0 : economicAnalysis.month10,
            month11: economicAnalysis.month11 === null ? 0 : economicAnalysis.month11,
            month12: economicAnalysis.month12 === null ? 0 : economicAnalysis.month12,
            periodId: event.target.value
          };
        }
      });
      await this.setState({ billing });
    }
    const billing = {
      billing: { ...this.state.billing, [name]: parseInt(event.target.value === "" ? 0 : event.target.value, 10) }
    };
    await this.setState(billing);
  };

  handleClose = async () => {
    this.setState({ open: false });
    this.props.close();
  };

  onSubmit = async e => {
    e.preventDefault();
    const token = this.props.cookies.get("token");
    const payload = {
      ...this.state.billing,
      projectId: this.props.project.id,
      analysisTypeId: 1
    };
    await this.props.edit(payload, token);
    await this.props.getById(payload.projectId, token);
    this.props.close();
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        aria-labelledby="form-dialog-title"
        onEscapeKeyDown={this.handleClose}
      >
        {(!this.props.loadingProject && !this.props.loadingEconomicAnalysis) ? (
          <div>
            <DialogTitle id="form-dialog-title">
              Editar aporte real a {" "}
              {this.props.project ? this.props.project.name : ""}
            </DialogTitle>
            <DialogContent>
              <StyledFormHelperText style={{ marginBottom: 10 }}>
                {"*Si se guardan los cambios, se enviará un correo electrónico al sponsor de este proyecto"}
              </StyledFormHelperText>
              <Subtitle style={{ marginBottom: 20 }}>Aporte real</Subtitle>
              <form onSubmit={this.onSubmit}>
                <Container>
                  <ContainerContent style={{ marginTop: 20 }}>
                    {this.state.periods ? (
                      <TextField
                        select
                        label="Periodo"
                        value={this.state.billing.periodId}
                        fullWidth
                        required
                        onChange={this.handleChange("periodId")}
                      >
                        {this.state.periods.map((period, index) => (
                          <MenuItem key={index} value={period.id}>
                            {period.year}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                        ""
                      )}
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month1"
                      label={this.state.months ? this.state.months[0] : "Mes 1"}
                      type="number"
                      value={this.state.billing.month1}
                      onChange={this.handleChange("month1")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month2"
                      label={this.state.months ? this.state.months[1] : "Mes 2"}
                      type="number"
                      value={this.state.billing.month2}
                      onChange={this.handleChange("month2")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month3"
                      label={this.state.months ? this.state.months[2] : "Mes 3"}
                      type="number"
                      value={this.state.billing.month3}
                      onChange={this.handleChange("month3")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month4"
                      label={this.state.months ? this.state.months[3] : "Mes 4"}
                      type="number"
                      value={this.state.billing.month4}
                      onChange={this.handleChange("month4")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month5"
                      label={this.state.months ? this.state.months[4] : "Mes 5"}
                      type="number"
                      value={this.state.billing.month5}
                      onChange={this.handleChange("month5")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month6"
                      label={this.state.months ? this.state.months[5] : "Mes 6"}
                      type="number"
                      value={this.state.billing.month6}
                      onChange={this.handleChange("month6")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month7"
                      label={this.state.months ? this.state.months[6] : "Mes 7"}
                      type="number"
                      value={this.state.billing.month7}
                      onChange={this.handleChange("month7")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month8"
                      label={this.state.months ? this.state.months[7] : "Mes 8"}
                      type="number"
                      value={this.state.billing.month8}
                      onChange={this.handleChange("month8")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month9"
                      label={this.state.months ? this.state.months[8] : "Mes 9"}
                      type="number"
                      value={this.state.billing.month9}
                      onChange={this.handleChange("month9")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month10"
                      label={this.state.months ? this.state.months[9] : "Mes 10"}
                      type="number"
                      value={this.state.billing.month10}
                      onChange={this.handleChange("month10")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month11"
                      label={this.state.months ? this.state.months[10] : "Mes 11"}
                      type="number"
                      value={this.state.billing.month11}
                      onChange={this.handleChange("month11")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{ marginRight: 10 }}
                      id="month12"
                      label={this.state.months ? this.state.months[11] : "Mes 12"}
                      type="number"
                      value={this.state.billing.month12}
                      onChange={this.handleChange("month12")}
                      required
                    />
                  </ContainerContent>
                </Container>
                <DialogActions>
                  <CancelButton onClick={this.props.close}>
                    Cancelar
                  </CancelButton>
                  <SubmitButton type="submit">Guardar cambios</SubmitButton>
                </DialogActions>
              </form>
            </DialogContent>
          </div>
        ) : (
            <StyledCircularProgress size={100} thickness={6} />
          )}
      </Dialog>
    );
  }
}

PropTypes.EditRealBillingComponent = {
  open: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loadingProject: state.projects.isLoading,
    loadingEconomicAnalysis: state.economicAnalysis.isLoading,
    periods: state.periods.periods,
    project: state.projects.project,
    company: state.companies.company,
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps,
  { edit, getById }
)(withCookies(EditRealBillingComponent));
