import React from "react";
import { connect } from "react-redux";
import ReactHighcharts from "react-highcharts";
import HighchartsMore from 'highcharts-more';
import { withCookies } from 'react-cookie';
import Axios from "axios";
import * as jwtDecode from "jwt-decode";
import { routesApi } from "../../../setup/routes";
HighchartsMore(ReactHighcharts.Highcharts);

class ProjectPipelineAreaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: 'xy',
          height: (7.5 / 16 * 100) + '%'
        },
        title: {
          text: "Pipeline de los proyectos",
          align: "left",
          style: {
            fontFamily: 'Lato',
            fontSize: 18,
            color: "#212F55"
          }
        },
        xAxis: {
          plotLines: [{
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 1.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 2.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 3.5,
            width: 1
          },
          {
            color: "#bbbbbb",
            dashStyle: "dash",
            value: 4.5,
            width: 1
          }],
          min: 1,
          max: 5,
          startOnTick: false,
          minPadding: 0.025,
          maxPadding: 0.025,
          title: "Etapas",
          categories: [
            "Enfocar",
            "Detectar",
            "Idear",
            "Diseñar",
            "Pilotear",
            "Escalar",
          ]
        },
        yAxis: {
          startOnTick: false,
          minPadding: 0.025,
          maxPadding: 0.025,
          title: "Focos",
          categories: [],
          endOnTick: true
        },
        plotOptions: {
          bubble: {
            minSize: 5 + "%",
            maxSize: 15 + "%",
          },
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          },
          series: {
            dataLabels: {
              enabled: false,
              format: '{point.name}',
              displayNegative: true
            }
          }
        },
        tooltip: {
          useHTML: true,
          headerFormat: '<table>',
          pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
            '<tr><th>Area:</th><td>{point.area}</td></tr>' +
            '<tr><th>Impacto:</th><td>${point.impact}</td></tr>',
          footerFormat: '</table>',
          followPointer: true
        },
        credits: "INNSPIRAL LAB",
        series: [],
      },
      periodId: undefined,
      spotlights: [],
      loaded: false,
      currency: "CLP"
    }
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = Axios.post(routesApi.spotlights.base, { id: user.companyId }, { headers });
      const response = await request;
      this.setState({ spotlights: response.data.spotlight })
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillReceiveProps(nextProps) {
    let series = [];
    let yAxis = {
      startOnTick: false,
      minPadding: 0.025,
      maxPadding: 0.05,
      max: this.state.spotlights.length - 1,
      title: "Focos",
      categories: []
    };
    await this.state.spotlights.forEach(spotlight => {
      yAxis.categories.push(spotlight.name);
    });
    if (nextProps.company && nextProps.area) {
      let blocks = [];
      let projectsOriginal = [];
      let maxZSum = 0;
      nextProps.area.Projects.forEach(project => {
        if (project.impact === 0 || project.impact === null) project.z = 1;
        if (nextProps.company.categoryImpact1 > project.impact && project.impact !== 0 && project.impact !== null) project.z = 1.5;
        if (nextProps.company.categoryImpact1 < project.impact && project.impact < nextProps.company.categoryImpact2) project.z = 3;
        if (project.impact > nextProps.company.categoryImpact2) project.z = 5;
        projectsOriginal.push(project);
      });
      this.setState({ currency: nextProps.company.Currency.name });
      this.state.spotlights.forEach((spotlight, index) => {
        this.state.data.xAxis.categories.forEach((step) => {
          let projects = [];
          let projectCount = 0;
          let zSum = 0;
          projectsOriginal.forEach(project => {
            if (project.ProjectStep.name === step && project.Spotlight.name === spotlight.name) {
              project.zSum = zSum;
              project.y = index;
              project.index = projectCount;
              projects.push(project);
              zSum = zSum + project.z;
              projectCount++;
            }
          });
          if (maxZSum < zSum) maxZSum = zSum;
          blocks.push({ spotlight: spotlight.name, step, projects, projectCount });
        });
      });
      let projectAreas = { name: nextProps.area.name, data: [], color: "#ba0f0f" };
      nextProps.area.Projects.forEach(areaProject => {
        blocks.forEach(block => {
          block.projects.forEach(project => {
            if (areaProject.id === project.id) {
              const payload = {
                x: (project.ProjectStep.id - 1.5) + ((project.z / maxZSum) / 2) + (project.zSum / maxZSum),
                y: project.y,
                z: project.z,
                step: project.ProjectStep.name,
                impact: `${project.impact} ${this.state.currency}`,
                name: project.name,
                area: nextProps.area.name,
              }
              projectAreas.data.push(payload);
            }
          })
        });
      });
      series.push(projectAreas);
      const newState = { data: { ...this.state.data, series, yAxis } };
      this.setState(newState);
    }
  }

  render() {
    return (
      <ReactHighcharts config={this.state.data} ref="chart_grades" />
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.area.area,
    company: state.companies.company,
    isLoading: state.area.isLoading,
    error: state.area.error
  };
}

export default connect(
  mapStateToProps,
  {}
)(withCookies(ProjectPipelineAreaComponent));
