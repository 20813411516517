import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import * as moment from "moment";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Table,
  TableHead,
  Paper,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import styled from "styled-components";
const actualYear = moment().format("YYYY");

const Container = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class AnnualBillingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      periodId: undefined,
      analysis: [],
      months: []
    };
  }

  componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    let analysis = [];
    if (nextProps.company) {
      let months = [];
      for (let index = 0; index < 12; index++) {
        months.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      }
      await this.setState({ months });
    }
    if (nextProps.periods) {
      nextProps.periods.forEach(async period => {
        if (period.year === `Año ${actualYear}`)
          await this.setState({ periodId: period.id });
      });
    }
    if (nextProps.project) {
      nextProps.project.EconomicAnalyses.forEach(economicAnalysis => {
        if (economicAnalysis.periodId === this.state.periodId) {
          economicAnalysis.period =
            economicAnalysis.AnalysisTypeId === 1 ? "Real" : "Proyectado";
          analysis.push(economicAnalysis);
        }
      });
    }
    this.setState({ analysis });
  }

  handleChange = async event => {
    await this.setState({ periodId: event.target.value });
    let analysis = [];
    this.props.project.EconomicAnalyses.forEach(economicAnalysis => {
      if (economicAnalysis.periodId === event.target.value) {
        economicAnalysis.period =
          economicAnalysis.AnalysisTypeId === 1 ? "Real" : "Proyectado";
        analysis.push(economicAnalysis);
      }
    });
    this.setState({ analysis });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.periodId && (
          <Container>
            <FormControl>
              <InputLabel>Periodo</InputLabel>
              <Select value={this.state.periodId} onChange={this.handleChange}>
                {this.props.periods.map((period, index) => {
                  return (
                    <MenuItem key={index} value={period.id}>
                      {period.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Container>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.analysis.length && !this.props.isLoading ? (
          <Paper style={{ width: "100%", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {this.state.months.map((month, index) => {
                    return <TableCell key={index}>{month}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.analysis.map((economic, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{economic.period}</TableCell>
                      <TableCell>
                        ${economic.month1 ? economic.month1 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month2 ? economic.month2 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month3 ? economic.month3 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month4 ? economic.month4 : 0}
                      </TableCell>
                      <TableCell>${economic.month5 ? economic.month5 : 0}</TableCell>
                      <TableCell>
                        ${economic.month6 ? economic.month6 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month7 ? economic.month7 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month8 ? economic.month8 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month9 ? economic.month9 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month10 ? economic.month10 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month11 ? economic.month11 : 0}
                      </TableCell>
                      <TableCell>
                        ${economic.month12 ? economic.month12 : 0}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
      </React.Fragment>
    );
  }
}

// Component Properties
// AnnualBillingTable.propTypes = {
//   getById: PropTypes.func.isRequired,
//   getList: PropTypes.func.isRequired
// };

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    company: state.companies.company
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(AnnualBillingTable)));
