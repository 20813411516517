import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  CircularProgress,
  Chip
} from "@material-ui/core";
import { getList } from "../milestone/api/actions";
import * as moment from "moment";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import styled from "styled-components";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import DetailsComponent from "../milestone/DetailsComponent";
import EditComponent from "../milestone/EditComponent";
import { Subtitle, SubHeader, SecondaryButton, Text } from "../../../helpers/Typography";

const Container = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction; row;
    justify-content: space-between;
  }
`;

const ContainerCard = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const InverseContainer = styled.div`
  && {
    display: flex;
    flex-direction: row-reverse;
    align-content: flex-start;
    margin-top: 10px;
  }
`;

const StyledDivCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction; row;
  align-content: flex-start;
`;

const StyledCard = styled(Card)`
  min-width: 275px;
  max-width: 350px;
  margin: 7px;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 275px;
  min-height: 75px;
  text-align: center;
  margin: 50px;
`;

const RealizedChip = styled(Chip)`
  && {
    background-color: #11ab99;
    color: #FFFF;
  }
`;

const PendingChip = styled(Chip)`
  && {
    background-color: #FDCC4D;
    color: #FFFF;
  }
`;

const UnrealizedChip = styled(Chip)`
  && {
    background-color: #DD2E44;
    color: #FFFF;
  }
`;

const ActionIcon = styled.span`
  && {
    cursor: pointer;
    margin-right: 5px;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

class DialogMilestoneListComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
      milestones: [],
      months: [
        { name: "Enero", number: 1 },
        { name: "Febrero", number: 2 },
        { name: "Marzo", number: 3 },
        { name: "Abril", number: 4 },
        { name: "Mayo", number: 5 },
        { name: "Junio", number: 6 },
        { name: "Julio", number: 7 },
        { name: "Agosto", number: 8 },
        { name: "Septiembre", number: 9 },
        { name: "Octubre", number: 10 },
        { name: "Noviembre", number: 11 },
        { name: "Diciembre", number: 12 }
      ],
      years: [
        { year: 2018 },
        { year: 2019 },
        { year: 2020 },
        { year: 2021 },
        { year: 2022 },
        { year: 2023 }
      ],
      selectedMonth: parseInt(moment().format("M"), 10),
      selectedYear: parseInt(moment().format("Y"), 10),
      selectedMilestone: undefined,
      edit: false,
      details: false
    };
  }

  async componentDidMount() {
    const id = this.props.history.location.pathname.replace("/projects/", "");
    const token = this.props.cookies.get("token");
    await this.props.getList(id, token);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ open: nextProps.open });
    if (nextProps.list) {
      let milestones = nextProps.list.filter(
        milestone =>
          parseInt(moment(milestone.date).format("M"), 10) ===
          this.state.selectedMonth &&
          parseInt(moment(milestone.date).format("Y"), 10) ===
          this.state.selectedYear
      );
      await this.setState({ milestones });
    }
  }

  handleChange = async event => {
    await this.setState({
      [event.target.name]: parseInt(event.target.value, 10),
      details: false,
      edit: false,
      selectedMilestone: undefined
    });
    let milestones = this.props.list.filter(
      milestone =>
        parseInt(moment(milestone.date).format("M"), 10) ===
        this.state.selectedMonth &&
        parseInt(moment(milestone.date).format("Y"), 10) === this.state.selectedYear
    );
    await this.setState({ milestones });
  };

  handleClose = async () => {
    this.setState({ open: false, selectedMilestone: false });
    this.props.close();
  };

  handleCloseDetails() {
    this.setState({ details: false, selectedMilestone: false });
  }

  render() {
    return (
      <Dialog
        fullScreen={true}
        open={this.state.open}
        onEscapeKeyDown={this.handleClose}
      >
        {this.state.selectedMilestone && (
          <React.Fragment>
            <DetailsComponent
              milestone={this.state.selectedMilestone}
              open={this.state.details}
              close={() => this.handleClose}
            />
            <EditComponent
              milestone={this.state.selectedMilestone}
              project={this.state.selectedMilestone.Project}
              open={this.state.edit}
              close={() => this.handleClose}
            />
          </React.Fragment>
        )}
        {!this.props.isLoading ? (
          <React.Fragment>
            <InverseContainer>
              <Button onClick={this.handleClose}>
                <FontAwesomeIcon size="3x" icon={faTimes} />
              </Button>
            </InverseContainer>
            <Container style={{ padding: 10 }}>
              <Typography variant="display1">Listado de hitos</Typography>
              <div>
                <FormControl style={{ marginRight: 5 }}>
                  <InputLabel>Año</InputLabel>
                  <Select
                    name="selectedYear"
                    id="selectedYear"
                    value={this.state.selectedYear}
                    onChange={this.handleChange}
                  >
                    {this.state.years.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year.year}>
                          {year.year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl style={{ marginRight: 5 }}>
                  <InputLabel>Mes</InputLabel>
                  <Select
                    name="selectedMonth"
                    id="selectedMonth"
                    value={this.state.selectedMonth}
                    onChange={this.handleChange}
                  >
                    {this.state.months.map((month, index) => {
                      return (
                        <MenuItem key={index} value={month.number}>
                          {month.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Container>
            {this.state.milestones.length > 0 ? (
              <StyledDivCard>
                {this.state.milestones.map((milestone, index) => {
                  return (
                    <StyledCard key={index}>
                      <CardContent>
                        <Subtitle>
                          {milestone.name}
                        </Subtitle>
                        <hr />
                        <ContainerCard>
                          <div>
                            {milestone.MilestoneState.name === "Pendiente" && (<PendingChip label={milestone.MilestoneState.name} />)}
                            {milestone.MilestoneState.name === "Realizado" && (<RealizedChip label={milestone.MilestoneState.name} />)}
                            {milestone.MilestoneState.name === "No realizado" && (<UnrealizedChip label={milestone.MilestoneState.name} />)}
                          </div>
                          <SubHeader>
                            {moment(milestone.date).format("D/MMMM")}
                          </SubHeader>
                        </ContainerCard>
                        <InverseContainer>
                          <div>
                            <ActionIcon
                              onClick={() =>
                                this.setState({
                                  details: true,
                                  allMilestones: false,
                                  edit: false,
                                  create: false,
                                  selectedMilestone: milestone
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </ActionIcon>
                            <ActionIcon
                              onClick={() =>
                                this.setState({
                                  details: false,
                                  allMilestones: false,
                                  edit: true,
                                  create: false,
                                  selectedMilestone: milestone
                                })
                              }>
                              <FontAwesomeIcon icon={faEdit} />
                            </ActionIcon>
                          </div>
                        </InverseContainer>
                      </CardContent>
                    </StyledCard>
                  );
                })}
              </StyledDivCard>
            ) : (
                <ErrorCard>
                  <Typography variant="display1">
                    No hay hitos próximos registrados
                </Typography>
                </ErrorCard>
              )}
          </React.Fragment>
        ) : (
            <StyledCircularProgress size={100} thickness={6} />
          )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.milestones.isLoading,
    list: state.milestones.list
  };
}

export default connect(
  mapStateToProps,
  { getList }
)(withCookies(withRouter(DialogMilestoneListComponent)));
