// Imports

// App Imports
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_RESPONSE,
  GET_COMPANY_FAILURE
} from './actions'

// Elements list

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  company: undefined
}

// State
export const companies = (state = elementsInitialState, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case GET_COMPANY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        company: action.company
      }

    case GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      }

    default:
      return state
  }
}