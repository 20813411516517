// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const INDUSTRY_LIST_REQUEST = 'INDUSTRY/GET_LIST_REQUEST';
export const INDUSTRY_LIST_RESPONSE = 'INDUSTRY/GET_LIST_RESPONSE';
export const INDUSTRY_LIST_FAILURE = 'INDUSTRY/GET_LIST_FAILURE';
export const CREATE_INDUSTRY_REQUEST = 'INDUSTRY/CREATE_REQUEST';
export const CREATE_INDUSTRY_RESPONSE = 'INDUSTRY/CREATE_RESPONSE';
export const CREATE_INDUSTRY_FAILURE = 'INDUSTRY/CREATE_FAILURE';

// Actions

// Get list of elements
export function getList(token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: INDUSTRY_LIST_REQUEST,
      isLoading
    });
    try {      
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.get(routesApi.industries.base, { headers })
      const response = await request;
      return dispatch({
        type: INDUSTRY_LIST_RESPONSE,
        list: response.data.industries
      });
    } catch (error) {
      return dispatch({
        type: INDUSTRY_LIST_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function create(industry, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: CREATE_INDUSTRY_REQUEST,
      isLoading
    });
    try {
      // TODO: Falta implementar JWT
      const headers = { Authorization: `Bearer ${token}` };
      const payload = { name: industry };
      const request = axios.post(routesApi.industries.base, payload, { headers });
      const response = await request;
      dispatch({
        type: CREATE_INDUSTRY_RESPONSE,
        industry: response.data.industry
      });
    } catch (error) {
      return dispatch({
        type: CREATE_INDUSTRY_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}