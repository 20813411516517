import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  MenuItem
} from "@material-ui/core";
import { routesApi } from "../../../setup/routes";
import axios from "axios";
import { withCookies } from "react-cookie";

class AddActivityModal extends Component {
  constructor(props) {
    super();
    const today = new Date();
    this.state = {
      name: "",
      milestoneProjectId: 0,
      deadline: `${today.getFullYear()}-01-01`,
      token: props.cookies.get("token"),
      created: false,
      loading: false,
      error: false,
    };
  }

  onSubmit = async () => {
    this.setState({ loading: true });
    const { name, deadline, milestoneProjectId, token } = this.state;
    const payload = { name, deadline, milestoneProjectId };
    const headers = { Authorization: `Bearer ${token}` };
    try {
      await axios.post(routesApi.projects.activities, payload, { headers });
      this.setState({ created: true, loading: false });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  validateForm = () => {
    const { name, milestoneProjectId } = this.state;
    if (name !== "" && milestoneProjectId !== 0) return false;
    return true;
  };

  render() {
    const { open, onClose } = this.props;
    const { name, milestoneProjectId, deadline, created, loading, error } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <Dialog open={loading} style={{ minWidth: "600px" }}>
            <DialogTitle>Creando la actividad</DialogTitle>
          </Dialog>
        )}
        {error && (
          <Dialog open={error} style={{ minWidth: "600px" }}>
            <DialogTitle>Ocurrió un error creando la actividad</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.setState({error: false})}
                color="primary"
                style={{ color: "#009688" }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {created && (
          <Dialog open={created} style={{ minWidth: "600px" }}>
            <DialogTitle>Actividad registrada con éxito</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => window.location.reload()}
                color="primary"
                style={{ color: "#009688" }}
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {(!created || !loading || !error) && (
          <Dialog open={open} style={{ minWidth: "600px" }}>
          <DialogTitle>Crear nueva actividad</DialogTitle>
          <DialogContent>
            <Grid container justify="center">
              <Grid item xs={10}>
                <TextField
                  value={name}
                  label="Título de la actividad"
                  name="title"
                  type="text"
                  fullWidth
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <TextField
                  value={milestoneProjectId}
                  label="Hito asociado"
                  name="title"
                  type="text"
                  fullWidth
                  select
                  onChange={e =>
                    this.setState({ milestoneProjectId: e.target.value })
                  }
                >
                  <MenuItem value={0}>
                    <i>Seleccione un hito</i>
                  </MenuItem>
                  {this.props.milestoneProjects.map(milestoneProject => (
                    <MenuItem
                      key={milestoneProject.id}
                      value={milestoneProject.id}
                    >
                      {milestoneProject.milestone}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={deadline}
                  label="Fecha límite"
                  name="endDate"
                  type="date"
                  fullWidth
                  onChange={e => this.setState({ deadline: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="primary"
              style={{ color: "#009688" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={this.onSubmit}
              color="primary"
              style={{ color: "#009688" }}
              disabled={this.validateForm()}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default withCookies(AddActivityModal);
