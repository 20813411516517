import React from "react";
import ListComponent from "../../common/milestone/ListComponent";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateMilestone from "../../common/milestone/CreateComponent";
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { getById } from '../../common/project/api/actions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 16px;
    max-height: 50px;
    max-width: 50px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

class MilestoneList extends React.Component {
  constructor() {
    super();

    this.state = {
      create: false,
      project: undefined
    };
  }

  componentDidMount() {
    const id = this.props.history.location.pathname.replace("/milestones/", "");
    const token = this.props.cookies.get("token");
    this.props.getById(id, token);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ project: nextProps.project });
  }

  handleClose = async () => {
    await this.setState({ create: false });
  }

  render() {
    return (
      <React.Fragment>
        <CreateMilestone
          open={this.state.create}
          project={this.state.project}
          close={this.handleClose}
        />
        <Container>
          <h1>Listado de hitos (Falta definir como desplegar los datos)</h1>
          {this.state.project && (
            <StyledButton
              onClick={() => this.setState({ create: true })}
              variant="fab"
            >
              <FontAwesomeIcon icon={faPlus} />
            </StyledButton>
          )}
        </Container>
        <ListComponent  />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    isLoading: state.projects.isLoading,
    error: state.projects.error
  };
}

export default connect(mapStateToProps, { getById })(withRouter(withCookies(MilestoneList)));
