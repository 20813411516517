import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import styled from "styled-components";
import { Title } from "../../../helpers/Typography";
import {
  Card,
  CardContent,
  Grid,
  CardHeader,
  List,
  withStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import moment from "moment";
import AnnualBillingGraphComponent from "./AnnualBillingGraphComponent";
import EditEstimatedBillingComponent from "../economicAnalysis/EditEstimatedBillingComponent";
import EditRealBillingComponent from "../economicAnalysis/EditRealBillingComponent";
import { edit } from "./api/actions";
import IconButton from "@material-ui/core/IconButton";
import EditProjectImpact from "./EditProjectImpact";
import EditEstimatedRelease from "./EditEstimatedRelease";
import EditProjectInvestment from "./EditProjectInvestment";
import ProjectInformationComponent from "./ProjectInformationComponent";
import ProjectBriefComponent from "./ProjectBriefComponent";
import ProjectActivitiesComponent from "./ProjectActivitiesComponent";
import { routes } from "../../../setup/routes";
import EditProjectDetails from './EditProjectDetails';
import EditProjectCost from './EditProjectCost';

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const ContainerRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: flex-end;
`;

const GraphContainer = styled.div`
  && {
    display: column;
    flex-flow: row wrap;
    flex-direction: row;
    padding: 20px;
  }
`;

const BackButton = styled(Button)`
  && {
    color: #717171;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-right: 13px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const styles = () => ({
  cardTitle: {
    color: "white",
    fontSize: "19px",
    fontFamily: "Lato"
  },
  tabIndicator: {
    backgroundColor: "#1b2642"
    // top: 0
  },
  root: {
    backgroundColor: "#7792d8",
    color: "#FFFFFF",
    opacity: 1
  },
  selected: {
    backgroundColor: "#FFFFFF",
    color: "#000000"
  }
});

class EditProjectComponent extends Component {
  constructor() {
    super();
    this.state = {
      impact: 0,
      implementationDate: "",
      projectStepId: 1,
      problem: "",
      solution: "",
      objetives: "",
      benefits: "",
      editReal: false,
      editEstimated: false,
      project: undefined,
      hasBilling: false,
      steps: [
        { id: 1, name: "Enfocar" },
        { id: 2, name: "Detectar" },
        { id: 3, name: "Idear" },
        { id: 4, name: "Diseñar" },
        { id: 5, name: "Pilotear" },
        { id: 6, name: "Escalar" }
      ],
      milestoneProjects: [],
      detailsType: "",
      editImpact: false,
      editCost: false,
      editReleaseDate: false,
      editInvestment: false,
      editDetails: false,
      editDescription: false,
    };
  }

  async componentDidMount() {}

  async componentWillReceiveProps(nextProps) {
    if (nextProps.project) {
      const milestoneProjects = [];
      nextProps.project.milestonesProjects.forEach(milestonesProject => {
        milestonesProject.milestones.forEach(milestone =>
          milestoneProjects.push(milestone)
        );
      });
      let real = nextProps.project.EconomicAnalyses.find(
        e => e.AnalysisTypeId === 1
      );
      let estimated = nextProps.project.EconomicAnalyses.find(
        e => e.AnalysisTypeId === 2
      );
      if (real && estimated) await this.setState({ hasBilling: true });
      await this.setState({
        project: nextProps.project,
        impact: nextProps.project.impact,
        investment: nextProps.project.investment,
        cost: nextProps.project.cost,
        implementationDate:
          nextProps.project.implementationDate === null
            ? moment().format("YYYY-MM")
            : moment(nextProps.project.implementationDate).format("YYYY-MM"),
        projectStepId: nextProps.project.ProjectStep.id,
        problem: nextProps.project.problem,
        solution: nextProps.project.solution,
        objetives: nextProps.project.objetives,
        benefits: nextProps.project.benefits,
        team: nextProps.project.team,
        spotlightId: nextProps.project.Spotlight.id,
        milestoneProjects
      });
    }
  }

  handleChange = name => async event => {
    await this.setState({ [name]: event.target.value });
  };

  handleClose = () => {
    this.setState({ editEstimated: false, editReal: false });
  };

  changeState = async states => {
    await this.setState(states);
    this.onSubmit();
  }

  openEditDetails = detailsType => {
    this.setState({ detailsType, editDetails: true });
  }

  onSubmit = () => {
    const token = this.props.cookies.get("token");
    const payload = {
      ...this.state.project,
      changeImpact: false,
      changeStep: false,
      changeImplementation: false,
      projectStepId: this.state.projectStepId,
      impact: this.state.impact,
      investment: this.state.investment,
      implementationDate: this.state.implementationDate,
      problem: this.state.problem,
      solution: this.state.solution,
      objetives: this.state.objetives,
      benefits: this.state.benefits,
      areaId: this.state.project.Area.id,
      team: this.state.team,
      spotlightId: this.state.spotlightId
    };
    if (this.state.project.impact !== this.state.impact)
      payload.changeImpact = true;
    if (this.state.project.ProjectStep.id !== this.state.projectStepId)
      payload.changeStep = true;
    if (
      moment(this.state.project.implementationDate).format("YYYY-MM-DD") !==
      moment(this.state.implementationDate).format("YYYY-MM-DD")
    )
      payload.changeImplementation = true;
    this.props.edit(payload, token);
    
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.editImpact && (
          <EditProjectImpact
            open={this.state.editImpact}
            onClose={() => this.setState({ editImpact: false })}
            changeState={this.changeState}
            impact={this.state.impact}
          />
        )}
        {this.state.editInvestment && (
          <EditProjectInvestment
            open={this.state.editInvestment}
            onClose={() => this.setState({ editInvestment: false })}
            changeState={this.changeState}
            investment={this.state.investment}
          />
        )}
        {this.state.editCost && (
          <EditProjectCost
            open={this.state.editCost}
            onClose={() => this.setState({ editCost: false })}
            changeState={this.changeState}
            cost={this.state.cost}
          />
        )}
        {this.state.editReleaseDate && (
          <EditEstimatedRelease
            open={this.state.editReleaseDate}
            onClose={() => this.setState({ editReleaseDate: false })}
            changeState={this.changeState}
            date={this.state.implementationDate}
          />
        )}
        {this.state.editDetails && this.props.company && (
          <EditProjectDetails 
            open={this.state.editDetails}
            onClose={() => this.setState({ editDetails: false })}
            changeState={this.changeState}
            type={this.state.detailsType}
            team={this.state.team}
            companyId={this.props.company.id}
            spotlightId={this.state.spotlightId}
            problem={this.state.problem}
            solution={this.state.solution}
            objetives={this.state.objetives}
            benefits={this.state.benefits}
          />
        )}
        {this.state.hasBilling && (
          <div>
            <EditRealBillingComponent
              open={this.state.editReal}
              close={this.handleClose}
            />
            <EditEstimatedBillingComponent
              open={this.state.editEstimated}
              close={this.handleClose}
            />
          </div>
        )}
        {this.state.project && (
          <div>
            <Container>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginBottom: "20px"
                }}
              >
                {this.state.project && (
                  <BackButton
                    onClick={() =>
                      this.props.history.push(
                        `${routes.projects.brief}/${this.state.project.id}`
                      )
                    }
                  >
                    <FontAwesomeIcon size="2x" icon={faAngleLeft} />
                  </BackButton>
                )}
                <Title>{this.state.project.name}</Title>
              </div>
            </Container>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Principales Indicadores"
                    classes={{ title: classes.cardTitle }}
                    style={{ backgroundColor: "#1b2642", height: "33px" }}
                  />
                  <CardContent>
                  {this.state.project.ProjectStep.name !== "Escalar" && (
                    <Grid container>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText primary="Impacto en régimen:" />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText primary={`$ ${this.state.impact}`} />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() =>
                                  this.setState({ editImpact: true })
                                }
                              >
                                <FontAwesomeIcon icon={faPen} size={"xs"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>)}
                    {this.state.project.ProjectStep.name !== "Escalar" && (
                    <Grid container>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText primary="Inversión estimada:" />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText
                              primary={`$ ${
                                this.state.investment === null
                                  ? 0
                                  : this.state.investment
                              }`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() =>
                                  this.setState({ editInvestment: true })
                                }
                              >
                                <FontAwesomeIcon icon={faPen} size={"xs"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>)}
                    {this.state.project.ProjectStep.name !== "Escalar" && (
                    <Grid container>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText primary="Fecha est. de lanzamiento:" />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText
                              primary={`${moment(this.state.implementationDate).format("YYYY-MM")}`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() =>
                                  this.setState({ editReleaseDate: true })
                                }
                              >
                                <FontAwesomeIcon icon={faPen} size={"xs"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>)}
                    <Grid container>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText primary="Gasto a la fecha:" />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List dense={false}>
                          <ListItem>
                            <ListItemText
                              primary={`$ ${
                                this.state.cost === null ? 0 : this.state.cost
                              }`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() =>
                                  this.setState({ editCost: true })
                                }
                              >
                                <FontAwesomeIcon icon={faPen} size={"xs"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    title="Detalle del proyecto"
                    classes={{ title: classes.cardTitle }}
                    style={{ backgroundColor: "#1b2642", height: "33px" }}
                  />
                  <ProjectInformationComponent edit={true} openEditDetails={this.openEditDetails} />
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <ProjectBriefComponent edit={true} openEditDetails={this.openEditDetails} />
              </Grid>
              <Grid item xs={12}>
                {this.state.project.ProjectStep.name !== "Escalar" ? (
                  <ProjectActivitiesComponent
                    editProject={true}
                    milestoneProjects={this.state.milestoneProjects}
                  />
                ) : (
                  <div>
                    {this.state.hasBilling && (
                      <Card style={{ marginBottom: 20 }}>
                        <CardHeader
                          title={`${this.props.company &&
                            this.props.company.EconomicIndicator
                              .indicator} Real vs Proyectado`}
                          classes={{ title: classes.cardTitle }}
                          style={{ backgroundColor: "#1b2642", height: "33px" }}
                        />
                        <GraphContainer>
                          <AnnualBillingGraphComponent changePeriod={true} />
                          <ContainerRight>
                            <StyledButton
                              onClick={() => {
                                this.setState({ editReal: true });
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Actualizar{" "}
                              {this.props.company &&
                                this.props.company.EconomicIndicator.indicator}
                            </StyledButton>
                            <StyledButton
                              onClick={() => {
                                this.setState({ editEstimated: true });
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Editar proyección
                            </StyledButton>
                          </ContainerRight>
                        </GraphContainer>
                      </Card>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    company: state.companies.company
  };
}

export default connect(
  mapStateToProps,
  { edit }
)(withRouter(withCookies(withStyles(styles)(EditProjectComponent))));
