import React, {Component} from 'react';
  
  class Forbidden extends Component {
    render() {
      return (
        <div>
          <h1>Forbidden</h1>
          <p>No tienes permisos para acceder a esta vista.</p>
        </div>
      );
    }
  }
  
  export default Forbidden;