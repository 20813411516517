// Imports

// App Imports
import {
  GET_AREA_PROJECTS_REQUEST,
  GET_AREA_PROJECTS_RESPONSE,
  GET_AREA_PROJECTS_FAILURE
} from './actions'

// Elements list

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  area: undefined
}

// State
export const area = (state = elementsInitialState, action) => {
  switch (action.type) {
    case GET_AREA_PROJECTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case GET_AREA_PROJECTS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        area: action.area
      }

    case GET_AREA_PROJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        area: undefined,
        error: true,
      }

    default:
      return state
  }
}