import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Divider, TextField, MenuItem, Button, AppBar, Toolbar, Paper, Typography,  Hidden, Icon } from '@material-ui/core'
import { BigTitle, SubHeader, Title, SubtitleStrong } from "../../../../helpers/Typography";


import Add from '@material-ui/icons/Add';



const styles = theme => ({
    
  container: {
    display: 'grid',
 
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing.unit,
  },

  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },

  divider: {
      backgroundColor: '#C6C6C6',
       
  }, 
  button: {
    margin: '1em 2em 0em 2.5em',
    textTransform: 'none',
    borderWidth: "1px",
    borderColor: "#7894D5 !important",
    borderRadius: '10px',
    borderWidth: "2px",
    padding: "15px",
    backgroundColor:"#7894D5",
    color: 'white',
    width: '200px'
    

  },

  textField: {
    marginLeft: '2.5em',
    marginRight: '2em',
    
    [`& fieldset`]: {
        borderRadius: '10px',
        borderWidth: "2px",
        
    }, 
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#7894D5 !important"
  }, 
  focused: {
    "& $notchedOutline": {
      borderColor: "#1B2543 !important"
    }
  }, 
});



const currencies = [
    {
      value: 'USD',
      label: 'USD',
    },
    {
      value: 'EUR',
      label: 'EUR',
    },
    {
      value: 'CLP',
      label: 'CLP',
    },
    {
      value: 'UF',
      label: 'UF',
    },
  ];

  const economicIndicator = [
    {
      value: 'EBITDA',
      label: 'EBITDA',
    },
    {
      value: 'VAN',
      label: 'VAN',
    },
  ];

  const countryCompany = [
    {
      value: 'Chile',
      label: 'Chile',
    },
    {
      value: 'Perú',
      label: 'Perú',
    },
  ];


class NewProjectForm extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
        <React.Fragment>
        <Grid container spacing={24} style={{backgroundColor:"#F9FAFC"}}>
            
            <Grid item xs={12} >
            <div style={{margin: '50px 0px 8px 40px'}}>
                <BigTitle >
                    Nuevo Proyecto 
                    {/* en realidad aqui tiene que ir el input q se pone en el nombre de la empresa que se quiere agregar */}
                </BigTitle> 
            </div>
            </Grid>
            <Grid item xs={12} >
                <Divider style= {{  margin: '0em 3em 1em 3em'}} className={classes.divider} />
            </Grid>

        <Grid item md={6} xs={12}>
            <Grid 
            container 
            direction= "column"
            alignItems= "flex-start"
            >

                <SubtitleStrong>
                    INFORMACIÓN DEL PROYECTO
                </SubtitleStrong>
            </Grid>     
        </Grid>      
      </Grid>
      </React.Fragment>
    )
  }
}



NewProjectForm.propTypes = {
    classes: PropTypes.object.isRequired,
  
  
}



export default withStyles(styles)(NewProjectForm);


