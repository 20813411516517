import axios from "axios";
import { routesApi } from "../../../../setup/routes";
import * as jwtDecode from "jwt-decode";

export const TOKEN_GET_REQUEST = "USER/GET_REQUEST";
export const TOKEN_GET_RESPONSE = "USER/GET_RESPONSE";
export const TOKEN_GET_FAILURE = "USER/GET_FAILURE";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RECOVERY_PASSWORD_FAILURE = "RECOVERY_PASSWORD_FAILURE";
export const RECOVERY_PASSWORD_RESPONSE = "RECOVERY_PASSWORD_RESPONSE";
export const RECOVERY_PASSWORD_REQUEST = "RECOVERY_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_REQUEST = "USER/CHANGE_USER_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_RESPONSE = "USER/CHANGE_USER_PASSWORD_RESPONSE";
export const CHANGE_USER_PASSWORD_FAILURE = "USER/CHANGE_USER_PASSWORD_FAILURE";

export function getToken(user, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: TOKEN_GET_REQUEST,
      isLoading
    });
    try {
      const request = axios.post(routesApi.auth.login, user);
      const response = await request;
      return dispatch({
        type: TOKEN_GET_RESPONSE,
        token: response.data.token,
        auth: true,
        user: jwtDecode(response.data.token).user,
        rol: jwtDecode(response.data.token).user.rol,
        firstLogin: response.data.firstLogin
      })
    } catch (error) {
      console.log({ error });
      return dispatch({
        type: TOKEN_GET_FAILURE,
        error: true
      })
    }
  };
}

export function setToken(token) {
  return dispatch => {
    const userObj = jwtDecode(token);
    dispatch({
      type: SET_TOKEN,
      token: token,
      auth: true,
      user: userObj.user,
      rol: userObj.user.rol
    });
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: LOGOUT,
      token: undefined,
      auth: false,
      user: undefined
    });
  };
}

export function recoveryPassword(payload, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: RECOVERY_PASSWORD_REQUEST,
      isLoading
    });
    try {
      const request = axios.post(routesApi.auth.recoveryPassword, payload);
      const response = await request;
      return dispatch({
        type: RECOVERY_PASSWORD_RESPONSE,
        recovery: response.data.recoveryPassword
      });
    } catch (error) {
      return dispatch({
        type: RECOVERY_PASSWORD_FAILURE,
        error: error.response.data.error
      });
    }
  }
}

export function forgotPassword(email, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      isLoading
    });
    try {
      const request = axios.post(routesApi.auth.forgotPassword, email);
      const response = await request;
      return dispatch({
        type: FORGOT_PASSWORD_RESPONSE,
        passwordToken: response.data.forgotPassword.token
      });
    } catch (error) {
      return dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        error: error.response.data.error
      });
    }
  };
}

export function changePassword(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: CHANGE_USER_PASSWORD_REQUEST,
      isLoading,
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.users.changePassword, payload, { headers });
      const response = await request;
      dispatch({
        type: CHANGE_USER_PASSWORD_RESPONSE,
        message: response.data.message
      });
    } catch (error) {
      dispatch({
        type: CHANGE_USER_PASSWORD_FAILURE,
        error: error.response.data.error
      });
    }
  }
}