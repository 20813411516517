import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardContent, Typography, withWidth, Popper, Fade, Paper, Grid } from "@material-ui/core";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

const styles = theme => ({
  card: {
    backgroundColor: "#1b2642"
  }
});

const cardWidth = ((73.7 / 6) * window.innerWidth) / 100;

const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();

const StyledCard = styled(Card)`
  min-width: 100px;
  min-height: 130px;
  margin-bottom: 2%;
`;

const CardContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-items: baseline;
  }
`;

const CenterTypography = styled(Typography)`
  text-align: center;
`;

class ProjectKPIComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpis: [],
      anchorEl: null,
      active: undefined,
    };
  }

  componentDidMount() {
  }

  static getDerivedStateFromProps(props, state) {
    if (props.project !== state.project) {
      let kpis = [];
      const last = (12 - parseInt(props.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      let totalRealAccumulatedProject = 0;
      let totalEstimatedAccumulatedProject = 0;
      let totalRealAccumulatedCompany = 0;
      let lastRealMonth = 0;
      let lastEstimatedMonth = 0;
      props.project.EconomicAnalyses.forEach(economicAnalysis => {
        if (economicAnalysis.periodId === props.periodId && economicAnalysis.AnalysisTypeId === 1) {
          lastRealMonth = economicAnalysis["month" + last];
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month1;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month2;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month3;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month4;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month5;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month6;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month7;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month8;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month9;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month10;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month11;
          totalRealAccumulatedProject = totalRealAccumulatedProject + economicAnalysis.month12;
        }
      });
      props.project.EconomicAnalyses.forEach(economicAnalysis => {
        if (economicAnalysis.periodId === props.periodId && economicAnalysis.AnalysisTypeId === 2) {
          lastEstimatedMonth = economicAnalysis["month" + last];
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month1;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month2;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month3;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month4;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month5;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month6;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month7;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month8;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month9;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month10;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month11;
          totalEstimatedAccumulatedProject = totalEstimatedAccumulatedProject + economicAnalysis.month12;
        }
      });
      props.company.EconomicInformations.forEach(economicInformation => {
        if (economicInformation.analysisTypeId === 1 && economicInformation.periodId === props.periodId) {
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month1;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month2;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month3;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month4;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month5;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month6;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month7;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month8;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month9;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month10;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month11;
          totalRealAccumulatedCompany = totalRealAccumulatedCompany + economicInformation.month12;
        }
      });
      const i3 = ((totalRealAccumulatedProject / totalRealAccumulatedCompany) * 100).toFixed(2).replace(".", ",") + "%";
      const developmentMonths = Math.trunc(moment.duration(moment().diff(moment(props.project.creationDate))).asMonths());
      const implementationDate = props.project.implementationDate;
      if (props.project && props.project.ProjectStep.id < 6) {
        kpis.push(
          { data: props.project.impact === null ? `${0}MM` : `${(Number(props.project.impact) / 1000000).toFixed(2).replace(".", ",")}MM`, label: "Impacto en Régimen " + `(${props.company.Currency.name})`, dropTitle: "", description: "Dinero estimado que generará el proyecto cuando ingrese al mercado" },
          { data: props.project.investment === null ? `${0}MM` : `${(Number(props.project.investment) / 1000000).toFixed(3).replace(".", ",")}MM`, label: "Inversión Total Estimada", dropTitle: `(${props.company.Currency.name})`, description: "Inversión que se prevén utilizar en este proyecto" },
          { data: `${Number(props.project.cost).toLocaleString('es')}`, label: "Gasto a la Fecha", dropTitle: `(${props.company.Currency.name})`, description: "Acumulado de los gastos del proyecto a la fecha actual" },
          { data: implementationDate === null ? "-" : moment(implementationDate).format("MMM-YY").replace(".", "").replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() }), label: "Fecha Estimada de Lanzamiento", dropTitle: "", description: `${moment(implementationDate).diff(moment(), "days") <= 0 ? "Se estima que el proyecto debería lanzarse durante este mes" : `Se estima que el proyecto debería lanzarse en ${moment(implementationDate).diff(moment(), "days")}`}` },
          { data: isNaN(developmentMonths) ? "-" : developmentMonths, label: "Meses en Desarrollo", dropTitle: "", description: "Cantidad de meses que el proyecto lleva en desarrollo" },
          { data: props.project.percentageOfCompletion === null ? `${0}%` : `${props.project.percentageOfCompletion.replace(".", ",")}%`, label: "Avance de proyecto", dropTitle: "", description: "Porcentaje obtenido con la cantidad de hitos finalizados por etapa" },
        );
      } else {
        kpis.push(
          { data: i3, label: "Aporte al i3 (Índice Intensidad de Innovación)", dropTitle: "", description: "Recordemos que el indicador i3 es el porcentaje del resultado de la empresa proveniente de proyectos de innovación" },
          { data: `${Number(lastRealMonth).toLocaleString('es')}`, label: "Aporte de " + moment().subtract(1, 'month').format("MM-YYYY") + ` (${props.company.Currency.name})`, dropTitle: "", description: "Aporte realizado por el proyecto durante el mes pasado en el periodo actual" },
          { data: `${Number(totalRealAccumulatedProject).toLocaleString('es')}`, label: "Aporte del proyecto a la fecha" + ` (${props.company.Currency.name})`, dropTitle: "", description: props.company.EconomicIndicator.indicator + " real desde el inicio del periodo hasta " + moment().format("MM-YYYY") },
          { data: `${((totalRealAccumulatedProject * 100) / totalEstimatedAccumulatedProject).toFixed(2).replace(".", ",")}%`, label: "Cumplimiento de meta anual del proyecto", dropTitle: "", description: "Porcentaje del " + props.company.EconomicIndicator.indicator + " presupuestado del proyecto cumplido" },
          { data: `${((lastRealMonth - lastEstimatedMonth) / lastEstimatedMonth).toFixed(2).replace(".", ",")}%`, label: "Diferencia con proyección " + moment().subtract(1, 'month').format("MM-YYYY"), dropTitle: "", description: "Diferencia entre lo generado por el proyecto realmente y lo que se tenía proyectado" },
          { data: `${Number(props.project.cost).toLocaleString('es')}`, label: "Gasto a la Fecha", dropTitle: "En " + props.company.Currency.name, description: "Gasto del proyecto hasta el día de hoy" },
        );
      }
      return {
        kpis
      };
    }
    return null;
  }

  openPopper = (index, e) => {
    this.setState({ anchorEl: e.currentTarget, active: index });
  }

  closePopper = () => {
    this.setState({ anchorEl: null, active: undefined });
  }

  screenWidthValidation = () => {
    if(this.props.width === "xs") {
      return true
    }
    return false
  }

  cardWidthMethod = () => {
    if(this.screenWidthValidation()) {
      return window.innerWidth * 0.75
    }
    return cardWidth
  }

  render() {
    const { classes } = this.props;
    const spacing = this.screenWidthValidation() ? 8 : 24 
    return (
      <CardContainer>
      <Grid container spacing={spacing}>
        {this.state.kpis.map((kpi, index) =>
            <Grid item xs={"auto"} key={index}>
            <StyledCard onMouseEnter={(e) => this.openPopper(index, e)} onMouseLeave={this.closePopper} className={classes.card} elevation={1} style={index !== this.state.kpis.length - 1 ? { marginRight: "2%", width: this.cardWidthMethod() } : { width: this.cardWidthMethod() }} key={index}>
              <CardContent>
                <CenterTypography style={{ color: "white", fontSize: "1.4rem", marginRight: "-8px", marginLeft: "-8px" }} variant="headline" noWrap>
                  {kpi.data}
                </CenterTypography>
                <CenterTypography style={{ color: "white", marginLeft: "-8px", marginRight: "-8px", textAlign: "center", paddingBottom: "-17px" }} variant="body1">
                  {kpi.label}
                </CenterTypography>
                <CenterTypography style={{ color: "white" }} variant="caption">
                  {kpi.dropTitle}
                </CenterTypography>
              </CardContent>
            </StyledCard>
            </Grid>
        )}
      </Grid>
        {this.state.active !== undefined && <Popper open={this.state.active !== undefined} anchorEl={this.state.anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography style={{padding: 5}}>{this.state.kpis[this.state.active].description}</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>}
      </CardContainer>
    );
  }
}

ProjectKPIComponent.propTypes = {
  company: PropTypes.object,
  classes: PropTypes.object,
  project: PropTypes.object,
  periodId: PropTypes.number
};

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    company: state.companies.company
  };
}

export default connect(mapStateToProps)(withRouter(withCookies(withWidth()(withStyles(styles)(ProjectKPIComponent)))));
