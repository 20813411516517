import styled from 'styled-components';
import Button from '@material-ui/core/Button';

// Super H1
export const BigTitle = styled.strong`
  font-size: 50px;
  color: #7894D5;
  font-family: 'Lato';
`;


// H1
export const Title = styled.strong`
  font-size: 36px;
  color: #212F55;
  font-family: 'Lato';
`;

// H2
export const Subtitle = styled.span`
  font-size: 19px;
  color: #212F55;
  font-family: 'Lato';
`;
// H2 STRONG
export const SubtitleStrong = styled.strong`
  font-size: 19px;
  color: #212F55;
  font-family: 'Lato';
`;

// H3
export const SubHeader = styled.span`
  font-size: 16px;
  color: #212F55;
  font-family: 'Lato';
`;

// p1
export const Text = styled.span`
  font-size: 14px;
  color: #212F55;
  font-family: 'Lato';
`;

export const TextP2 = styled.span`
  font-size: 12px;
  color: #212F55;
  font-family: 'Lato';
`;

// p1 bold
export const TextStrong = styled.strong`
  font-size: 14px;
  color: #212F55;
  font-family: 'Lato';
`;

// 13 = 12

export const SecondaryButton = styled(Button)`
  && {
    background-color: #FFFF;
    color: #1b2642;
    border: 1px solid;
    border-color: #1b2642;
    margin-right: 2px;
    font-size: 12px;
    &&:hover {
      background-color: #1b2642;
      color: #FFFF;
    }
  }
`;
