import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, AppBar, Tabs, Tab, Button, Paper, CardHeader } from '@material-ui/core';
import styled from 'styled-components';
import { Text, Subtitle, TextStrong } from '../../../helpers/Typography';
import axios from 'axios';
import { routesApi } from '../../../setup/routes';
import { withCookies } from 'react-cookie';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledCard = styled(Card)`
  height: 350px;
  width: 100%;
  overflow: auto;
`;

const StyledPaper = styled(Paper)`
  height: 350px;
  width: 100%;
  overflow: auto;
`;

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const ContainerReverse = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const StyledTabs = styled(Tabs)`
  && {
    margin: 0;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-width: 50px;
    text-transform: initial;
  }
`;

const CardMargin = styled.div`
  && {
    margin: 4%;
  }
`;

const styles = theme => ({
  tabIndicator: {
    backgroundColor: "#1b2642",
    // top: 0
  },
  appBar: {
    backgroundColor: "#7792d8"
  },
  cardTitle: {
    color: 'white',
    fontSize: "19px",
    fontFamily: 'Lato',
  },
  tabRoot: {
    backgroundColor: "#7792d8",
    color: "#FFFFFF",
    opacity: 1
  },
  selected: {
    backgroundColor: "#FFFFFF",
    color: "#000000"
  }
});

class ProjectScaleBrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: undefined,
      value: 0,
      file: undefined
    }
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state) {
    if (props.project !== state.project) {
      return {
        project: props.project,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({ project: undefined });
  }

  uploadEvaluation = async () => {
    try {
      // eslint-disable-next-line no-undef
      let formData = new FormData();
      await formData.append("file", this.state.evaluationFile, this.state.evaluationFile.name);
      const token = this.props.cookies.get("token");
      const headers = { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' };
      const request = axios.post(routesApi.projects.uploadEvaluation(this.state.project.id), formData, { headers });
      const response = await request;
      if (response.status === 200)
        this.props.reload();
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log({ error });
    }
  }

  uploadGantt = async () => {
    try {
      // eslint-disable-next-line no-undef
      let formData = new FormData();
      await formData.append("file", this.state.ganttFile, this.state.ganttFile.name);
      const token = this.props.cookies.get("token");
      const headers = { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' };
      const request = axios.post(routesApi.projects.uploadGantt(this.state.project.id), formData, { headers });
      const response = await request;
      if (response.status === 200)
        this.props.reload();
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log({ error });
    }
  }

  downloadEvaluation = async () => {
    try {
      const token = this.props.cookies.get("token");
      const headers = { Authorization: `Bearer ${token}` };
      const requestUrl = axios.get(routesApi.projects.getEvaluation(this.state.project.economicEvaluationFile), { headers });
      const responseUrl = await requestUrl;
      // eslint-disable-next-line no-undef
      window.open(responseUrl.data.url, "_blank");
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log({ error });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  }

  render() {
    const { classes } = this.props;
    return (
      <StyledPaper>
        <StyledCard>
          <CardHeader title="Descripción del Proyecto" classes={{ title: classes.cardTitle }} style={{ backgroundColor: '#1b2642' }} />
          <div>
            {!this.state.project ? <div style={{ display: "flex", justifyContent: "center" }}> <CircularProgress size={100} thickness={6} /> </div> : (
              <div>
                <AppBar classes={{ root: classes.appBar }} elevation={0} position="static" style={{ width: "100%", boxShadow: 'none' }}>
                  <StyledTabs classes={{ indicator: classes.tabIndicator }} value={this.state.value} onChange={this.handleChange} fullWidth>
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Resumen" />
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Problema" />
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Solución" />
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Beneficios" />
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Equipo" />
                    <StyledTab classes={{ selected: classes.selected, root: classes.tabRoot }} label="Acerca del proyecto" />
                  </StyledTabs>
                </AppBar>
                <CardMargin>
                  <div>
                    {this.state.value === 0 &&
                      <Text style={this.state.project.solution.length > 150 ? { minHeight: 340, columnCount: 2, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 } : { minHeight: 340, columnCount: 1, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 }}>
                        {this.state.project.problem}
                      </Text>}
                    {this.state.value === 1 &&
                      <Text style={this.state.project.solution.length > 150 ? { minHeight: 340, columnCount: 2, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 } : { minHeight: 340, columnCount: 1, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 }}>
                        {this.state.project.solution}
                      </Text>}
                    {this.state.value === 2 &&
                      <Text style={this.state.project.solution.length > 150 ? { minHeight: 340, columnCount: 2, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 } : { minHeight: 340, columnCount: 1, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 }}>
                        {this.state.project.objetives}
                      </Text>}
                    {this.state.value === 3 &&
                      <div>
                        <div style={{ marginBottom: 15 }}>
                          <Text style={this.state.project.solution.length > 150 ? { minHeight: 340, columnCount: 2, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 } : { minHeight: 340, columnCount: 1, whiteSpace: "pre-line", color: "#000000", lineHeight: 1.4 }}>
                            {this.state.project.benefits}
                          </Text>
                        </div>
                        <Container>
                          <Subtitle>Impacto en régimen: ${Number(this.state.project.impact).toLocaleString("es") + " " + this.props.company.Currency.name}</Subtitle>
                        </Container>
                        <ContainerReverse>
                          {this.state.project.economicEvaluationFile !== null && <Button onClick={this.downloadEvaluation}>Descargar Evaluación</Button>}
                        </ContainerReverse>
                      </div>}
                    {this.state.value === 4 &&
                      <div>
                        <div>
                          <TextStrong>Lider de proyecto: {" "}</TextStrong>
                          <Text>{this.state.project.leader.firstName + " " + this.state.project.leader.lastName}</Text>
                        </div>
                        <div>
                          <TextStrong>Sponsor: {" "}</TextStrong>
                          <Text>{this.state.project.sponsor.firstName + " " + this.state.project.sponsor.lastName}</Text>
                        </div>
                        <div>
                          <TextStrong>Equipo: {" "}</TextStrong>
                          <Text>{this.state.project.team}</Text>
                        </div>
                      </div>
                    }
                    {this.state.value === 5 &&
                      <div>
                        <div><TextStrong>Foco de Innovación: {" "}</TextStrong>
                          <Text>{this.state.project.Spotlight.name}</Text>
                        </div>
                        {this.state.project.filters.map((filter, index) =>
                          <div key={index}>
                            <TextStrong>{filter.name}: {" "}</TextStrong>
                            {filter.options.map((option, findex) => <Text key={findex}>{option.name}</Text>)}
                          </div>
                        )}
                      </div>}
                  </div>
                </CardMargin>
              </div>
            )}
          </div>
        </StyledCard>
      </StyledPaper>
    );
  }
}

ProjectScaleBrief.propTypes = {
  cookies: PropTypes.object,
  periods: PropTypes.array,
  isLoading: PropTypes.bool,
  company: PropTypes.object,
  classes: PropTypes.object,
  error: PropTypes.bool,
  reload: PropTypes.func,

};

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    company: state.companies.company,
    error: state.projects.error
  };
}

export default connect(mapStateToProps)(withCookies(withStyles(styles)(ProjectScaleBrief)));
