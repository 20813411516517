// Helpers

// Render element or component by provided condition
export function renderIf(condition, renderFn) {
  return condition ? renderFn() : null
}

export default {
  companyImage: companyName => {
    try {
      return require(`../images/companies/${companyName}.png`);
    } catch(e) {
      return require(`../images/companies/unknown.png`)
    }
  }
}