// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const DIMENSION_LIST_REQUEST = 'DIMENSION/GET_LIST_REQUEST';
export const DIMENSION_LIST_RESPONSE = 'DIMENSION/GET_LIST_RESPONSE';
export const DIMENSION_LIST_FAILURE = 'DIMENSION/GET_LIST_FAILURE';

// Actions

// Get list of elements
export function getList(companyId, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: DIMENSION_LIST_REQUEST,
      isLoading
    });
    try {      
      const headers = { Authorization: `Bearer ${token}` };
      const payload = { id: companyId }
      const request = axios.post(routesApi.dimension.base, payload, { headers })
      const response = await request;
      return dispatch({
        type: DIMENSION_LIST_RESPONSE,
        dimensions: response.data.dimensions
      });
    } catch (error) {
      return dispatch({
        type: DIMENSION_LIST_FAILURE,
        error: true
      });
    }
  }
}