// Imports
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// App Imports
import * as auth from "../components/common/login/api/state";
import { industries } from '../components/common/industry/api/state';
import { projects } from '../components/common/project/api/state';
import { milestones } from '../components/common/milestone/api/state';
import { economicAnalysis } from '../components/common/economicAnalysis/api/state';
import { economicInformation } from "../components/common/economicInformation/api/state";
import { periods } from "../components/common/period/api/state";
import { companies } from "../components/common/company/api/state";
import { area } from '../components/common/area/api/state';
import { dimensions } from '../components/common/dimension/api/state';

// Root Reducer
const rootReducer = combineReducers({
  ...auth,
  industries,
  projects,
  milestones,
  economicAnalysis,
  economicInformation,
  periods,
  companies,
  area,
  dimensions
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Store
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
