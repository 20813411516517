// Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { getNextMilestones } from "../milestone/api/actions";
import CreateMilestone from "../milestone/CreateComponent";
import * as moment from "moment";
import { Card, CardContent, Typography, Chip } from "@material-ui/core";
import styled from "styled-components";
import DetailsComponent from "../milestone/DetailsComponent";
import DialogMilestoneListComponent from "./DialogMilestoneListComponent";
import EditComponent from "../milestone/EditComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Subtitle, SubHeader, SecondaryButton } from "../../../helpers/Typography";

const Container = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContainerCard = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const InverseContainer = styled.div`
  && {
    display: flex;
    flex-direction: row-reverse;
    align-content: flex-start;
    margin-top: 10px;
  }
`;

const StyledDivCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  align-content: flex-start;
  margin-top: 10px;
`;

const StyledCard = styled(Card)`
  min-width: 275px;
  max-width: 350px;
  margin: 7px;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 275px;
  min-height: 75px;
  text-align: center;
  margin: 50px;
`;

const RealizedChip = styled(Chip)`
  && {
    background-color: #11ab99;
    color: #FFFF;
  }
`;

const PendingChip = styled(Chip)`
  && {
    background-color: #FDCC4D;
    color: #FFFF;
  }
`;

const UnrealizedChip = styled(Chip)`
  && {
    background-color: #DD2E44;
    color: #FFFF;
  }
`;

const ActionIcon = styled.span`
  && {
    cursor: pointer;
    margin-right: 5px;
    color: #1b2642;
  }
`;

class ProjectMilestonesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      months: [
        { name: "Enero", number: 1 },
        { name: "Febrero", number: 2 },
        { name: "Marzo", number: 3 },
        { name: "Abril", number: 4 },
        { name: "Mayo", number: 5 },
        { name: "Junio", number: 6 },
        { name: "Julio", number: 7 },
        { name: "Agosto", number: 8 },
        { name: "Septiembre", number: 9 },
        { name: "Octubre", number: 10 },
        { name: "Noviembre", number: 11 },
        { name: "Diciembre", number: 12 }
      ],
      years: [
        { year: 2018 },
        { year: 2019 },
        { year: 2020 },
        { year: 2021 },
        { year: 2022 },
        { year: 2023 }
      ],
      selectedMonth: parseInt(moment().format("M"), 10),
      selectedYear: parseInt(moment().format("Y"), 10),
      selectedMilestone: undefined,
      details: false,
      allMilestones: false,
      create: false,
      edit: false,
      milestones: [],
      nextMilestones: []
    };
  }

  async componentDidMount() {
    const id = this.props.history.location.pathname.replace("/projects/", "");
    const token = this.props.cookies.get("token");
    await this.props.getNextMilestones(id, token);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.nextMilestones) {
      await this.setState({ nextMilestones: nextProps.nextMilestones });
    }
  }

  handleClose() {
    this.setState({ details: false, allMilestones: false, create: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.project && (
          <CreateMilestone
            open={this.state.create}
            project={this.props.project}
            close={() => this.handleClose}
          />
        )}
        {this.state.selectedMilestone && (
          <React.Fragment>
            <DetailsComponent
              milestone={this.state.selectedMilestone}
              open={this.state.details}
              close={() => this.handleClose}
            />
            <EditComponent
              milestone={this.state.selectedMilestone}
              open={this.state.edit}
              close={() => this.handleClose}
              project={this.state.selectedMilestone.Project}
            />
          </React.Fragment>
        )}
        <DialogMilestoneListComponent
          open={this.state.allMilestones}
          close={() => this.handleClose}
        />
        <Container>
          <Subtitle>Próximos hitos</Subtitle>
          <div>
            <SecondaryButton onClick={() => this.setState({ create: true, allMilestones: false, details: false, edit: false })}>
              <FontAwesomeIcon icon={faPlus} /> {" "}
              Agregar hito
            </SecondaryButton>
            <SecondaryButton
              onClick={() =>
                this.setState({
                  create: false,
                  allMilestones: true,
                  details: false,
                  edit: false,
                  selectedMilestone: undefined
                })
              }
            >
              <FontAwesomeIcon icon={faEye} /> {" "}
              Ver más hitos
            </SecondaryButton>
          </div>
        </Container>
        {this.state.nextMilestones.length > 0 ? (
          <StyledDivCard>
            {this.state.nextMilestones.map((milestone, index) => {
              return (
                <StyledCard key={index}>
                  <CardContent>
                    <Subtitle>
                      {milestone.name}
                    </Subtitle>
                    <hr />
                    <ContainerCard>
                      <SubHeader>
                        {moment(milestone.date).format("D MMMM")}
                      </SubHeader>
                      <div>
                        {milestone.MilestoneState.name === "Pendiente" && (<PendingChip label={milestone.MilestoneState.name} />)}
                        {milestone.MilestoneState.name === "Realizado" && (<RealizedChip label={milestone.MilestoneState.name} />)}
                        {milestone.MilestoneState.name === "No realizado" && (<UnrealizedChip label={milestone.MilestoneState.name} />)}
                      </div>
                    </ContainerCard>
                    <InverseContainer>
                      <div>
                        <ActionIcon
                          onClick={() =>
                            this.setState({
                              details: true,
                              allMilestones: false,
                              edit: false,
                              create: false,
                              selectedMilestone: milestone
                            })
                          }
                        >
                          <FontAwesomeIcon size="lg" icon={faEye} />
                        </ActionIcon>
                        <ActionIcon
                          onClick={() =>
                            this.setState({
                              details: false,
                              allMilestones: false,
                              edit: true,
                              create: false,
                              selectedMilestone: milestone
                            })
                          }>
                          <FontAwesomeIcon icon={faEdit} />
                        </ActionIcon>
                      </div>
                    </InverseContainer>
                  </CardContent>
                </StyledCard>
              );
            })}
          </StyledDivCard>
        ) : (
            <ErrorCard>
              <Typography variant="display1">
                No hay hitos próximos registrados
            </Typography>
            </ErrorCard>
          )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    list: state.milestones.list,
    nextMilestones: state.milestones.nextMilestones
  };
}

export default connect(
  mapStateToProps,
  { getNextMilestones }
)(withCookies(withRouter(ProjectMilestonesComponent)));
