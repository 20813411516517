import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../../setup/routes";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import { Collapse, Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faTachometerAlt,
  faIndustry,
  faBars,
  faHome,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import helpers from "../../../setup/helpers";
import TitleBanner from "../../../images/gpi.png";
import styled from 'styled-components';
import { Text } from "../../../helpers/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  list: {
    width: 250
  },
  buttonLink: {
    textDecoration: "none",
    color: "white"
  },
  subMenu: {
    paddingLeft: 20
  },
  navBar: {
    backgroundColor: "#1b2642"
  }
};

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledToolBarContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledImage = styled.img`
  @media (max-width: 375px) {
    display: none;
  }
`;

const StyledText = styled(Text)`
  color: white;
  @media (max-width: 500px) {
    display: none;
  }
`;

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false,
      openMenu: false,
      anchorEl: null,
      auth: false,
      roles: [],
      userList: false,
      thingList: false,
      notificationList: false
    };
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      await this.setState({
        auth: nextProps.auth
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, auth } = this.state;
    const open = Boolean(this.state.anchorEl);
    const sideList = (
      <div className={classes.list}>
        <List
          component="nav"
        >
          <ListItem>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Gestión de Portafolio
            </Typography>
          </ListItem>
          <Divider />
          {["Gerente de Innovación"].includes(this.props.rol) && (
            <React.Fragment>
              <ListItem button onClick={() => this.redirect(routes.company.dashboard)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTachometerAlt} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              {/* <ListItem button onClick={() => this.redirect(routes.company.areas)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faIndustry} />
                </ListItemIcon>
                <ListItemText primary="Listado de áreas" />
              </ListItem> */}
            </React.Fragment>
          )}
          {["Gerente de Área"].includes(this.props.rol) && (
            <Divider />
          )}
          {["Líder de proyecto"].includes(this.props.rol) && (
            <Divider />
          )}
          {["Administrador"].includes(this.props.rol) && (
            <ListItem button onClick={() => this.redirect(routes.about)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faHome} />
              </ListItemIcon>
              <ListItemText primary="Sobre Nosotros" />
            </ListItem>
          )}
        </List>
        {["Administrador"].includes(this.props.rol) && (
          <React.Fragment>
            <Divider />
            <List component="nav">
              <ListItem button onClick={() => this.openList("users")}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUsers} />
                </ListItemIcon>
                <ListItemText primary="Usuarios" inset />
                {this.state.userList ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
              </ListItem>
              <Collapse in={this.state.userList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => this.redirect(routes.users.getUsers)}
                  >
                    <ListItemIcon style={styles.subMenu}>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText primary="Listado" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.redirect(routes.users.register)}
                  >
                    <ListItemIcon style={styles.subMenu}>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText primary="Nuevo usuario" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem button onClick={() => this.openList("things")}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHome} />
                </ListItemIcon>
                <ListItemText primary="Cosas" inset />
                {this.state.thingList ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
              </ListItem>
              <Collapse in={this.state.thingList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => this.redirect(routes.things.all)}
                  >
                    <ListItemIcon style={styles.subMenu}>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText primary="Listado de cosas" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.redirect(routes.things.create)}
                  >
                    <ListItemIcon style={styles.subMenu}>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText primary="Nueva cosa" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem button onClick={() => this.openList("notifications")}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHome} />
                </ListItemIcon>
                <ListItemText primary="Notificaciones" inset />
                {this.state.notificationList ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
              </ListItem>
              <Collapse
                in={this.state.notificationList}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => this.redirect(routes.notifications.create)}
                  >
                    <ListItemIcon style={styles.subMenu}>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText primary="Crear notificación" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </React.Fragment>
        )}
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" style={styles.navBar}>
          <StyledToolbar>
            <StyledToolBarContent>
              {auth && (
                <IconButton
                  onClick={() => this.toggleDrawer(true)}
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                >
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
              )}
              <StyledImage style={{ width: 150 }} src={TitleBanner} alt="Gestión de portafolio de innovación by INNSPIRAL" />
            </StyledToolBarContent>
            <div>
              {!auth && (
                <Button color="inherit">
                  <Link to={routes.login} style={styles.buttonLink}>
                    Iniciar sesión
                </Link>
                </Button>
              )}
              {auth && (
                <div>
                  <div style={{ cursor: "pointer" }} onClick={this.handleMenu}>
                      <IconButton
                        aria-owns={this.state.openMenu ? "menu-appbar" : null}
                        aria-haspopup="true"
                        color="inherit"
                      >
                        <Avatar src={helpers.companyImage(this.props.user ? this.props.user.company.toLowerCase() : "unknown")} size={5} style={{ float: 'left', display: 'block' }} />
                      </IconButton>
                      <Button style={{ textTransform: "none"}}>
                      <StyledText>
                        {this.props.user.firstName + " " + this.props.user.lastName}
                      </StyledText>
                      </Button>
                  </div>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={() => this.logout()}>
                      Cerrar Sesión
                  </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </StyledToolbar>
        </AppBar>
        {(this.props.notificationLoading ||
          this.props.userLoading ||
          this.props.authLoading) && <LinearProgress color="secondary" />}
        <Drawer
          open={this.state.openDrawer}
          onClose={() => this.toggleDrawer(false)}
        >
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  redirect = link => {
    this.toggleDrawer(false);
    this.setState({
      thingList: false,
      userList: false,
      notificationList: false
    });
    this.props.history.push(link);
    this.handleClose();
  };

  openList = listName => {
    switch (listName) {
      case "users":
        this.setState({
          userList: !this.state.userList
        });
        break;
      case "things":
        this.setState({
          thingList: !this.state.thingList
        });
        break;
      case "notifications":
        this.setState({
          notificationList: !this.state.notificationList
        });
        break;
      default:
        break;
    }
  };

  logout = () => {
    this.props.history.push(routes.logout);
    this.setState({
      auth: false
    });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = open => this.setState({ openDrawer: open });
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth.auth,
    token: state.auth.token,
    user: state.auth.user,
    rol: state.auth.rol
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(withStyles(styles)(NavBar))));
