import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem
} from "@material-ui/core";
import axios from 'axios';
import { withCookies } from 'react-cookie';
import { routesApi } from "../../../setup/routes";

class EditProjectDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      team: props.team === null ? "" : props.team,
      spotlightId: props.spotlightId,
      problem: props.problem,
      solution: props.solution,
      objetives: props.objetives,
      benefits: props.benefits,
      spotlights: [],
    };
  }

  async componentDidMount() {
    try {
      const token = this.props.cookies.get("token");
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.post(routesApi.spotlights.base, {id: this.props.companyId}, {headers});
      const response = await request;
      const spotlights = response.data.spotlight
      this.setState({spotlights});
    } catch (error) {
      return error;
    }
  }

  onSubmit = () => {
    const { team, spotlightId, problem, solution, objetives, benefits } = this.state;
    const states = { team, spotlightId, problem, solution, objetives, benefits, editDetails: false };
    this.props.changeState(states);
  }

  render() {
    const { team, spotlightId, problem, solution, objetives, benefits } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} style={{ minWidth: "600px" }}>
        {this.props.type === 'team' && <DialogTitle>Editar equipo del proyecto</DialogTitle>}
        {this.props.type === 'about' && <DialogTitle>Editar foco del proyecto</DialogTitle>}
        {this.props.type === 'resume' && <DialogTitle>Editar resumen del proyecto</DialogTitle>}
        {this.props.type === 'solution' && <DialogTitle>Editar problema del proyecto</DialogTitle>}
        {this.props.type === 'objetives' && <DialogTitle>Editar solución del proyecto</DialogTitle>}
        {this.props.type === 'benefits' && <DialogTitle>Editar beneficios del proyecto</DialogTitle>}
        <DialogContent>
          {this.props.type === 'team' && (
            <TextField
              value={team}
              name="team"
              label="Equipo"
              multiline
              fullWidth
              onChange={e => this.setState({ team: e.target.value })}
            />
          )}
          {this.props.type === 'about' && (
            <TextField
              value={spotlightId}
              name="spotlightId"
              select
              fullWidth
              onChange={e => this.setState({ spotlightId: e.target.value })}
            >
              {this.state.spotlights.map(spotlight => (
                <MenuItem key={spotlight.id} value={spotlight.id}>{spotlight.name}</MenuItem>
              ))}
            </TextField>
          )}
          {this.props.type === 'resume' && (
            <TextField
              value={problem}
              name="team"
              label="Resumen"
              multiline
              fullWidth
              onChange={e => this.setState({ problem: e.target.value })}
            />
          )}
          {this.props.type === 'solution' && (
            <TextField
              value={solution}
              name="team"
              label="Solución"
              multiline
              fullWidth
              onChange={e => this.setState({ solution: e.target.value })}
            />
          )}
          {this.props.type === 'objetives' && (
            <TextField
              value={objetives}
              name="team"
              label="Solución"
              multiline
              fullWidth
              onChange={e => this.setState({ objetives: e.target.value })}
            />
          )}
          {this.props.type === 'benefits' && (
            <TextField
              value={benefits}
              name="team"
              label="Beneficios"
              multiline
              fullWidth
              onChange={e => this.setState({ benefits: e.target.value })}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            style={{ color: "#009688" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={this.onSubmit}
            color="primary"
            style={{ color: "#009688" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withCookies(EditProjectDetails);
