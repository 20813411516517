import React from "react";
import styled from "styled-components";
import { Button, Typography, Card } from "@material-ui/core";
import { routes } from "../../../setup/routes";
import { getById } from "../../common/project/api/actions";
import { getList } from "../../common/period/api/actions";
import { getById as companyById } from "../../common/company/api/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import * as jwtDecode from "jwt-decode";
import AnnualBillingGraphComponent from "../../common/project/AnnualBillingGraphComponent";
import ProjectMilestonesComponent from "../../common/project/ProjectMilestonesComponent";
import AnnualBillingTableComponent from "../../common/project/AnnualBillingTableComponent";
import { Title, Text, TextStrong, SecondaryButton } from '../../../helpers/Typography';

const SubtitleContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-right: 13px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const InverseContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row-reverse;
`;

const Container = styled.div`
  margin-right: 7%;
  margin-left: 7%;
`;

const StyledCard = styled(Card)`
  && {
    margin-top: 20px;
    padding: 30px;
    min-width: 275px;
  }
`;

const BackButton = styled(Button)`
  && {
    color: #717171;
  }
`;

class ProjectStats extends React.Component {
  constructor() {
    super();
    this.state = {
      id: undefined,
      details: false
    };
  }

  componentDidMount() {
    const id = this.props.history.location.pathname.replace("/projects/", "");
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    this.props.getList(token);
    this.props.getById(id, token);
    this.props.companyById(user.companyId, token);
    this.setState({ id });
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          {this.props.project && (
            <div>
              <div>
                <BackButton onClick={() => this.props.history.goBack()}><FontAwesomeIcon size="2x" icon={faAngleLeft} /></BackButton>
                <Title>{this.props.project.name}</Title>
              </div>
              <SubtitleContainer>
                <div>
                  <Typography variant="body1">
                    <TextStrong>Área de la empresa:</TextStrong> <Text>{this.props.project.Area.name}</Text>
                  </Typography>
                  <Typography variant="body1">
                    <TextStrong>Líder de proyecto:</TextStrong>
                    {" " +
                      this.props.project.leader.firstName +
                      " " +
                      this.props.project.leader.lastName}
                  </Typography>
                </div>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.props.history.push(
                      `${routes.projects.brief}/${this.state.id}`
                    )
                  }
                >
                  Ver el brief del proyecto
                </StyledButton>
              </SubtitleContainer>
            </div>
          )}
          <StyledCard>
            <AnnualBillingGraphComponent changePeriod={true} />
            <InverseContainer>
              <SecondaryButton
                onClick={() => this.setState({ details: !this.state.details })}
              >
                {!this.state.details ? "Ver detalles" : "Esconder detalles"}
              </SecondaryButton>
            </InverseContainer>
            <div hidden={!this.state.details}>
              <hr />  
              <AnnualBillingTableComponent />
            </div>
          </StyledCard>
          <hr />
          <ProjectMilestonesComponent />
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error
  };
}

export default connect(
  mapStateToProps,
  { getById, getList, companyById }
)(withRouter(withCookies(ProjectStats)));
