
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, DialogActions, TablePagination, Select, Card, Fab, FormHelperText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCaretDown, faCaretUp, faSort, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Subtitle, SubHeader } from '../../../helpers/Typography';
import styled from 'styled-components';
import moment from "moment";
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { routesApi } from '../../../setup/routes';
import { withCookies } from 'react-cookie';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import AddActivityModal from './AddActivityModal';
import EditActivityModal from './EditActivityModal';
import { faPen } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled(Button)`
  && {
    background-color: #8c92a1;
    margin-right: 13px;
    &&:hover {
      background-color: #9e9e9e;
    }
  }
`;

const StyledTable = styled(Table)`
  && {
    min-width: 700px;
  }
`;

const Container = styled.div`
  && {
    background-color: #1b2642;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    min-width: 120px; 
    margin-right: 10px;
  }
`;

const StyledTableHead = styled(TableHead)`
  background-color: #7792d8;
`;

const StyledTableBody = styled(TableBody)`
  background-color: #FBFBFB;
`;

const styles = theme => ({
  tableCell: {
    padding: '4px 10px 4px 10px',
  }
});

class ProjectActivitiesComponent extends Component {

  constructor() {
    super();
    this.state = {
      project: undefined,
      activities: [],
      filterActivities: [],
      state: "-",
      addActivity: false,
      editActivity: false,
      year: "-",
      month: "-",
      preState: "-",
      preYear: "-",
      preMonth: "-",
      openFilters: false,
      orderBy: "",
      page: 0,
      rowsPerPage: 5,
    }
  }

  downloadGantt = async () => {
    try {
      const token = this.props.cookies.get("token");
      const headers = { Authorization: `Bearer ${token}` };
      const requestUrl = axios.get(routesApi.projects.getGantt(this.state.project.ganttFile), { headers });
      const responseUrl = await requestUrl;
      // eslint-disable-next-line no-undef
      window.open(responseUrl.data.url, "_blank");
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log({ error });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.project !== state.project) {
      if(props.project) {
        return {
          project: props.project,
          activities: props.project.activities ? props.project.activities : [],
          filterActivities: props.project.activities ? props.project.activities : [],
        };
      } 
      return null;
    }
    return null;
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeFilter = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilterSubmit = () => {
    let filterActivities = this.state.activities;
    if (this.state.preState !== "-") {
      filterActivities = filterActivities.filter(activity => activity.MilestoneState.name === this.state.preState);
      this.setState({ state: this.state.preState });
    }
    if (this.state.preMonth !== "-") {
      filterActivities = filterActivities.filter(activity => moment(activity.deadline).format("MM").toString() === this.state.preMonth);
      this.setState({ state: this.state.preState });
    }
    if (this.state.preYear !== "-") {
      filterActivities = filterActivities.filter(activity => moment(activity.deadline).format("YYYY").toString() === this.state.preYear);
      this.setState({ state: this.state.preState });
    }
    this.setState({ filterActivities, openFilters: false, orderBy: "" });
  }

  onSort = async (event, sortKey) => {
    const filterActivities = this.state.filterActivities;
    if (this.state.orderBy === sortKey) {
      await this.setState({ desc: !this.state.desc });
    } else {
      await this.setState({ desc: true, orderBy: sortKey });
    }
    if (this.state.desc) {
      if (this.state.orderBy === "state") {
        filterActivities.sort((a, b) => a.MilestoneState.name.localeCompare(b.MilestoneState.name));
      } else {
        filterActivities.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      }
    } else {
      if (this.state.orderBy === "state") {
        filterActivities.sort((a, b) => b.MilestoneState.name.localeCompare(a.MilestoneState.name));
      } else {
        filterActivities.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
      }
    }
    await this.setState({ filterActivities });
  }

  editActivity = activity => {
    this.setState({activity, editActivity: true});
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { classes } = this.props;
    return (
      <Card>
        <Container style={{ backgroundColor: "#1b2642" }}>
          <Subtitle style={{ marginLeft: 15, color: "white" }}>Listado de actividades</Subtitle>
          {this.state.activities && this.state.activities.length > 0 && (
            <div>
              <Tooltip placement="top" title="Pulsa aquí para filtrar las actividades por deadline, estado y fecha real">
                <StyledButton variant="fab" mini color="secondary" aria-label="Filtros" onClick={() => this.setState({ openFilters: true })}>
                  <FontAwesomeIcon icon={faFilter} />
                </StyledButton>
              </Tooltip>
            </div>
          )}
        </Container>
        <div>
          <Dialog open={this.state.openFilters} onEscapeKeyDown={() => this.setState({ openFilters: false })}>
            <DialogTitle>Filtros</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {'Seleccione el deadline o estado de las actividades para filtrarlos, para limpiar los filtros, dejar los dos campos en "Todos".'}
              </DialogContentText>
              <StyledFormControl>
                <InputLabel>Estado</InputLabel>
                <Select onChange={e => this.setState({ preState: e.target.value })} value={this.state.preState} name="preStep">
                  <MenuItem value={"-"}>
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value="Por realizar">Por realizar</MenuItem>
                  <MenuItem value="En desarrollo">En desarrollo</MenuItem>
                  <MenuItem value="Atrasado">Atrasado</MenuItem>
                  <MenuItem value="Completado">Realizado</MenuItem>
                  <MenuItem value="Completado con atraso">Completado con atraso</MenuItem>
                  <MenuItem value="Omitido">Omitido</MenuItem>
                </Select>
              </StyledFormControl>
              <StyledFormControl>
                <InputLabel>Mes de deadline</InputLabel>
                <Select onChange={e => this.setState({ preMonth: e.target.value })} value={this.state.preMonth} name="preStep">
                  <MenuItem value="-">
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value={"01"}>Enero</MenuItem>
                  <MenuItem value={"02"}>Febrero</MenuItem>
                  <MenuItem value={"03"}>Marzo</MenuItem>
                  <MenuItem value={"04"}>Abril</MenuItem>
                  <MenuItem value={"05"}>Mayo</MenuItem>
                  <MenuItem value={"06"}>Junio</MenuItem>
                  <MenuItem value={"07"}>Julio</MenuItem>
                  <MenuItem value={"08"}>Agosto</MenuItem>
                  <MenuItem value={"09"}>Septiembre</MenuItem>
                  <MenuItem value={"10"}>Octubre</MenuItem>
                  <MenuItem value={"11"}>Noviembre</MenuItem>
                  <MenuItem value={"12"}>Diciembre</MenuItem>
                </Select>
              </StyledFormControl>
              <StyledFormControl>
                <InputLabel>Año de deadline</InputLabel>
                <Select onChange={e => this.setState({ preYear: e.target.value })} value={this.state.preYear} name="preStep">
                  <MenuItem value="-">
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value={"2018"}>2018</MenuItem>
                  <MenuItem value={"2019"}>2019</MenuItem>
                  <MenuItem value={"2020"}>2020</MenuItem>
                  <MenuItem value={"2021"}>2021</MenuItem>
                  <MenuItem value={"2022"}>2022</MenuItem>
                </Select>
              </StyledFormControl>
            </DialogContent>
            <DialogActions>
              <StyledButton variant="contained" color="primary" onClick={this.handleFilterSubmit}>Filtrar</StyledButton>
              <StyledButton variant="contained" color="primary" onClick={() => this.setState({ openFilters: false, preArea: this.state.area, preStep: this.state.step })}>
                Cancelar
            </StyledButton>
            </DialogActions>
          </Dialog>
          {(this.state.filterActivities && this.state.filterActivities.length > 0) ? (
            <Paper style={{ width: "100%", overflowX: "auto" }}>
              <StyledTable>
                <StyledTableHead>
                  <TableRow>
                  <TableCell className={classes.tableCell}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>Hito</SubHeader>
                    </TableCell>
                    <TableCell className={classes.tableCell} onClick={e => this.onSort(e, 'name')} style={{ cursor: "pointer" }}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>
                        Actividad {" "}
                        {this.state.orderBy === "name" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                      </SubHeader>
                    </TableCell>
                    <TableCell className={classes.tableCell} onClick={e => this.onSort(e, 'state')} style={{ cursor: "pointer" }}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>
                        Estado {" "}
                        {this.state.orderBy === "state" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                      </SubHeader>
                    </TableCell>
                    <TableCell className={classes.tableCell} onClick={e => this.onSort(e, 'deadline')} style={{ cursor: "pointer" }}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>
                        Deadline {" "}
                        {this.state.orderBy === "deadline" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                      </SubHeader>
                    </TableCell>
                    <TableCell className={classes.tableCell} onClick={e => this.onSort(e, 'realDate')} style={{ cursor: "pointer" }}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>
                        Fecha Real {" "}
                        {this.state.orderBy === "realDate" ? <FontAwesomeIcon icon={this.state.desc ? faCaretDown : faCaretUp} /> : <FontAwesomeIcon icon={faSort} />}
                      </SubHeader>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <SubHeader style={{ display: "inline", whiteSpace: "nowrap", color: "white" }}>Comentarios</SubHeader>
                    </TableCell>
                    {this.props.editProject && <TableCell className={classes.tableCell}></TableCell>}
                  </TableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {this.state.filterActivities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((activity, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{activity.MilestoneProject.Milestone.name}</TableCell>
                      <TableCell className={classes.tableCell}>{activity.name}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={activity.MilestoneState.name} placement="right">
                          <FontAwesomeIcon style={{ color: activity.MilestoneState.color }} icon={faCircle} />
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{moment(activity.deadline).format("DD-MM-YYYY")}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {activity.realDate !== null ? moment(activity.realDate).format("DD-MM-YYYY") : "-"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{activity.comment}</TableCell>
                      {this.props.editProject && <TableCell className={classes.tableCell}>
                        <Fab onClick={() => this.editActivity(activity)} size="small" style={{ backgroundColor: "#8C92A1", color: "#FFFF" }}>
                          <FontAwesomeIcon icon={faPen} />
                        </Fab>
                      </TableCell>}
                    </TableRow>
                  ))}
                </StyledTableBody>
              </StyledTable>
              {this.props.editProject && (
                <div>
                  {this.state.editActivity &&  
                    <EditActivityModal 
                      milestoneProjects={this.props.milestoneProjects} 
                      open={this.state.editActivity} 
                      onClose={() => this.setState({ editActivity: false })} 
                      activity={this.state.activity} 
                    />
                  }
                  {this.state.addActivity && 
                    <AddActivityModal milestoneProjects={this.props.milestoneProjects} 
                      open={this.state.addActivity} onClose={() => this.setState({ addActivity: false })} 
                    />
                  }
                  <br/>
                  <Grid container justify="center" alignItems="baseline">
                    <Grid item xs={2}>
                      <Fab onClick={() => this.setState({ addActivity: true })} size="small" style={{ backgroundColor: "#009688", color: "#FFFF" }}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Fab>
                      <FormHelperText component="span">{"  Agregar actividad"}</FormHelperText>
                    </Grid>
                  </Grid>
                </div>
              )}
              <TablePagination component="div" count={this.state.filterActivities.length} rowsPerPage={rowsPerPage} page={page}
                backIconButtonProps={{ 'arial-label': 'Página anterior' }}
                nextIconButtonProps={{ 'arial-label': 'Página siguiente' }}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count} actividades`}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage} />
            </Paper>
          ) : (
              <SubHeader style={{ width: "100%" }}>No se encontraron actividades registradas para este proyecto</SubHeader>
            )}
          <div>
            {this.state.project && this.state.project.ProjectStep.id !== 6 && this.state.project.ganttFile !== null && <Button onClick={this.downloadGantt}>Descargar Gantt</Button>}
          </div>
        </div>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
  };
}

ProjectActivitiesComponent.propTypes = {
  project: PropTypes.object,
  cookies: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
}

export default connect(
  mapStateToProps,
)(withCookies(withStyles(styles)(ProjectActivitiesComponent)));