import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormHelperText
} from "@material-ui/core";
import { routes } from '../../../setup/routes';
import { forgotPassword } from './api/actions';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledForm = styled.form`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    margin-top: 16px;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    background-color: #11AB99;
    margin-top: 16px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

class ForgotPasswordComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      email: ""
    }
  }

  componentDidMount(){
    if(this.props.auth){
      this.props.history.push(routes.home);
    }
  }

  onSubmit = async e => {
    e.preventDefault();
    this.props.forgotPassword(this.state.email);
  };

  handleChange = e => {
    this.setState({
      email: { ...this.state, [e.target.name]: e.target.value }
    });
  }

  render() {
    return (
      <StyledForm onSubmit={this.onSubmit} method="post">
        <FormControl component="fieldset">
          <FormLabel component="legend">Olvidé mi contraseña</FormLabel>
          <FormGroup>
            <StyledTextField
              label="Correo electrónico"
              type="email"
              name="email"
              id="email"
              required
              onChange={this.handleChange}
            />
            {this.props.error && <FormHelperText style={{ color: "red" }}>{this.props.error}</FormHelperText>}
            {this.props.token && <FormHelperText style={{ color: "green" }}>Link de activación enviado a tu correo</FormHelperText>}
            <StyledSubmitButton type="submit" color="primary" variant="contained">Recuperar contraseña</StyledSubmitButton>
            <StyledSubmitButton onClick={() => this.props.history.push(routes.login)} variant="contained" color="primary">Cancelar</StyledSubmitButton>
          </FormGroup>
        </FormControl>
      </StyledForm>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.forgotError,
    token: state.auth.passwordToken,
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps, { forgotPassword })(withRouter(ForgotPasswordComponent));