import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import EditProjectComponent from '../../common/project/EditProjectComponent';
import styled from 'styled-components';
import * as jwtDecode from "jwt-decode";
import { getById } from '../../common/project/api/actions';
import { getList } from "../../common/period/api/actions";
import { getById as companyById } from "../../common/company/api/actions";

const Container = styled.div`
  margin-right: 7%;
  margin-left: 7%;
`;

class EditView extends Component {

  async componentDidMount(){
    const token = this.props.cookies.get("token");
    const id = this.props.history.location.pathname.replace("/projects/edit/", "");
    const user = jwtDecode(token).user;
    await this.props.getById(id, token);
    await this.props.getList(token);
    await this.props.companyById(user.companyId, token);
  }

  componentWillReceiveProps(){

  }

  render() {
    return (
      <Container>
        <EditProjectComponent />
      </Container>
    );
  }
}

export default connect(
  null, { getById, getList, companyById }
)(withRouter(withCookies(EditView)));