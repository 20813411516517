import React from 'react';
import styled from 'styled-components';
import * as jwtDecode from "jwt-decode";
import moment from "moment";
import { connect } from 'react-redux';
import { getById, cleanProject } from "../../common/project/api/actions";
import { getList } from "../../common/period/api/actions";
import { getList as getDimension } from "../../common/dimension/api/actions";
import { getById as companyById } from "../../common/company/api/actions";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Title, Subtitle } from '../../../helpers/Typography';
import { Card, Button } from '@material-ui/core';
import ProjectBriefComponent from '../../common/project/ProjectBriefComponent';
import AnnualBillingGraphComponent from '../../common/project/AnnualBillingGraphComponent';
import ProjectKPIComponent from '../../common/project/ProjectKPIComponent';
import ProjectInformationComponent from '../../common/project/ProjectInformationComponent';
import ProjectMilestoneTable from '../../common/project/ProjectMilestoneTable';
import ProjectActivitiesComponent from '../../common/project/ProjectActivitiesComponent';
import ProjectScaleBrief from '../../common/project/ProjectScaleBrief';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { PropTypes } from 'prop-types';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../../setup/routes';

const actualDay = moment().format("DD");
const actualYear = moment().format("YYYY");
const today = moment().format("YYYY-MM-DD");
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();

const BackButton = styled(Button)`
  && {
    color: #717171;
  }
`;

const StyledCardTable = styled.div`
  && {
    margin-top: 20px;
    min-width: 100%;
  }
`;
const Container = styled.div`
  margin-right: 7%;
  margin-left: 7%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
`;

const RowContent = styled.div`
  width: 100%;
  @media (min-width: 500px) {
    min-width: 400px;
    width: 49%;
  }
`;

const ContainerTitle = styled.div`
  && {
    background-color: "#1b2642";
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const StyledCard = styled(Card)`
  && {
    min-width: 100%;
  }
`;

const StyledDiv = styled.div`
  && {
    margin-bottom: 5px;
  }
`;

class ProjectBrief extends React.Component {

  constructor() {
    super();
    this.state = {
      project: undefined,
      periods: [],
      periodId: undefined,
    }
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    const id = this.props.history.location.pathname.replace("/projects/brief/", "");
    const user = jwtDecode(token).user;
    this.props.getById(id, token);
    this.props.getList(token);
    this.props.companyById(user.companyId, token);
    this.props.getDimension(user.companyId, token);
  }

  reloadProject = () => {
    const token = this.props.cookies.get("token");
    const id = this.props.history.location.pathname.replace("/projects/brief/", "");
    this.props.getById(id, token);
  }

  static getDerivedStateFromProps(props) {
    if (props.project && props.periods && props.company) {
      const initialPeriod = moment(new Date(Number(actualYear), Number(props.company.InitialMonth.id) - 1, actualDay));
      const startPeriod = initialPeriod.format("YYYY-MM-DD");
      const endPeriod = initialPeriod.add(11, "months").format("YYYY-MM-DD");
      let last = (12 - parseInt(props.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      const period = props.periods.filter(period => period.year.includes(actualYear));
      let periodId = period[0].id;
      if (new Date(endPeriod) < new Date(today))
        periodId = periodId + 1;
      if (new Date(startPeriod) > new Date(today))
        periodId = periodId - 1;
      const periods = props.periods.filter(period => period.id <= periodId);
      return {
        project: props.project,
        periods,
        periodId
      };
    }
    return null;
  }

  async componentWillUnmount() {
    this.props.cleanProject();
    await this.setState({ project: undefined });
  }

  handleChange = e => {
    this.setState({ periodId: e.target.value });
  }

  render() {
    return <React.Fragment>
      <Container>
        <div style={{display: "flex", alignItems: "baseline", marginBottom: "20px"}}>
          <BackButton onClick={() => this.props.history.goBack()}><FontAwesomeIcon size="2x" icon={faAngleLeft} /></BackButton>
          {this.state.project && <Title>{this.state.project.name}</Title>}
          {this.state.project && 
            <BackButton onClick={() => this.props.history.push(`${routes.projects.edit}/${this.state.project.id}`)}>
              <FontAwesomeIcon size="1x" icon={faPen} />
            </BackButton>
          }
        </div>
        {this.state.project && this.state.project.ProjectStep.id === 6 ?
          <div>
            <StyledDiv>
              <ProjectKPIComponent periodId={this.state.periodId} />
            </StyledDiv>
            <Row>
              <StyledCard>
                <ContainerTitle style={{ backgroundColor: "#1b2642" }}>
                  <Subtitle style={{ marginLeft: 15, color: "white" }}>
                    {this.props.company ? this.props.company.EconomicIndicator.indicator : ""} Real vs Presupuestado
                  </Subtitle>
                </ContainerTitle>
                <div style={{padding: "30px"}}>
                  <AnnualBillingGraphComponent changePeriod={false} />
                </div>
              </StyledCard>
            </Row>
            <Row style={{marginTop: "20px"}}>
              <ProjectScaleBrief />
              {/* <RowContent style={{marginRight: "10px"}}>
                <StyledDiv>
                  <ProjectBriefComponent reload={this.reloadProject} />
                </StyledDiv>
                <StyledDiv>
                  <ProjectInformationComponent />
                </StyledDiv>
              </RowContent>
              <RowContent>
                <StyledCard>
                  <AnnualBillingGraphComponent changePeriod={false} />
                </StyledCard>
              </RowContent> */}
            </Row>
            {/* <Row>
              <StyledCardTable>
                <ProjectDimensionKPITable />
              </StyledCardTable>
            </Row>
            <Row>
              <StyledCardTable>
                <ProjectDimensionKPITableForm reload={this.reloadProject} />
              </StyledCardTable>
            </Row> */}
          </div>
          :
          <div>
            <StyledDiv>
              <ProjectKPIComponent periodId={this.state.periodId} />
            </StyledDiv>
            <Row>
              <RowContent>
                <ProjectMilestoneTable reload={this.reloadProject} />
              </RowContent>
              <RowContent>
                <StyledDiv>
                  <ProjectBriefComponent reload={this.reloadProject} />
                </StyledDiv>
                <StyledDiv>
                  <ProjectInformationComponent />
                </StyledDiv>
              </RowContent>
            </Row>
            <Row>
              <StyledCardTable>
                <ProjectActivitiesComponent />
              </StyledCardTable>
            </Row>
          </div>
        }
      </Container>
    </React.Fragment>
  }
}

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    company: state.companies.company,
  };
}

ProjectBrief.propTypes = {
  project: PropTypes.object,
  company: PropTypes.object,
  periods: PropTypes.array,
  history: PropTypes.object,
  cookies: PropTypes.object,
  getById: PropTypes.func,
  getList: PropTypes.func,
  cleanProject: PropTypes.func,
  companyById: PropTypes.func,
  getDimension: PropTypes.func,
}

export default connect(mapStateToProps, { getById, getList, companyById, getDimension, cleanProject })(withRouter(withCookies(ProjectBrief)));
