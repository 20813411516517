import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withCookies } from "react-cookie";
import MenuItem from "@material-ui/core/MenuItem";
import { getList } from "../period/api/actions";
import { create } from "../economicInformation/api/actions";
import * as moment from "moment";

const lastMonth = moment().subtract(1, "month");

const SubmitButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin: 200px;
  }
`;

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction; row;
    align-content: flex-start;
  }
`;

const ContainerContent = styled.div`
  && {
    width: 100%;
  }
`;

// Se puede usar para notificar al usuario de errores dentro del formulario.
const StyledFormHelperText = styled(FormHelperText)`
  && {
    color: #ba0f0f;
  }
`;

const CancelButton = styled(Button)`
  && {
    background-color: #ba0f0f;
    margin-top: 16px;
    color: #ffff;
    &&:hover {
      background-color: #eab6b8;
    }
  }
`;

class CompanyAnnualBillingComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: "",
      periods: undefined,
      billing: {
        month1: 0,
        month2: 0,
        month3: 0,
        month4: 0,
        month5: 0,
        month6: 0,
        month7: 0,
        month8: 0,
        month9: 0,
        month10: 0,
        month11: 0,
        month12: 0,
        periodId: 1
      },
      month: "",
      year: ""
    };
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    this.props.getList(token);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({
      open: nextProps.open,
      periods: nextProps.periods,
      month: lastMonth.format("MMMM").toLowerCase(),
      year: lastMonth.format("YYYY")
    });
  }

  handleChange = name => async event => {
    const billing = {
      billing: { ...this.state.billing, [name]: parseInt(event.target.value, 10) }
    };
    await this.setState(billing);
  };

  handleClose = async () => {
    this.setState({ open: false });
    this.props.close();
  };

  onSubmit = async e => {
    e.preventDefault();
    const token = this.props.cookies.get("token");
    const payload = {
      ...this.state.billing,
      companyId: this.props.companyId,
      analysisTypeId: 2
    };
    this.props.create(payload, token);
    this.props.close();
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        aria-labelledby="form-dialog-title"
        onEscapeKeyDown={this.handleClose}
      >
        {!this.props.isLoading ? (
          <div>
            <DialogTitle id="form-dialog-title">
              Agregar facturación anual estimada de la empresa
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {"Ingresa el valor proyectado para los siguientes meses"}
              </DialogContentText>
              <h3>Proyecciones</h3>
              <form onSubmit={this.onSubmit}>
                <Container>
                  <ContainerContent>
                    {this.props.periods ? (
                      <TextField
                        select
                        label="Periodo"
                        value={this.state.billing.periodId}
                        fullWidth
                        required
                        onChange={this.handleChange("periodId")}
                      >
                        {this.props.periods.map((period, index) => (
                          <MenuItem key={index} value={period.id}>
                            {period.year}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      ""
                    )}
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month1"
                      label="Mes 1"
                      type="number"
                      value={this.state.month1}
                      onChange={this.handleChange("month1")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month2"
                      label="Mes 2"
                      type="number"
                      value={this.state.month2}
                      onChange={this.handleChange("month2")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month3"
                      label="Mes 3"
                      type="number"
                      value={this.state.month3}
                      onChange={this.handleChange("month3")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month4"
                      label="Mes 4"
                      type="number"
                      value={this.state.month4}
                      onChange={this.handleChange("month4")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month5"
                      label="Mes 5"
                      type="number"
                      value={this.state.month5}
                      onChange={this.handleChange("month5")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month6"
                      label="Mes 6"
                      type="number"
                      value={this.state.month6}
                      onChange={this.handleChange("month6")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month7"
                      label="Mes 7"
                      type="number"
                      value={this.state.month7}
                      onChange={this.handleChange("month7")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month8"
                      label="Mes 8"
                      type="number"
                      value={this.state.month8}
                      onChange={this.handleChange("month8")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month9"
                      label="Mes 9"
                      type="number"
                      value={this.state.month9}
                      onChange={this.handleChange("month9")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month10"
                      label="Mes 10"
                      type="number"
                      value={this.state.month10}
                      onChange={this.handleChange("month10")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month11"
                      label="Mes 11"
                      type="number"
                      value={this.state.month11}
                      onChange={this.handleChange("month11")}
                      required
                    />
                    <TextField
                      margin="dense"
                      style={{marginRight: 10}}
                      id="month12"
                      label="Mes 12"
                      type="number"
                      value={this.state.month12}
                      onChange={this.handleChange("month12")}
                      required
                    />
                  </ContainerContent>
                </Container>
                <DialogActions>
                  <CancelButton onClick={this.props.close}>
                    Cancelar
                  </CancelButton>
                  <SubmitButton type="submit">Ingresar</SubmitButton>
                </DialogActions>
              </form>
            </DialogContent>
          </div>
        ) : (
          <StyledCircularProgress size={100} thickness={6} />
        )}
      </Dialog>
    );
  }
}

PropTypes.CompanyAnnualBillingComponent = {
  open: PropTypes.bool.isRequired,
  companyId: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    periods: state.periods.periods,
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps,
  { getList, create }
)(withCookies(CompanyAnnualBillingComponent));
