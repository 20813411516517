// Imports
import axios from 'axios'
import { routesApi } from '../../../../setup/routes';

// Actions Types
export const MILESTONE_LIST_REQUEST = 'MILESTONE/GET_LIST_REQUEST';
export const MILESTONE_LIST_RESPONSE = 'MILESTONE/GET_LIST_RESPONSE';
export const MILESTONE_LIST_FAILURE = 'MILESTONE/GET_LIST_FAILURE';
export const LIST_NEXT_REQUEST = 'MILESTONE/GET_LIST_NEXT_REQUEST';
export const LIST_NEXT_RESPONSE = 'MILESTONE/GET_LIST_NEXT_RESPONSE';
export const LIST_NEXT_FAILURE = 'MILESTONE/GET_LIST_NEXT_FAILURE';
export const MILESTONE_STATE_LIST_REQUEST = 'MILESTONE_STATE/GET_LIST_REQUEST';
export const MILESTONE_STATE_LIST_RESPONSE = 'MILESTONE_STATE/GET_LIST_RESPONSE';
export const MILESTONE_STATE_LIST_FAILURE = 'MILESTONE_STATE/GET_LIST_FAILURE';
export const CREATE_MILESTONE_REQUEST = 'MILESTONE/CREATE_REQUEST';
export const CREATE_MILESTONE_RESPONSE = 'MILESTONE/CREATE_RESPONSE';
export const CREATE_MILESTONE_FAILURE = 'MILESTONE/CREATE_FAILURE';
export const EDIT_MILESTONE_REQUEST = 'MILESTONE/EDIT_REQUEST';
export const EDIT_MILESTONE_RESPONSE = 'MILESTONE/EDIT_RESPONSE';
export const EDIT_MILESTONE_FAILURE = 'MILESTONE/EDIT_FAILURE';

// Actions

// Get list of elements
export function getNextMilestones(id, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: LIST_NEXT_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const payload = { id };
      const request = axios.post(routesApi.projects.nextMilestones, payload, { headers });
      const response = await request;
      return dispatch({
        type: LIST_NEXT_RESPONSE,
        nextMilestones: response.data.milestones
      });
    } catch (error) {
      return dispatch({
        type: LIST_NEXT_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function getList(id, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: MILESTONE_LIST_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const payload = { id };
      const request = axios.post(routesApi.projects.milestones, payload, { headers });
      const response = await request;
      return dispatch({
        type: MILESTONE_LIST_RESPONSE,
        list: response.data.milestones
      });
    } catch (error) {
      return dispatch({
        type: MILESTONE_LIST_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function getState(token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: MILESTONE_STATE_LIST_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const request = axios.get(routesApi.milestoneState.base, { headers });
      const response = await request;
      return dispatch({
        type: MILESTONE_STATE_LIST_RESPONSE,
        state: response.data.milestoneState
      });
    } catch (error) {
      return dispatch({
        type: MILESTONE_STATE_LIST_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function create(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: CREATE_MILESTONE_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const requestCreate = axios.post(routesApi.milestones.base, payload, { headers });
      const responseCreate = await requestCreate;
      const requestList = axios.post(routesApi.projects.milestones, { id: payload.projectId }, { headers });
      const responseList = await requestList;
      const requestNextList = axios.post(routesApi.projects.nextMilestones, { id: payload.projectId }, { headers });
      const responseNextList = await requestNextList;
      dispatch({
        type: CREATE_MILESTONE_RESPONSE,
        list: responseList.data.milestones,
        nextMilestones: responseNextList.data.milestones
      });
    } catch (error) {
      return dispatch({
        type: CREATE_MILESTONE_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}

export function edit(payload, token, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: EDIT_MILESTONE_REQUEST,
      isLoading
    });
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const requestEdit = axios.put(routesApi.milestones.base, payload, { headers });
      const responseEdit = await requestEdit;
      const requestList = axios.post(routesApi.projects.milestones, { id: payload.projectId }, { headers });
      const responseList = await requestList;
      const requestNextList = axios.post(routesApi.projects.nextMilestones, { id: payload.projectId }, { headers });
      const responseNextList = await requestNextList;
      dispatch({
        type: EDIT_MILESTONE_RESPONSE,
        list: responseList.data.milestones,
        nextMilestones: responseNextList.data.milestones
      });
    } catch (error) {
      return dispatch({
        type: EDIT_MILESTONE_FAILURE,
        error: true,
        errorMessage: error.response.data.error
      });
    }
  }
}