import React from "react";
import CompanyTotalBillingGraphComponent from "../../common/project/CompanyTotalBillingGraphComponent";
import InputByAreaComponent from "../../common/project/InputByAreaComponent";
import ProjectPipelineComponent from "../../common/project/ProjectPipelineComponent";
import styled from "styled-components";
import { Card, Button, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import ProjectTableComponent from "../../common/project/ProjectTableComponent";
import CompanyKPIComponent from "../../common/company/CompanyKPIComponent";
import * as jwtDecode from "jwt-decode";
import { withCookies } from 'react-cookie';
import CompanyAnnualBillingComponent from "../../common/project/CompanyAnnualBillingComponent";
import CompanyMonthlyBillingComponent from "../../common/project/CompanyMonthlyBillingComponent";
import { getList } from '../../common/period/api/actions';
import { getById as companyById } from "../../common/company/api/actions";
import { connect } from 'react-redux';
import { getByCompany, getBySpotlight } from "../../common/project/api/actions";
import { PropTypes } from 'prop-types';
import StackedAreaGraphComponent from "../../common/company/StackedAreaGraphComponent";
import { Title, Subtitle } from '../../../helpers/Typography';
import moment from "moment";
const actualDay = moment().format("DD");
const actualYear = moment().format("YYYY");
const today = moment().format("YYYY-MM-DD");
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();
const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-content: flex-start;
  }
`;

const InverseContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const GraphContainer = styled.div`
  && {
    width: 100%;
  }
`;

const StyledCard = styled.div`
  && {
    margin-top: 20px;
    min-width: 100%;
  }
`;

const StyledGraphCard = styled.div`
  && {
    margin-top: 20px;
    min-width: 48%;
  }
`;

const AreaGraphContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    background-color: #11AB99;
    margin-top: 16px;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

const ViewMargin = styled.div`
  && {
    margin-right: 7%;
    margin-left: 7%;
  }
`;
class InnovationDashboard extends React.Component {

  constructor() {
    super();
    this.state = {
      user: undefined,
      company: undefined,
      billingAnnual: false,
      billingMonth: false,
      addAnualBilling: false,
      addMonthBilling: false,
      initialMonth: undefined,
      periods: [],
      periodId: 0,
      actualPeriodId: 0,
    }
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    await this.props.getList(token);
    await this.props.companyById(user.companyId, token);
    await this.props.getByCompany(user.companyId, token);
    await this.props.getBySpotlight(user.companyId, token);
    this.setState({ user });
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ addAnualBilling: false, addMonthBilling: false });
    if (nextProps.company && nextProps.periods) {
      const initialPeriod = moment(new Date(Number(actualYear), Number(nextProps.company.InitialMonth.id) - 1, actualDay));
      const startPeriod = initialPeriod.format("YYYY-MM-DD");
      const endPeriod = initialPeriod.add(11, "months").format("YYYY-MM-DD");
      let last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      const period = nextProps.periods.filter(period => period.year.includes(actualYear));
      let periodId = period[0].id;
      if (new Date(endPeriod) < new Date(today))
        periodId = periodId + 1;
      if (new Date(startPeriod) > new Date(today))
        periodId = periodId - 1;
      const periods = nextProps.periods.filter(period => period.id <= periodId);
      await this.setState({ company: nextProps.company, periods });
      await nextProps.periods.forEach(async period => {
        if (period.year === `Año ${actualYear}`) {
          await this.setState({ initialMonth: nextProps.company.InitialMonth, periodId, actualPeriod: periodId });
          nextProps.company.EconomicInformations.forEach(economicInformation => {
            if (economicInformation.periodId === periodId && economicInformation.analysisTypeId === 1) {
              this.setState({ addAnualBilling: false });
              if (economicInformation[`month${last}`] === null)
                this.setState({ addMonthBilling: true });
            }
          });
        }
      });
    }
  }

  handleClose = async () => {
    await this.setState({ billingAnnual: false, billingMonth: false });
  }

  handleChange = event => {
    this.setState({ periodId: event.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <ViewMargin>
          {this.state.user && (
            <React.Fragment>
              <CompanyAnnualBillingComponent open={this.state.billingAnnual}
                companyId={this.state.user.companyId}
                close={this.handleClose} />
              <CompanyMonthlyBillingComponent open={this.state.billingMonth}
                indicator={this.state.company.EconomicIndicator.indicator}
                month={lastMonth}
                year={actualYear}
                periodId={this.state.periodId}
                close={this.handleClose} />
            </React.Fragment>
          )}
          <InverseContainer>
            <div>
              {this.state.addAnualBilling && <StyledSubmitButton onClick={() => this.setState({ billingAnnual: true })} variant="contained" color="primary">Agregar proyección anual de la empresa</StyledSubmitButton>}
              {this.state.addMonthBilling && <StyledSubmitButton onClick={() => this.setState({ billingMonth: true })} variant="contained" color="primary">Agregar {this.state.company.EconomicIndicator.indicator} del mes pasado de la empresa</StyledSubmitButton>}
            </div>
            <Title>Dashboard {this.state.company && <span>{"de " + this.state.company.name}</span>}</Title>
          </InverseContainer>
          <br />
          <InverseContainer>
            <div>
              {this.props.periods && (
                <FormControl style={{ minWidth: 100 }}>
                  <InputLabel>Periodo</InputLabel>
                  <Select value={this.state.periodId} onChange={this.handleChange}>
                    {this.state.periods.map((period, index) => {
                      return (
                        <MenuItem key={index} value={period.id}>
                          {period.year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>
            <Subtitle>Indicadores clave de rendimiento</Subtitle>
          </InverseContainer>
          <Container>
            <CompanyKPIComponent periodId={this.state.periodId} />
          </Container>
          <StyledCard style={{marginBottom: "20px"}}>
            <ProjectPipelineComponent />
            {/* <PipelineLegendComponent /> */}
          </StyledCard>
          <Container>
            <GraphContainer>
              <CompanyTotalBillingGraphComponent periodId={this.state.periodId} actualPeriod={this.state.actualPeriod} />
            </GraphContainer>
          </Container>
          <AreaGraphContainer>
            <StyledGraphCard>
              <InputByAreaComponent periodId={this.state.periodId} />
            </StyledGraphCard>
            <StyledGraphCard>
              <StackedAreaGraphComponent periodId={this.state.periodId} />
            </StyledGraphCard>
          </AreaGraphContainer>
          <StyledCard>
            <ProjectTableComponent />
          </StyledCard>
        </ViewMargin>
      </React.Fragment>
    );
  }
}

ProjectPipelineComponent.PropTypes = {
  getById: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  getByCompany: PropTypes.func.isRequired,
  getBySpotlight: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    company: state.companies.company,
    error: state.projects.error
  };
}

export default connect(
  mapStateToProps,
  { getList, companyById, getByCompany, getBySpotlight }
)(withCookies(InnovationDashboard));
