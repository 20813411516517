import React from "react";
import "./StateIcon.css";

export function icon(state) {
  switch (state) {
    case 1:
      return (<svg style={{ width: "17px" }} id='c993de74-4f86-4f5b-bd5f-30e281cc79cc' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 107.66 107.63'>
        <defs />
        <g id='6903de3d-08be-43c6-b196-05464cbe8c6b' data-name='123f7a82-bbff-4c4a-871f-ef671fb0f095'>
          <g id='db691bb2-9454-4e35-b75c-a7d5e4d99216' data-name='fd1d9f20-54cd-4d62-822a-e0d93e88a12d'>
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M45.68,2.07v9.15A43.41,43.41,0,0,0,29.46,18L23,11.48A52.17,52.17,0,0,1,45.68,2.07M47.49,0A53.91,53.91,0,0,0,20.23,11.29l9,9a41.31,41.31,0,0,1,18.23-7.58V0Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M11.48,23,18,29.46a43.37,43.37,0,0,0-6.74,16.22H2.07A52.17,52.17,0,0,1,11.48,23m-.19-2.74A53.92,53.92,0,0,0,0,47.49H12.75a41.33,41.33,0,0,1,7.58-18.23l-9-9Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M96.15,23a52.15,52.15,0,0,1,9.41,22.71H96.4a43.43,43.43,0,0,0-6.74-16.22L96.15,23m.18-2.74-9,9a41.29,41.29,0,0,1,7.58,18.23h12.75A53.91,53.91,0,0,0,96.33,20.23Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M61.94,2.07a52.17,52.17,0,0,1,22.71,9.41L78.16,18a43.35,43.35,0,0,0-16.22-6.74V2.07M60.13,0V12.75a41.34,41.34,0,0,1,18.23,7.58l9-9A53.92,53.92,0,0,0,60.13,0Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M105.55,61.94a52.15,52.15,0,0,1-9.41,22.71l-6.49-6.49A43.38,43.38,0,0,0,96.4,61.94h9.15m2.07-1.81H94.88A41.32,41.32,0,0,1,87.3,78.36l9,9A53.92,53.92,0,0,0,107.59,60.1Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M78.16,89.66l6.49,6.49a52.16,52.16,0,0,1-22.71,9.41V96.4a43.42,43.42,0,0,0,16.22-6.74m.2-2.36a41.29,41.29,0,0,1-18.23,7.58v12.75a53.92,53.92,0,0,0,27.26-11.3l-9-9Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M11.22,61.94A43.41,43.41,0,0,0,18,78.16l-6.49,6.49A52.16,52.16,0,0,1,2.07,61.94h9.15m1.52-1.81H0A53.92,53.92,0,0,0,11.29,87.39l9-9a41.32,41.32,0,0,1-7.58-18.23Z'
            />
            <path className='149099bd-60a3-4180-9f42-0a448c06e12f' d='M29.46,89.66A43.4,43.4,0,0,0,45.68,96.4v9.15A52.15,52.15,0,0,1,23,96.14l6.49-6.49m-.2-2.36-9,9a53.92,53.92,0,0,0,27.25,11.29V94.88A41.31,41.31,0,0,1,29.26,87.3Z'
            />
          </g>
        </g>
      </svg>)
    case 2:
      return (<svg style={{ width: "17px" }} id='3db60e8a-b839-43cd-9305-e2bc6700befe' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 107.66 107.63'>
        <defs />
        <g id='01dda566-dfdb-40b6-86a6-3e7f23e9cdd0' data-name='a4d8c19b-cfff-4ed8-9821-b48baf80c0fd'>
          <g id='1e5f0863-c422-49ef-b7b7-5f379bff8609' data-name='26ef6f0e-42ca-42e9-9abd-5174094dfce2'>
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M96.15,23a52.15,52.15,0,0,1,9.41,22.71H96.4a43.43,43.43,0,0,0-6.74-16.22L96.15,23m.18-2.74-9,9a41.29,41.29,0,0,1,7.58,18.23h12.75A53.91,53.91,0,0,0,96.33,20.23Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M61.94,2.07a52.17,52.17,0,0,1,22.71,9.41L78.16,18a43.35,43.35,0,0,0-16.22-6.74V2.07M60.13,0V12.75a41.34,41.34,0,0,1,18.23,7.58l9-9A53.92,53.92,0,0,0,60.13,0Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M105.55,61.94a52.15,52.15,0,0,1-9.4,22.71l-6.49-6.49A43.38,43.38,0,0,0,96.4,61.94h9.15m2.07-1.81H94.88A41.32,41.32,0,0,1,87.3,78.36l9,9A53.92,53.92,0,0,0,107.59,60.1Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M78.16,89.66l6.49,6.49a52.16,52.16,0,0,1-22.71,9.41V96.4a43.42,43.42,0,0,0,16.22-6.74m.2-2.36a41.29,41.29,0,0,1-18.23,7.58v12.75a53.92,53.92,0,0,0,27.26-11.3l-9-9Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M47.5,94.87v12.76A53.65,53.65,0,0,1,20.26,96.34l9-9A42,42,0,0,0,47.5,94.87Zm-1.75,10.7V96.41a43,43,0,0,1-16.22-6.73L23,96.15A52.83,52.83,0,0,0,45.75,105.57Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M47.5,0V12.75a41.45,41.45,0,0,0-18.2,7.56l-9-9A53.65,53.65,0,0,1,47.5,0ZM45.75,11.22V2A52.83,52.83,0,0,0,23,11.47l6.54,6.47A43,43,0,0,1,45.75,11.22Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M45.75,96.41v9.17A52.83,52.83,0,0,1,23,96.15l6.54-6.47A43,43,0,0,0,45.75,96.41Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M45.75,2v9.17A43,43,0,0,0,29.49,18L23,11.47A52.83,52.83,0,0,1,45.75,2Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M11.28,20.26l9,9A41.45,41.45,0,0,0,12.75,47.5H0A53.65,53.65,0,0,1,11.28,20.26ZM18,29.49,11.47,23A52.83,52.83,0,0,0,2,45.75h9.17A43,43,0,0,1,18,29.49Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M20.32,78.39l-9,9A53.65,53.65,0,0,1,0,60.13H12.75A42,42,0,0,0,20.32,78.39Zm-8.85,6.28,6.47-6.54a43,43,0,0,1-6.73-16.22H2a52.83,52.83,0,0,0,9.42,22.77Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M11.47,23l6.47,6.54a43,43,0,0,0-6.72,16.26H2A52.83,52.83,0,0,1,11.47,23Z'
            />
            <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M18,78.14l-6.47,6.54A52.83,52.83,0,0,1,2,61.92h9.17A43,43,0,0,0,18,78.14Z'
            />
          </g>
        </g>
      </svg>)
    case 3:
      return (
        <svg style={{ width: "17px" }} id='3db60e8a-b839-43cd-9305-e2bc6700befe' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 107.66 107.63'>
          <defs />
          <g id='01dda566-dfdb-40b6-86a6-3e7f23e9cdd0' data-name='a4d8c19b-cfff-4ed8-9821-b48baf80c0fd'>
            <g id='1e5f0863-c422-49ef-b7b7-5f379bff8609' data-name='26ef6f0e-42ca-42e9-9abd-5174094dfce2'>
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M96.15,23a52.15,52.15,0,0,1,9.41,22.71H96.4a43.43,43.43,0,0,0-6.74-16.22L96.15,23m.18-2.74-9,9a41.29,41.29,0,0,1,7.58,18.23h12.75A53.91,53.91,0,0,0,96.33,20.23Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M61.94,2.07a52.17,52.17,0,0,1,22.71,9.41L78.16,18a43.35,43.35,0,0,0-16.22-6.74V2.07M60.13,0V12.75a41.34,41.34,0,0,1,18.23,7.58l9-9A53.92,53.92,0,0,0,60.13,0Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M105.55,61.94a52.15,52.15,0,0,1-9.4,22.71l-6.49-6.49A43.38,43.38,0,0,0,96.4,61.94h9.15m2.07-1.81H94.88A41.32,41.32,0,0,1,87.3,78.36l9,9A53.92,53.92,0,0,0,107.59,60.1Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M78.16,89.66l6.49,6.49a52.16,52.16,0,0,1-22.71,9.41V96.4a43.42,43.42,0,0,0,16.22-6.74m.2-2.36a41.29,41.29,0,0,1-18.23,7.58v12.75a53.92,53.92,0,0,0,27.26-11.3l-9-9Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M47.5,94.87v12.76A53.65,53.65,0,0,1,20.26,96.34l9-9A42,42,0,0,0,47.5,94.87Zm-1.75,10.7V96.41a43,43,0,0,1-16.22-6.73L23,96.15A52.83,52.83,0,0,0,45.75,105.57Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M47.5,0V12.75a41.45,41.45,0,0,0-18.2,7.56l-9-9A53.65,53.65,0,0,1,47.5,0ZM45.75,11.22V2A52.83,52.83,0,0,0,23,11.47l6.54,6.47A43,43,0,0,1,45.75,11.22Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M45.75,96.41v9.17A52.83,52.83,0,0,1,23,96.15l6.54-6.47A43,43,0,0,0,45.75,96.41Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M45.75,2v9.17A43,43,0,0,0,29.49,18L23,11.47A52.83,52.83,0,0,1,45.75,2Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M11.28,20.26l9,9A41.45,41.45,0,0,0,12.75,47.5H0A53.65,53.65,0,0,1,11.28,20.26ZM18,29.49,11.47,23A52.83,52.83,0,0,0,2,45.75h9.17A43,43,0,0,1,18,29.49Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M20.32,78.39l-9,9A53.65,53.65,0,0,1,0,60.13H12.75A42,42,0,0,0,20.32,78.39Zm-8.85,6.28,6.47-6.54a43,43,0,0,1-6.73-16.22H2a52.83,52.83,0,0,0,9.42,22.77Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M11.47,23l6.47,6.54a43,43,0,0,0-6.72,16.26H2A52.83,52.83,0,0,1,11.47,23Z'
              />
              <path className='302857e2-6c2c-4c92-8e31-59f4005b1694' d='M18,78.14l-6.47,6.54A52.83,52.83,0,0,1,2,61.92h9.17A43,43,0,0,0,18,78.14Z'
              />
            </g>
          </g>
        </svg>
      )
    default:
      return ""
  }
}