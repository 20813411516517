import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../setup/routes";
import Layout from "./common/Layout";
import Home from "./views/Home";
import Login from "./common/login/Login";
import { withCookies } from "react-cookie";
import Logout from "./common/login/Logout";
import Authorization from "./common/authorization/Authorization";
import ForgotPassword from "./views/ForgotPassword";
import RecoveryPassword from "./views/RecoveryPassword";
import IndustriesList from "./views/industries/ListView";
import ProjectList from "./views/projects/ListView";
import MilestoneList from "./views/milestones/ListView";
import ProjectBrief from "./views/projects/BriefView";
import ProjectStats from "./views/projects/StatsView";
import InnovationDashboard from './views/innovation/DashboardView';
import * as moment from "moment";
import localization from 'moment/locale/es';
import AreaListView from './views/areas/ListView';
import DashboardView from "./views/areas/DashboardView"
import NotFoundView from "./views/error/404";
import ProjectEdit from './views/projects/EditView';
import { APP_URL_API } from "../setup/config";
import NewCompany from "./views/fillForm/newCompany/NewCompany";
moment.locale('es');
//Authorization
const Public = Authorization(["Public"]);
const Auth = Authorization(["Auth"]);
const Admin = Authorization(["Administrador"]);
const Leader = Authorization(["Líder de proyecto"]);
const Area = Authorization(["Gerente de Área"]);
const Innovation = Authorization(["Gerente de Innovación", "Administrador"]);
// Mix
const InnovationArea = Authorization([
  "Gerente de Innovación",
  "Gerente de Área",
  "Administrador"
]);
const InnovationAreaLeader = Authorization([
  "Gerente de Innovación",
  "Gerente de Área",
  "Administrador",
  "Líder de proyecto"
]);
// Component
class App extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path={routes.home} component={Public(Home)} />
          <Route exact path={routes.company.dashboard} component={Innovation(InnovationDashboard)} />
          <Route exact path={routes.company.areas} component={Innovation(AreaListView)} />
          <Route exact path={routes.admin.base} component={Admin(NewCompany)} />
          <Route path={routes.company.areas} component={InnovationArea(DashboardView)} />
          <Route path={routes.login} component={Public(Login)} />
          <Route path={routes.logout} component={Public(Logout)} />
          <Route path={routes.forgotPassword} component={Public(ForgotPassword)} />
          <Route path={routes.recoveryPassword} component={Public(RecoveryPassword)} />
          <Route
            path={routes.milestones.base}
            component={Admin(MilestoneList)}
          />
          <Route
            exact
            path={routes.industries.base}
            component={Admin(IndustriesList)}
          />
          
          {/* Project views */}
          <Route
            exact
            path={routes.projects.base}
            component={InnovationAreaLeader(ProjectList)}
          />
          <Route path={routes.projects.edit} component={InnovationAreaLeader(ProjectEdit)} />
          <Route path={routes.projects.brief} component={InnovationAreaLeader(ProjectBrief)} />
          <Route path={routes.projects.base} component={InnovationAreaLeader(ProjectStats)} />
          <Route component={Public(NotFoundView)} />
        </Switch>
      </Layout>
    );
  }
}

export default withCookies(App);
