// Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getList } from './api/actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { withCookies } from "react-cookie";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ListComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      create: false
    }
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    this.props.getList(token);
  }

  handleClose() {
    this.setState({ create: false });
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          {this.props.isLoading && (<CircularProgress size={100} thickness={6} />)}
          {!this.props.isLoading && this.props.error && (<h1>Ocurrió un error intentando recuperar las industrias</h1>)}
        </StyledDiv>
        <Paper>
          {this.props.list.length < 0 && !this.props.isLoading && (<h1>No hay industrias registradas</h1>)}
          {this.props.list.length > 0 && !this.props.isLoading && !this.props.error && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Nombre de la industria</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              {!this.props.isLoading && (
                <TableBody>
                  {this.props.list.map((industry, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{industry.name}</TableCell>
                        <TableCell>{industry.id}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          )}
        </Paper>
      </React.Fragment>
    )
  }
}

ListComponent.propTypes = {
  list: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    list: state.industries.list,
    isLoading: state.industries.isLoading,
    error: state.industries.error
  }
}

export default connect(mapStateToProps, { getList })(withCookies(ListComponent))