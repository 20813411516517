// Imports
import React, {Component} from 'react';

// App Imports
import NavBar from './layout/NavBar';

const styles = {
  page: {
    padding: "20px"
  }
}
// Component
class Layout extends Component {

  render() {
    const {children} = this.props

    return (
      <React.Fragment>
        <NavBar />
        <div style={styles.page}>
          {children}
        </div>
      </React.Fragment>
    )
  }
}

export default Layout