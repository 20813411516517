import React, { Component } from 'react';
import axios from 'axios';
import * as jwtDecode from 'jwt-decode';
import { routesApi, routes } from '../../../setup/routes';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import styled from 'styled-components';
import { Card, CardActions, CircularProgress, CardContent, Button } from '@material-ui/core';
import { Title, SubHeader, Subtitle } from '../../../helpers/Typography';

const StyledDivCard = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-content: flex-start;
  }
`;

const StyledCard = styled(Card)`
  && {
    width: 300px;
    margin: 7px;
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  &&{
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    background-color: #11AB99;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

class AreaListComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      areas: [],
      isLoading: true,
      error: false,
    }
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const areasPromise = axios.post(routesApi.companies.areas, { companyId: user.companyId }, { headers });
      const areas = await areasPromise;
      this.setState({ areas: areas.data.areas, isLoading: false });
    } catch (error) {
      this.setState({ error: true, isLoading: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <Title>Listado de áreas de la empresa</Title>
          <Button disabled color="primary">Agregar nueva área</Button>
        </Container>
        <hr />
        <StyledDiv>
          {this.state.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
          {!this.state.isLoading &&
            this.state.error && (
              <SubHeader>Ocurrió un error intentando recuperar las áreas</SubHeader>
            )}
        </StyledDiv>
        {this.state.areas.length > 0 && (
          <StyledDivCard>
            {this.state.areas.map((area, index) => {
              return (
                <StyledCard key={index}>
                  <CardContent>
                    <Subtitle>{area.name}</Subtitle>
                  </CardContent>
                  <CardActions>
                    <StyledSubmitButton onClick={() => this.props.history.push(`${routes.company.areas}/${area.id}`)} variant="contained" color="primary">
                      Ver área
                    </StyledSubmitButton>
                    <Button onClick={() => this.props.history.push(`${routes.company.areas}/${area.id}`)} disabled>
                      Editar área
                    </Button>
                  </CardActions>
                </StyledCard>
              );
            })}
          </StyledDivCard>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(withCookies(AreaListComponent))