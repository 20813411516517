import React, { Component } from "react";
import { connect } from 'react-redux';
import { withCookies } from "react-cookie";
import Forbidden from "./Forbidden";
import * as jwtDecode from "jwt-decode";
import { Redirect } from 'react-router-dom';
import { routes } from "../../../setup/routes";
import { setToken } from "../login/api/actions";

// Authorization HOC
const Authorization = allowedRoles => WrappedComponent => {
  return connect(mapStateToProps, { setToken })(withCookies(
    class WithAuthorization extends Component {
      constructor(props) {
        super(props);
        this.state = {};
      }

      async componentDidMount() {
        if (typeof this.props.cookies.get("token") !== 'undefined') {
          const token = this.props.cookies.get("token");
          if (!this.props.auth) {
            this.props.setToken(token);
          }
        }
      }

      render() {        
        /* Check if the view is public */
        if (allowedRoles.includes("Public")) {
          return <WrappedComponent {...this.props} />;
        }

        /* if no cookie created */
        if (typeof this.props.cookies.get("token") === 'undefined') {
          // const { cookies } = this.props;
          // cookies.remove("token");
          // window.location.reload();
          return <Redirect to={routes.login} />;
        } else {
          const token = this.props.cookies.get("token");
          const user = jwtDecode(token).user
          const rol = user.rol

          /* if user is not active, display forbidden */
          if (!user.isActive) {
            return <Forbidden />;
          }

          /* Check if the user has the allowed roles */
          let allowed = false;

          if (allowedRoles.includes(rol)) {
            allowed = true;
          }          
          if (allowed) {
            return <WrappedComponent {...this.props} />;
          } else {
            return <Forbidden />;
          }
        }
      }
    }
  ));
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
    auth: state.auth.auth,
  };
}

export default Authorization;
