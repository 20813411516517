// App Imports
import { APP_URL_API } from './config'

// APP Routes
export const routes = {
  home: '/',
  about: '/about',
  login: '/login',
  logout: '/logout',
  profile: '/profile',
  forgotPassword: '/forgotten-password',
  recoveryPassword: '/recovery-password',
  admin: {
    base: '/admin',
  },
  areas: {
    base: '/areas'
  },
  industries: {
    base: '/industries',
    create: '/industries/register'
  },
  company: {
    dashboard: '/dashboard',
    areas: '/areas'
  },
  projects: {
    base: '/projects',
    brief: '/projects/brief',
    create: '/projects/register',
    edit: '/projects/edit'
  },
  users: {
    getUsers: '/users',
    register: '/users/register'
  },
  notifications: {
    getNotifications: '/notifications',
    create: '/notifications/create',
  },
  milestones: {
    base: `/milestones`,
  }
}

export const routesApi = {
  auth: {
    login: `${APP_URL_API}/login`,
    changePassword: `${APP_URL_API}/change-password`,
    forgotPassword: `${APP_URL_API}/forgot-password`,
    recoveryPassword: `${APP_URL_API}/recovery-password`,
  },
  areas: {
    projects: `${APP_URL_API}/areas/projects`,
  },
  currencies: {
    base: `${APP_URL_API}/currencies`,
  },
  companies: {
    areas: `${APP_URL_API}/companies/areas`,
    base: `${APP_URL_API}/companies`,
    getById: id => `${APP_URL_API}/companies/${id}`,
  },
  industries: {
    base: `${APP_URL_API}/industries`,
    getById: id => `${APP_URL_API}/industries/${id}`,
  },
  economicAnalysis: {
    base: `${APP_URL_API}/economicAnalysis`,
    addMonthlyBilling: `${APP_URL_API}/economicAnalysis/perMonth`,
  },
  economicInformation: {
    base: `${APP_URL_API}/economicInformations`,
    addMonthlyBilling: `${APP_URL_API}/economicInformations/perMonth`,
  },
  period: {
    base: `${APP_URL_API}/periods`,
  },
  dimension: {
    base: `${APP_URL_API}/dimensions`,
  },
  projects: {
    base: `${APP_URL_API}/projects`,
    company: `${APP_URL_API}/projects/company`,
    spotlight: `${APP_URL_API}/projects/spotlight`,
    area: `${APP_URL_API}/projects/area`,
    milestones: `${APP_URL_API}/projects/milestones`,
    nextMilestones: `${APP_URL_API}/projects/next-milestones`,
    getById: id => `${APP_URL_API}/projects/${id}`,
    uploadEvaluation: id => `${APP_URL_API}/projects/uploadEvaluation/${id}`,
    getEvaluation: name => `/projects/evaluationFile/${name}`,
    uploadGantt: id => `${APP_URL_API}/projects/uploadGantt/${id}`,
    getGantt: name => `/projects/ganttFile/${name}`,
    actionPlan: `${APP_URL_API}/projects/actionPlan`,
    activities: `${APP_URL_API}/projects/activities`,
  },
  milestones: {
    base: `${APP_URL_API}/milestones`,
    getById: id => `${APP_URL_API}/milestones/${id}`,
  },
  milestoneState: {
    base: `${APP_URL_API}/milestoneStates`,
  },
  users: {
    getUsers: `${APP_URL_API}/users`,
    register: `${APP_URL_API}/users/register`,
    toggleActive: `${APP_URL_API}/users/toggle-active`,
    getUser: id => `${APP_URL_API}/users/${id}`,
    updateRoles: `${APP_URL_API}/users/update-roles`,
  },
  notifications: {
    create: `${APP_URL_API}/notifications/create`,
    markAsReaded: `${APP_URL_API}/notifications/markAsReaded`,
    getNotifications: userId => `${APP_URL_API}/notifications/user/${userId}`,
  },
  roles: {
    getRoles: `${APP_URL_API}/roles`
  },
  spotlights: {
    base: `${APP_URL_API}/spotlights/company`
  }
}