// Imports

// App Imports
import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_RESPONSE,
  PROJECT_LIST_FAILURE,
  PROJECT_BY_ID_REQUEST,
  PROJECT_BY_ID_RESPONSE,
  PROJECT_BY_ID_FAILURE,
  PROJECT_BY_COMPANY_REQUEST,
  PROJECT_BY_COMPANY_RESPONSE,
  PROJECT_BY_COMPANY_FAILURE,
  PROJECT_BY_SPOTLIGHT_REQUEST,
  PROJECT_BY_SPOTLIGHT_RESPONSE,
  PROJECT_BY_SPOTLIGHT_FAILURE,
  PROJECT_BY_AREA_REQUEST,
  PROJECT_BY_AREA_RESPONSE,
  PROJECT_BY_AREA_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_RESPONSE,
  CREATE_PROJECT_FAILURE,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  EDIT_PROJECT_FAILURE,
  CLEAN_PROJECT
} from "./actions";

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  list: []
};

// State
export const projects = (state = elementsInitialState, action) => {
  switch (action.type) {
    case PROJECT_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false,
        project: undefined
      };

    case PROJECT_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.list,
        project: undefined
      };

    case PROJECT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        project: undefined
      };
    case PROJECT_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };

    case PROJECT_BY_ID_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        project: action.project
      };

    case PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        project: undefined,
        error: action.error,
      };
    case PROJECT_BY_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };

    case PROJECT_BY_COMPANY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        companyProjects: action.companyProjects
      };

    case PROJECT_BY_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        companyProjects: undefined,
        error: action.error,
      };
    case PROJECT_BY_SPOTLIGHT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };

    case PROJECT_BY_SPOTLIGHT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        spotlightProjects: action.spotlightProjects
      };

    case PROJECT_BY_SPOTLIGHT_FAILURE:
      return {
        ...state,
        isLoading: false,
        spotlightProjects: undefined,
        error: action.error,
      };
    case PROJECT_BY_AREA_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };
    case PROJECT_BY_AREA_RESPONSE:
      return {
        ...state,
        isLoading: false,
        areaProjects: action.areaProjects,
        error: action.error,
      };
    case PROJECT_BY_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        areaProjects: undefined,
        error: action.error,
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };
    case CREATE_PROJECT_RESPONSE:
      let list = [...state.list];
      list.splice(list.length, 0, action.project);
      return {
        ...state,
        isLoading: false,
        error: false,
        list
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        project: undefined,
        error: action.error,
      };
    case CLEAN_PROJECT:
      return {
        ...state,
        isLoading: false,
        project: undefined,
      };
    case EDIT_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      };
    case EDIT_PROJECT_RESPONSE:
      return {
        ...state,
        project: action.project,
        isLoading: false,
        error: false,
      };
    case EDIT_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        project: undefined,
        error: action.error,
      };

    default:
      return state;
  }
};
