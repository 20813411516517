import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardContent, Typography, Popper, Fade, Paper, Grid, withWidth } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withStyles } from '@material-ui/core/styles';
const cardWidth = ((71.6 / 7) * window.innerWidth) / 100;
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();
const StyledCard = styled(Card)`
  margin-top: 1%;
  min-width: 130px;
  min-height: 130px;
`;

const CenterTypography = styled(Typography)`
  text-align: center;
`;

const styles = theme => ({
  card: {
    backgroundColor: "#1b2642"
  }
});


class CompanyKPIComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpis: [],
      active: undefined,
      anchorEl: null
    };
  }

  componentWillReceiveProps(nextProps) {
    let kpis = [];
    let portfolioTotal = 0;
    let realBillingProjects = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let realBillingCompany = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedBillingProject = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let projectList = [];
    let projectSteps = [
      { id: 2, step: "Detectar", percentage: "detectPercentage" },
      { id: 3, step: "Idear", percentage: "devisePercentage" },
      { id: 4, step: "Diseñar", percentage: "designPercentage" },
      { id: 5, step: "Pilotear", percentage: "prototypePercentage" },
      { id: 6, step: "Escalar", percentage: "escalatePercentage" }
    ];
    let portfolioValue = [];
    let projects = 0;
    let escalate = 0;
    let last = 0;
    let lastRealMonth = 0;
    let lastEstimatedMonth = 0;
    if (nextProps.company && nextProps.periods) {
      last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (nextProps.company.EconomicInformations) {
        nextProps.company.EconomicInformations.forEach(economicInformation => {
          if (economicInformation.analysisTypeId === 1 && economicInformation.periodId === nextProps.periodId) {
            realBillingCompany[0] = realBillingCompany[0] + economicInformation.month1;
            realBillingCompany[1] = realBillingCompany[1] + economicInformation.month2;
            realBillingCompany[2] = realBillingCompany[2] + economicInformation.month3;
            realBillingCompany[3] = realBillingCompany[3] + economicInformation.month4;
            realBillingCompany[4] = realBillingCompany[4] + economicInformation.month5;
            realBillingCompany[5] = realBillingCompany[5] + economicInformation.month6;
            realBillingCompany[6] = realBillingCompany[6] + economicInformation.month7;
            realBillingCompany[7] = realBillingCompany[7] + economicInformation.month8;
            realBillingCompany[8] = realBillingCompany[8] + economicInformation.month9;
            realBillingCompany[9] = realBillingCompany[9] + economicInformation.month10;
            realBillingCompany[10] = realBillingCompany[10] + economicInformation.month11;
            realBillingCompany[11] = realBillingCompany[11] + economicInformation.month12;
          }
        });
      }
      if (nextProps.areas) {
        nextProps.areas.forEach(area => {
          area.Projects.forEach(project => {
            projectList.push(project);
            projects++;
            if (project.ProjectStep.name === "Escalar") escalate++;
            project.EconomicAnalyses.forEach(economicAnalysis => {
              if (economicAnalysis.analysisTypeId === 1 && economicAnalysis.periodId === nextProps.periodId) {
                lastRealMonth = lastRealMonth + economicAnalysis["month" + last];
                realBillingProjects[0] = realBillingProjects[0] + economicAnalysis.month1;
                realBillingProjects[1] = realBillingProjects[1] + economicAnalysis.month2;
                realBillingProjects[2] = realBillingProjects[2] + economicAnalysis.month3;
                realBillingProjects[3] = realBillingProjects[3] + economicAnalysis.month4;
                realBillingProjects[4] = realBillingProjects[4] + economicAnalysis.month5;
                realBillingProjects[5] = realBillingProjects[5] + economicAnalysis.month6;
                realBillingProjects[6] = realBillingProjects[6] + economicAnalysis.month7;
                realBillingProjects[7] = realBillingProjects[7] + economicAnalysis.month8;
                realBillingProjects[8] = realBillingProjects[8] + economicAnalysis.month9;
                realBillingProjects[9] = realBillingProjects[9] + economicAnalysis.month10;
                realBillingProjects[10] = realBillingProjects[10] + economicAnalysis.month11;
                realBillingProjects[11] = realBillingProjects[11] + economicAnalysis.month12;
              } else if (economicAnalysis.analysisTypeId === 2 && economicAnalysis.periodId === nextProps.periodId) {
                estimatedBillingProject[0] = estimatedBillingProject[0] + economicAnalysis.month1;
                estimatedBillingProject[1] = estimatedBillingProject[1] + economicAnalysis.month2;
                estimatedBillingProject[2] = estimatedBillingProject[2] + economicAnalysis.month3;
                estimatedBillingProject[3] = estimatedBillingProject[3] + economicAnalysis.month4;
                estimatedBillingProject[4] = estimatedBillingProject[4] + economicAnalysis.month5;
                estimatedBillingProject[5] = estimatedBillingProject[5] + economicAnalysis.month6;
                estimatedBillingProject[6] = estimatedBillingProject[6] + economicAnalysis.month7;
                estimatedBillingProject[7] = estimatedBillingProject[7] + economicAnalysis.month8;
                estimatedBillingProject[8] = estimatedBillingProject[8] + economicAnalysis.month9;
                estimatedBillingProject[9] = estimatedBillingProject[9] + economicAnalysis.month10;
                estimatedBillingProject[10] = estimatedBillingProject[10] + economicAnalysis.month11;
                estimatedBillingProject[11] = estimatedBillingProject[11] + economicAnalysis.month12;
              }
            });
          });
        });
        projectSteps.forEach(step => {
          let stepImpact = 0;
          projectList.forEach(project => {
            if (project.ProjectStep.id === step.id) {
              stepImpact = stepImpact + project.impact;
            }
          });
          portfolioValue.push({ step: step.step, stepImpact, stepValue: stepImpact * (nextProps.company[step.percentage] / 100) })
        });
        portfolioValue.forEach(portfolio => {
          portfolioTotal = portfolioTotal + portfolio.stepValue;
        });
      }
    }
    let totalProjects = realBillingProjects.reduce(
      (x, y, index) => (realBillingProjects[index] = x + y)
    );
    let totalCompany = realBillingCompany.reduce(
      (x, y, index) => (realBillingCompany[index] = x + y)
    );
    let totalProjectEstimated = estimatedBillingProject.reduce(
      (x, y, index) => (realBillingCompany[index] = x + y)
    );
    // Total innovación / Total empresa
    const i3 = ((totalProjects / totalCompany) * 100).toFixed(2).replace(".", ",") + "%";
    const accomplishment = ((totalProjects / totalProjectEstimated) * 100).toFixed(2).replace(".", ",") + "%";
    totalProjects = "$ " + (totalProjects / 1000000).toFixed(1).replace(".", ",") + "MM";
    portfolioTotal = "$ " + (portfolioTotal / 1000000).toFixed(1).replace(".", ",") + "MM";
    lastRealMonth = "$ " + (lastRealMonth / 1000000).toFixed(1).replace(".", ",") + "MM";
    kpis.push(
      { number: i3, label: "Índice Intensidad de Innovación (i3)", dropTitle: "", description: `Porcentaje del resultado total de la compañía que proviene de proyectos de innovación durante el mes pasado` },
      { number: lastRealMonth, label: "EBITDA Innovación " + moment().subtract(1, 'month').format("MM-YYYY"), dropTitle: "", description: "Aporte generado por todos los proyectos de innovación durante el mes pasado" },
      { number: totalProjects, label: `${nextProps.company ? nextProps.company.EconomicIndicator.indicator : ""} Innovación a la fecha (${nextProps.company ? nextProps.company.Currency.name : " "})`, dropTitle: ``, description: "Aporte proyectado a generar con todos los proyectos de innovación durante el periodo actual" },
      { number: accomplishment, label: "Cumplimiento de la meta anual ", dropTitle: "", description: "Porcentaje de cumplimiento de la meta del año hasta el mes pasado" },
      { number: portfolioTotal, label: "Valor del portafolio", dropTitle: `En ${nextProps.company ? nextProps.company.Currency.name : " "}`, description: "Suma del potencial económico en régimen que tiene el portafolio, dada la probabilidad de éxito de cada proyecto según su etapa en el proceso de innovación" },
      { number: projects, label: "Proyectos en el portafolio", dropTitle: "", description: "Suma de todos los proyectos de innovación que existen en la empresa" },
      { number: escalate, label: "Proyectos en etapa escalar", dropTitle: "", description: "Suma de todos los proyectos de innovación que se encuentran en la etapa 'Escalar', etapa donde los proyectos ya generan resultados medibles" },
    );
    this.setState({ kpis });
  }

  openPopper = (index, e) => {
    this.setState({ anchorEl: e.currentTarget, active: index });
  }

  closePopper = () => {
    this.setState({ anchorEl: null, active: undefined });
  }

  screenWidthValidation = () => {
    if(this.props.width === "xs") {
      return true
    }
    return false
  }

  cardWidthMethod = () => {
    if(this.screenWidthValidation()) {
      return window.innerWidth * 0.75
    }
    return cardWidth
  }

  render() {
    const { classes } = this.props;
    const spacing = this.screenWidthValidation() ? 8 : 24    
    return (
      <React.Fragment>
        <Grid container spacing={spacing}>
          {this.state.kpis.map((kpi, index) => {
            return (
              <Grid item xs={"auto"} key={index}>
              <StyledCard onMouseEnter={(e) => this.openPopper(index, e)} onMouseLeave={this.closePopper} className={classes.card} elevation={1} style={index !== this.state.kpis.length - 1 ? { marginRight: "2%", width: this.cardWidthMethod() } : { width: this.cardWidthMethod() }} key={index}>
                <CardContent>
                  <div>
                    <CenterTypography style={{ color: "white", fontSize: "1.4rem", marginRight: "-8px", marginLeft: "-8px" }} variant="headline" noWrap>
                      {kpi.number}
                    </CenterTypography>
                  </div>
                  <div>
                    <CenterTypography style={{ color: "white", marginLeft: "-8px", marginRight: "-8px", textAlign: "center", paddingBottom: "-17px" }} variant="body1">
                      {kpi.label}
                    </CenterTypography>
                    <CenterTypography style={{ color: "white" }} variant="caption">
                      {kpi.dropTitle}
                    </CenterTypography>
                  </div>
                </CardContent>
              </StyledCard>
              </Grid>
            );
          })}
        </Grid>
        {/* <CardContainer>
          
        </CardContainer> */}
        {this.state.active !== undefined && <Popper open={this.state.active !== undefined} anchorEl={this.state.anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography style={{ padding: 5 }}>{this.state.kpis[this.state.active].description}</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    areas: state.projects.companyProjects,
    company: state.companies.company,
    periods: state.periods.periods,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(withWidth()(withStyles(styles)(CompanyKPIComponent)))));
