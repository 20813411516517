import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputAdornment
} from "@material-ui/core";

class EditProjectInvestment extends Component {
  constructor(props) {
    super();
    this.state = {
      investment: props.investment === null ? 0 : props.investment
    };
  }

  onSubmit = () => {
    const { investment } = this.state;
    const states = { investment: Number(investment), editInvestment: false };
    this.props.changeState(states);
  }

  render() {
    const { investment } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} style={{ minWidth: "600px" }}>
        <DialogTitle>Editar inversión estimada</DialogTitle>
        <DialogContent>
          <TextField
            value={investment}
            name="investment"
            type="number"
            fullWidth
            onChange={e => this.setState({ investment: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            style={{ color: "#009688" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={this.onSubmit}
            color="primary"
            style={{ color: "#009688" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditProjectInvestment;
