import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import * as moment from "moment";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Card
} from "@material-ui/core";
import styled from "styled-components";
import { Subtitle, TextStrong } from "../../../helpers/Typography";
const actualYear = moment().format("YYYY");

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    background-color: #1b2642;
    padding: 20px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const colors = ["#b8131b", "#3a528f", "#50aa99", "#1b2642", "#ee9842", "#8c92a0"];

class InputByAreaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        title: {
          text: " ",
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return (this.percentage).toFixed(2) + "%";
              },
              distance: -50,
              filter: {
                property: "percentage",
                operator: ">",
                value: 4
              }
            },
            showInLegend: true
          }
        },
        credits: "",
        series: [
          {
            name: "Monto",
            colorByPoint: true,
            data: []
          }
        ]
      },
      periodId: undefined
    };
  }

  async componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    // if (nextProps.periods) {
    //   nextProps.periods.map(async period => {
    //     if (period.year === `Año ${actualYear}`)
    //       await this.setState({ periodId: period.id });
    //   });
    // }
    const series = [];
    await this.setState({periodId: nextProps.periodId});
    if (nextProps.spotlightProjects && this.state.periodId) {
      nextProps.spotlightProjects.forEach(spotlight => {
        let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        spotlight.Projects.forEach(project => {
          project.EconomicAnalyses.forEach(economicAnalysis => {
            if (economicAnalysis.periodId === this.state.periodId) {
              if (economicAnalysis.analysisTypeId === 1) {
                realBilling[0] = realBilling[0] + economicAnalysis.month1;
                realBilling[1] = realBilling[1] + economicAnalysis.month2;
                realBilling[2] = realBilling[2] + economicAnalysis.month3;
                realBilling[3] = realBilling[3] + economicAnalysis.month4;
                realBilling[4] = realBilling[4] + economicAnalysis.month5;
                realBilling[5] = realBilling[5] + economicAnalysis.month6;
                realBilling[6] = realBilling[6] + economicAnalysis.month7;
                realBilling[7] = realBilling[7] + economicAnalysis.month8;
                realBilling[8] = realBilling[8] + economicAnalysis.month9;
                realBilling[9] = realBilling[9] + economicAnalysis.month10;
                realBilling[10] = realBilling[10] + economicAnalysis.month11;
                realBilling[11] = realBilling[11] + economicAnalysis.month12;
              }
            }
          });
        });
        series.push({
          name: spotlight.name,
          y: realBilling.reduce((x, y) => x + y)
        });
      });
    }
    const newState = {
      data: {
        ...this.state.data,
        series: [
          {
            name: "Monto",
            colorByPoint: true,
            data: series
          }
        ]
      }
    };
    this.setState(newState);
  }

  render() {
    const period = this.props.periods.filter(p => p.id === this.props.periodId);
    return (
      <React.Fragment>
        <Card>
        {this.state.periodId && (
          <Container>
            <Subtitle style={{color: "white"}}>{this.props.company.EconomicIndicator.indicator} real por foco</Subtitle>
            <TextStrong style={{color: "white"}}>{period[0].year}</TextStrong>
            {/* <FormControl>
              <InputLabel>Periodo</InputLabel>
              <Select value={this.state.periodId} onChange={this.handleChange}>
                {this.props.periods.map((period, index) => {
                  return (
                    <MenuItem key={index} value={period.id}>
                      {period.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
          </Container>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.data.series.length > 0 && !this.props.isLoading ? (
          <div style={{padding: "30px"}}>
            <ReactHighcharts config={this.state.data} ref="chart_grades" />
          </div>
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

// Component State
function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    spotlightProjects: state.projects.spotlightProjects,
    company: state.companies.company
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(InputByAreaComponent)));
