import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import { getList } from "../period/api/actions";
import { getById as companyById } from "../company/api/actions";
import { getById } from "./api/actions";
import * as jwtDecode from "jwt-decode";
import * as moment from "moment";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import styled from "styled-components";
const actualYear = moment().format("YYYY");
const actualDay = moment().format("DD");
const today = moment().format("YYYY-MM-DD");
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class AnnualBillingGraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: "line"
        },
        title: {
          text: " ",
          align: "left",
          style: {
            fontFamily: 'Lato',
            fontSize: 22,
            color: "#212F55"
          }
        },
        xAxis: {
          min: 0,
          max: 11,
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
          ],
        },
        yAxis: {
          title: {
            text: '<strong>Monto</strong>',
            style: {
              fontWeight: 'normal'
            }
          },
          labels: {
            formatter: function () {
              return "$ " + (this.value.toLocaleString('es'));
            }
          },
        },
        plotOptions: {
          line: {
            enableMouseTracking: true
          }
        },
        credits: "INNSPIRAL LAB",
        series: []
      },
      periodId: undefined,
      periods: [],
      initialMonth: undefined,
      currency: undefined
    };
  }

  componentDidMount() {
    let series = [];
    let periodId;
    if (this.props.company && this.props.periods && this.props.project) {
      const initialPeriod = moment(new Date(Number(actualYear), Number(this.props.company.InitialMonth.id) - 1, actualDay));
      const startPeriod = initialPeriod.format("YYYY-MM-DD");
      const endPeriod = initialPeriod.add(11, "months").format("YYYY-MM-DD");
      let last = (12 - parseInt(this.props.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      const period = this.props.periods.filter(period => period.year.includes(actualYear));
      periodId = period[0].id;
      if (new Date(endPeriod) < new Date(today))
        periodId = periodId + 1;
      if (new Date(startPeriod) > new Date(today))
        periodId = periodId - 1;
      const periods = this.props.periods.filter(period => period.id <= periodId);
      let xAxis = {
        min: 0,
        max: 11,
        categories: []
      }
      for (let index = 0; index < 12; index++)
        xAxis.categories.push(moment().month(this.props.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      let real = [];
      let estimate = [];
      let lastMonth = undefined;
      this.props.project.EconomicAnalyses.forEach(economicAnalysis => {
        if (economicAnalysis.periodId === periodId) {
          const month1 = economicAnalysis.month1;
          const month2 = month1 + economicAnalysis.month2;
          const month3 = month2 + economicAnalysis.month3;
          const month4 = month3 + economicAnalysis.month4;
          const month5 = month4 + economicAnalysis.month5;
          const month6 = month5 + economicAnalysis.month6;
          const month7 = month6 + economicAnalysis.month7;
          const month8 = month7 + economicAnalysis.month8;
          const month9 = month8 + economicAnalysis.month9;
          const month10 = month9 + economicAnalysis.month10;
          const month11 = month10 + economicAnalysis.month11;
          const month12 = month11 + economicAnalysis.month12;
          const serie = {
            name: economicAnalysis.AnalysisTypeId === 1 ? "Real" : "Proyectado",
            data: [
              month1,
              month2,
              month3,
              month4,
              month5,
              month6,
              month7,
              month8,
              month9,
              month10,
              month11,
              month12
            ],
            color: economicAnalysis.AnalysisTypeId === 1 ? "#1b2642" : "#5477ce"
          };
          if (economicAnalysis.AnalysisTypeId === 1) {
            real = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
            let flag = false;
            real.forEach((real, index) => {
              if (real === null && !flag) {
                lastMonth = index - 1;
                flag = true;
              }
            });
          } else {
            estimate = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
          }
          series.push(serie);
        }
      });
      if (real[11] === null) {
        const bestEstimate = [];
        let totalToThisDay = real.reduce((x, y) => x + y);
        bestEstimate.push(totalToThisDay);
        let estimateSum = 0;
        estimate.forEach((value, index) => {
          if (index > lastMonth) {
            estimateSum = estimateSum + value;
            bestEstimate.push(real.slice(0, index + 1).reduce((x, y) => x + y) + estimateSum);
          }
        });
        series.push({
          name: "Mejor estimado",
          data: bestEstimate,
          pointStart: lastMonth,
          color: "#dde4f5",
          zones: [{
            dashStyle: 'dot'
          }]
        });
      }
      series.forEach(serie => {
        if (serie.name === "Real") {
          serie.data.forEach((data, index) => {
            if (index > lastMonth)
              serie.data[index] = null;
          });
        }
      });
      const newState = {
        data: { ...this.state.data, series, xAxis },
        initialMonth: this.props.company.InitialMonth,
        currency: this.props.company.Currency,
        periodId,
        periods
      }
      this.setState(newState);
    }
  }

  async componentWillReceiveProps(nextProps) {
    let series = [];
    let periodId;
    if (nextProps.company && nextProps.periods) {
      const initialPeriod = moment(new Date(Number(actualYear), Number(nextProps.company.InitialMonth.id) - 1, actualDay));
      const startPeriod = initialPeriod.format("YYYY-MM-DD");
      const endPeriod = initialPeriod.add(11, "months").format("YYYY-MM-DD");
      let last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      const period = nextProps.periods.filter(period => period.year.includes(actualYear));
      periodId = period[0].id;
      if (new Date(endPeriod) < new Date(today))
        periodId = periodId + 1;
      if (new Date(startPeriod) > new Date(today))
        periodId = periodId - 1;
      const periods = nextProps.periods.filter(period => period.id <= periodId);
      await this.setState({ initialMonth: nextProps.company.InitialMonth, currency: nextProps.company.Currency, periodId, periods });
      let xAxis = {
        min: 0,
        max: 11,
        categories: []
      }
      for (let index = 0; index < 12; index++) {
        xAxis.categories.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      }
      const newState = { data: { ...this.state.data, xAxis } };
      this.setState(newState);
    }
    if (nextProps.project) {
      let real = [];
      let estimate = [];
      let lastMonth = undefined;
      let totalReal = undefined;
      let totalEstimate = undefined;
      nextProps.project.EconomicAnalyses.forEach(economicAnalysis => {
        if (economicAnalysis.periodId === periodId) {
          const month1 = economicAnalysis.month1;
          const month2 = month1 + economicAnalysis.month2;
          const month3 = month2 + economicAnalysis.month3;
          const month4 = month3 + economicAnalysis.month4;
          const month5 = month4 + economicAnalysis.month5;
          const month6 = month5 + economicAnalysis.month6;
          const month7 = month6 + economicAnalysis.month7;
          const month8 = month7 + economicAnalysis.month8;
          const month9 = month8 + economicAnalysis.month9;
          const month10 = month9 + economicAnalysis.month10;
          const month11 = month10 + economicAnalysis.month11;
          const month12 = month11 + economicAnalysis.month12;
          const serie = {
            name: economicAnalysis.AnalysisTypeId === 1 ? "Real" : "Proyectado",
            data: [
              month1,
              month2,
              month3,
              month4,
              month5,
              month6,
              month7,
              month8,
              month9,
              month10,
              month11,
              month12
            ],
            color: economicAnalysis.AnalysisTypeId === 1 ? "#1b2642" : "#5477ce"
          };
          if (economicAnalysis.AnalysisTypeId === 1) {
            real = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
            let flag = false;
            real.forEach((real, index) => {
              if (real === null && !flag) {
                lastMonth = index - 1;
                flag = true;
              }
            });
            totalReal = month12;
          } else {
            estimate = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
            totalEstimate = month12;
          }
          series.push(serie);
        }
      });
      if (real[11] === null) {
        const bestEstimate = [];
        let totalToThisDay = real.reduce((x, y) => x + y);
        bestEstimate.push(totalToThisDay);
        let estimateSum = 0;
        estimate.forEach((value, index) => {
          if (index > lastMonth) {
            estimateSum = estimateSum + value;
            bestEstimate.push(real.slice(0, index + 1).reduce((x, y) => x + y) + estimateSum);
          }
        });
        series.push({
          name: "Mejor estimado",
          data: bestEstimate,
          pointStart: lastMonth,
          color: "#dde4f5",
          zones: [{
            dashStyle: 'dot'
          }]
        });
      }
      series.forEach(serie => {
        if (serie.name === "Real") {
          serie.data.forEach((data, index) => {
            if (index > lastMonth)
              serie.data[index] = null;
          });
        }
      });
    }
    const newState = { data: { ...this.state.data, series } };
    this.setState(newState);
  }

  handleChange = async event => {
    await this.setState({ periodId: event.target.value });
    let series = [];
    let real = [];
    let estimate = [];
    let lastMonth = undefined;
    let totalReal = undefined;
    let totalEstimate = undefined;
    this.props.project.EconomicAnalyses.forEach(economicAnalysis => {
      if (economicAnalysis.periodId === event.target.value) {
        const month1 = economicAnalysis.month1;
        const month2 = month1 + economicAnalysis.month2;
        const month3 = month2 + economicAnalysis.month3;
        const month4 = month3 + economicAnalysis.month4;
        const month5 = month4 + economicAnalysis.month5;
        const month6 = month5 + economicAnalysis.month6;
        const month7 = month6 + economicAnalysis.month7;
        const month8 = month7 + economicAnalysis.month8;
        const month9 = month8 + economicAnalysis.month9;
        const month10 = month9 + economicAnalysis.month10;
        const month11 = month10 + economicAnalysis.month11;
        const month12 = month11 + economicAnalysis.month12;
        const serie = {
          name: economicAnalysis.AnalysisTypeId === 1 ? "Real" : "Proyectado",
          data: [
            month1,
            month2,
            month3,
            month4,
            month5,
            month6,
            month7,
            month8,
            month9,
            month10,
            month11,
            month12
          ],
          color: economicAnalysis.AnalysisTypeId === 1 ? "#8c92a1" : "#11AB99"
        };
        if (economicAnalysis.AnalysisTypeId === 1) {
          real = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
          let flag = false;
          real.forEach((real, index) => {
            if (real === null && !flag) {
              lastMonth = index - 1;
              flag = true;
            }
          });
          totalReal = month12;
        } else {
          estimate = Object.keys(economicAnalysis).filter(key => key.includes("month")).map(month => economicAnalysis[month]);
          totalEstimate = month12;
        }
        series.push(serie);
      }
    });
    if (real[11] === null) {
      const bestEstimate = [];
      let totalToThisDay = real.reduce((x, y) => x + y);
      bestEstimate.push(totalToThisDay);
      let estimateSum = 0;
      estimate.forEach((value, index) => {
        if (index > lastMonth) {
          estimateSum = estimateSum + value;
          bestEstimate.push(real.slice(0, index + 1).reduce((x, y) => x + y) + estimateSum);
        }
      });
      series.push({
        name: "Mejor estimado",
        data: bestEstimate,
        pointStart: lastMonth,
        color: "#ba0f0f",
        zones: [{
          dashStyle: 'dot'
        }]
      });
    }
    series.forEach(serie => {
      if (serie.name === "Real") {
        serie.data.forEach((data, index) => {
          if (index > lastMonth)
            serie.data[index] = null;
        });
      }
    });
    const newState = { data: { ...this.state.data, series } };
    this.setState(newState);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.periodId && this.props.changePeriod && (
          <Container>
            <FormControl>
              <InputLabel>Periodo</InputLabel>
              <Select value={this.state.periodId} onChange={this.handleChange}>
                {this.state.periods.map((period, index) => {
                  return (
                    <MenuItem key={index} value={period.id}>
                      {period.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Container>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.data.series.length && !this.props.isLoading ? (
          <ReactHighcharts config={this.state.data} ref="chart_grades" />
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
      </React.Fragment>
    );
  }
}

// Component Properties
AnnualBillingGraphComponent.propTypes = {
  getById: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  changePeriod: PropTypes.bool.isRequired
};

// Component State
function mapStateToProps(state) {
  return {
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    company: state.companies.company
  };
}

export default connect(
  mapStateToProps,
  { getById, getList, companyById }
)(withRouter(withCookies(AnnualBillingGraphComponent)));
