// Imports

// App Imports
import {
  ECONOMIC_ANALYSIS_REQUEST,
  ECONOMIC_ANALYSIS_RESPONSE,
  ECONOMIC_ANALYSIS_FAILURE,
  REGISTER_ECONOMIC_ANALYSIS_REQUEST,
  REGISTER_ECONOMIC_ANALYSIS_RESPONSE,
  REGISTER_ECONOMIC_ANALYSIS_FAILURE,
  EDIT_ECONOMIC_ANALYSIS_REQUEST,
  EDIT_ECONOMIC_ANALYSIS_RESPONSE,
  EDIT_ECONOMIC_ANALYSIS_FAILURE,
} from "./actions";

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  list: [],
  created: undefined
};

// State
export const economicAnalysis = (state = elementsInitialState, action) => {
  switch (action.type) {
    case ECONOMIC_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false,
        created: undefined
      };

    case ECONOMIC_ANALYSIS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        economicAnalysis: action.economicAnalysis,
        created: undefined
      };

    case ECONOMIC_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        errorMessage: action.errorMessage,
        created: undefined
      };
    case REGISTER_ECONOMIC_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false,
        created: undefined
      }
    case REGISTER_ECONOMIC_ANALYSIS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        created: action.created
      };

    case REGISTER_ECONOMIC_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        errorMessage: action.errorMessage,
        created: undefined
      }
    case EDIT_ECONOMIC_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false,
      }
    case EDIT_ECONOMIC_ANALYSIS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case EDIT_ECONOMIC_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
      }
    default:
      return state;
  }
};
