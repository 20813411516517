// Imports
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import './index.css';

// App Imports
import { store } from './setup/store'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import { CookiesProvider } from 'react-cookie';
import createHistory from 'history/createBrowserHistory';
import NewCompanyForm from './components/views/fillForm/newCompany/NewCompanyForm';
import NewCompany from './components/views/fillForm/newCompany/NewCompany';
import NewProject from './components/views/fillForm/newProject/NewProject';
 



const history = createHistory();
// Render App
ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <Router history={history}>
        <App />
        {/* <NewProject/> */}
        {/* <NewCompany /> */}
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
)

// Service Worker
registerServiceWorker()
