// Imports

// App Imports
import {
  MILESTONE_LIST_REQUEST,
  MILESTONE_LIST_RESPONSE,
  MILESTONE_LIST_FAILURE,
  LIST_NEXT_REQUEST,
  LIST_NEXT_RESPONSE,
  LIST_NEXT_FAILURE,
  MILESTONE_STATE_LIST_REQUEST,
  MILESTONE_STATE_LIST_RESPONSE,
  MILESTONE_STATE_LIST_FAILURE,
  CREATE_MILESTONE_REQUEST,
  CREATE_MILESTONE_RESPONSE,
  CREATE_MILESTONE_FAILURE,
  EDIT_MILESTONE_REQUEST,
  EDIT_MILESTONE_RESPONSE,
  EDIT_MILESTONE_FAILURE,
} from './actions'

// Initial State
const elementsInitialState = {
  isLoading: false,
  error: false,
  list: []
}

// State
export const milestones = (state = elementsInitialState, action) => {
  switch (action.type) {
    case MILESTONE_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case MILESTONE_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.list
      }

    case MILESTONE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        errorMessage: action.errorMessage
      }
    case LIST_NEXT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }
    case LIST_NEXT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        nextMilestones: action.nextMilestones
      }
    case LIST_NEXT_FAILURE:
      return {
        ...state,
        isLoading: false,
        nextMilestones: [],
        error: action.error,
        errorMessage: action.errorMessage
      }
    case MILESTONE_STATE_LIST_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }

    case MILESTONE_STATE_LIST_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        state: action.state
      }

    case MILESTONE_STATE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error,
        errorMessage: action.errorMessage
      }
    case CREATE_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }
    case CREATE_MILESTONE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.list,
        nextMilestones: action.nextMilestones
      }
    case CREATE_MILESTONE_FAILURE:
      return {
        ...state,
        isLoading: false,
        milestone: undefined,
        error: action.error,
        errorMessage: action.errorMessage
      }
    case EDIT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: false
      }
    case EDIT_MILESTONE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.list,
        nextMilestones: action.nextMilestones
      }
    case EDIT_MILESTONE_FAILURE:
      return {
        ...state,
        isLoading: false,
        milestone: undefined,
        error: action.error,
        errorMessage: action.errorMessage
      }

    default:
      return state
  }
}