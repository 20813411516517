import React, { Component } from "react";
import { connect } from "react-redux";
import { getToken } from "./api/actions";
import { PropTypes } from "prop-types";
import {
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Card
} from "@material-ui/core";
import DialogError from "./DialogError";
import { withCookies } from "react-cookie";
import { routes } from "../../../setup/routes";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginImage from "../../../images/login-image.png";
import * as jwtDecode from "jwt-decode";

const StyledForm = styled.form`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 30%;
    margin-bottom: 20px;
    @media (min-width: 1082px) {
      margin-top: 50%;
      margin-bottom: 0px;
    }
  }
`;

const SecondaryButton = styled(Button)`
  && {
    margin-top: 16px;
    font-size: 13px;
    min-width: 300px;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    margin-top: 16px;
    min-width: 300px;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    background-color: #5477ce;
    margin-top: 16px;
    min-width: 300px;
    &&:hover {
      background-color: #bbc9ec;
    }
  }
`;

const StyledCard = styled(Card)`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
    /* width: 50%;
    min-width: 300px; */
  }
`;

const CardContainer = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    margin: -20px;
  }
`;

const Content1 = styled.div`
  width: 100%;
  @media (min-width: 1082px) {
    min-width: 400px;
    width: 37%;
  }
`;

const Content2 = styled.div`
  width: 100%;
  @media (min-width: 1082px) {
    min-width: 400px;
    width: 63%;
  }
`;

const Container = styled.div`
  && {
    /* margin: 20px; */
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: ""
      },
      token: undefined
    };
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      const { cookies } = this.props;
      cookies.set("token", nextProps.token);
      await this.setState({ token: nextProps.token });
    }
  }

  handleChange = e => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value }
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    await this.props.getToken(this.state.user);
    if (!this.props.error) {
      const user = jwtDecode(this.state.token).user;
      switch (user.rol) {
        case "Administrador":
          this.props.history.push(routes.admin.base)
          break;
        case "Gerente de Innovación":
          this.props.history.push(routes.company.dashboard)
          break;
        case "Gerente de Área":
          this.props.history.push(routes.company.dashboard)
          break;
        case "Líder de proyecto":
          this.props.history.push(routes.company.dashboard)
          break;
        default:
          console.log("Error");
          break;
      }
    }
  };

  render() {
    return (
      <CardContainer>
        <Content1>
          <StyledCard>
            <Container>
              {this.props.isLoading ?
                <StyledDiv>
                  <CircularProgress size={100} thickness={6} />
                </StyledDiv> :
                <div>
                  <DialogError />
                  <StyledForm onSubmit={this.onSubmit} method="post">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">¡Ingresa tus credenciales!</FormLabel>
                      <FormGroup>
                        <StyledTextField
                          label="Correo electrónico"
                          type="email"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                        />
                        <StyledTextField
                          label="Contraseña"
                          type="password"
                          name="password"
                          id="password"
                          onChange={this.handleChange}
                        />
                        <StyledSubmitButton
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Ingresar
                      </StyledSubmitButton>
                        <SecondaryButton
                          onClick={() => this.props.history.push(routes.forgotPassword)} >
                          Olvidé mi contraseña
                      </SecondaryButton>
                      </FormGroup>
                    </FormControl>
                  </StyledForm>
                </div>}
            </Container>
          </StyledCard>
        </Content1>
        <Content2>
          <img src={LoginImage} style={{ width: "100%", height: "auto" }} />
        </Content2>
      </CardContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    error: state.auth.error,
    isLoading: state.auth.isLoading
  };
}

LoginComponent.propTypes = {
  getToken: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withCookies(
  connect(
    mapStateToProps,
    { getToken }
  )(withRouter(LoginComponent))
);
