// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { getList } from "./api/actions";
import styled from "styled-components";
import { routes } from "../../../setup/routes";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CreateMilestone from '../milestone/CreateComponent';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDivCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  align-content: flex-start;
`;

const StyledCard = styled(Card)`
  width: 350px;
  margin: 7px;
`;

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      create: false,
      project: undefined
    };
  }

  componentDidMount() {
    const token = this.props.cookies.get("token");
    this.props.getList(token);
  }

  handleClose = async () => {
    await this.setState({ create: false });
  }

  render() {
    return (
      <React.Fragment>
        <h1 style={{color: "red"}}>Hacer endpoint para obtener los proyectos de una compañía en específica, por ahora se está obteniendo todos los registrados</h1>
        <CreateMilestone open={this.state.create} project={this.state.project} close={this.handleClose} />
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
          {!this.props.isLoading &&
            this.props.error && (
              <h1>Ocurrió un error intentando recuperar los proyectos</h1>
            )}
        </StyledDiv>
        <StyledDivCard>
          {this.props.list.map((project, index) => {
            return (
              <StyledCard key={index}>
                <CardContent>
                  <Typography style={{ marginBottom: 10 }} variant="title">{project.name}</Typography>
                  <Typography style={{ marginBottom: 10 }} variant="caption">Foco: {project.Spotlight.name}</Typography>
                  <Typography style={{ marginBottom: 10 }} variant="caption">Área: {project.Area.name}</Typography>
                  <Typography variant="caption">Etapa del proyecto: {project.ProjectStep.name}</Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => this.props.history.push(`${routes.projects.base}/${project.id}`)}>
                    Estadísticas generales
                  </Button>
                </CardActions>
              </StyledCard>
            );
          })}
        </StyledDivCard>
      </React.Fragment>
    );
  }
}

ListComponent.propTypes = {
  list: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    list: state.projects.list,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps,
  { getList }
)(withRouter(withCookies(ListComponent)));
