import React from "react";
import styled from "styled-components";
import { Card, Button, Typography } from "@material-ui/core";
import * as jwtDecode from "jwt-decode";
import { withCookies } from 'react-cookie';
import ProjectByAreaTableComponent from "../../common/project/ProjectByAreaTableComponent";
import { getProjects } from "../../common/area/api/actions";
import { getById as companyById } from "../../common/company/api/actions";
import { getList } from "../../common/period/api/actions";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProjectPipelineAreaComponent from "../../common/project/ProjectPipelineAreaComponent";
import { PropTypes } from 'prop-types';
import AreaKPIComponent from "../../common/area/AreaKPIComponent";
import AreaTotalBillingGraphComponent from "../../common/area/AreaTotalBillingGraphComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Title, Subtitle } from '../../../helpers/Typography';

const Container = styled.div`
  && {
    display: flex;
    flex-flow: row wrap;
    flex-direction; row;
    justify-content: space-between;
    margin-bottom: 25px;
  }
`;

const StyledCard = styled(Card)`
  && {
    margin-top: 20px;
    padding: 30px;
    min-width: 275px;
  }
`;

const ViewContainer = styled.div`
  && {
    margin-right: 7%;
    margin-left: 7%;
  }  
`;

const BackButton = styled(Button)`
  && {
    color: #717171;
  }
`;

class AreaDashboard extends React.Component {

  constructor() {
    super();
    this.state = {
      area: undefined
    }
  }

  async componentDidMount() {
    const token = this.props.cookies.get("token");
    const user = jwtDecode(token).user;
    const areaId = this.props.history.location.pathname.replace("/areas/", "");
    const payload = { areaId, companyId: user.companyId };
    await this.props.getList(token);
    await this.props.getProjects(payload, token);
    await this.props.companyById(user.companyId, token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.area) {
      this.setState({ area: nextProps.area })
    }
  }

  render() {
    return (
      <React.Fragment>
        <ViewContainer>
          <Container>
            <div>
              <BackButton onClick={() => this.props.history.goBack()}><FontAwesomeIcon size="2x" icon={faAngleLeft} /></BackButton>
              <Title>{this.state.area ? this.state.area.name : ""}</Title>
            </div>
          </Container>
          <Subtitle>Indicadores clave de rendimiento</Subtitle>
          <AreaKPIComponent />
          <StyledCard>
            <AreaTotalBillingGraphComponent />
          </StyledCard>
          <StyledCard>
            <ProjectPipelineAreaComponent />
          </StyledCard>
          <StyledCard>
            <ProjectByAreaTableComponent />
          </StyledCard>
        </ViewContainer>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.setState({ area: undefined });
  }
}

AreaDashboard.propTypes = {
  getProjects: PropTypes.func.isRequired,
  companyById: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    area: state.area.area,
    isLoading: state.area.isLoading,
    error: state.area.error
  };
}

export default connect(mapStateToProps, { getProjects, companyById, getList })(withRouter(withCookies(AreaDashboard)));