import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import ReactHighcharts from "react-highcharts";
import * as moment from "moment";
import { CircularProgress, Card } from "@material-ui/core";
import styled from "styled-components";
import { Subtitle, TextStrong } from "../../../helpers/Typography";
const actualYear = moment().format("YYYY");
const lastMonth = moment().subtract(1, "month").format("M").toLowerCase();
const ContainerTable = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const CardHeader = styled.div`
  background-color: #1b2642;
  padding: 20px;
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class AnnualBillingGraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        chart: {
          type: "line"
        },
        title: {
          text: " ",
        },
        xAxis: {
          min: 0,
          max: 11,
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
          ]
        },
        yAxis: {
          title: {
            text: '<strong>Monto</strong>',
            style: {
              fontWeight: 'normal'
            }
          },
          labels: {
            formatter: function () {
              return "$ " + (this.value.toLocaleString('es'));
            }
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "MM$ " + (this.y / 1000000).toFixed(1).replace(".", ",");
              },
            },
            enableMouseTracking: true
          }
        },
        credits: "INNSPIRAL LAB",
        series: []
      },
      periodId: undefined
    };
  }

  async componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    let realBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedBilling = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let estimatedAcumulate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (nextProps.periods) {
      nextProps.periods.map(async period => {
        if (period.year === `Año ${actualYear}`)
          await this.setState({ periodId: period.id });
      });
    }
    await this.setState({ periodId: nextProps.periodId });
    let last = 0;
    if (nextProps.company) {
      last = (12 - parseInt(nextProps.company.InitialMonth.id, 10) + parseInt(lastMonth, 10) + 1) % 12;
      if (last === 0)
        last = 12;
      await this.setState({ initialMonth: nextProps.company.InitialMonth, currency: nextProps.company.Currency });
      let xAxis = {
        min: 0,
        max: 11,
        categories: []
      };
      let yAxis = {
        ...this.state.data.yAxis,
        title: {
          text: `<strong>Monto ${nextProps.company.Currency ? "(" + nextProps.company.Currency.name + ")" : ""}</strong>`,
          style: {
            fontWeight: 'normal'
          }
        }
      }
      for (let index = 0; index < 12; index++) {
        xAxis.categories.push(moment().month(nextProps.company.InitialMonth.id).add(index - 1, 'M').format("MMMM"));
      }
      const newState = { data: { ...this.state.data, xAxis, yAxis } };
      this.setState(newState);
    }
    if (nextProps.companyProjects && this.state.periodId) {
      nextProps.companyProjects.forEach(area => {
        area.Projects.forEach(project => {
          project.EconomicAnalyses.forEach(economicAnalysis => {
            if (economicAnalysis.periodId === this.state.periodId) {
              if (economicAnalysis.analysisTypeId === 1) {
                realBilling[0] = realBilling[0] + economicAnalysis.month1;
                realBilling[1] = realBilling[1] + economicAnalysis.month2;
                realBilling[2] = realBilling[2] + economicAnalysis.month3;
                realBilling[3] = realBilling[3] + economicAnalysis.month4;
                realBilling[4] = realBilling[4] + economicAnalysis.month5;
                realBilling[5] = realBilling[5] + economicAnalysis.month6;
                realBilling[6] = realBilling[6] + economicAnalysis.month7;
                realBilling[7] = realBilling[7] + economicAnalysis.month8;
                realBilling[8] = realBilling[8] + economicAnalysis.month9;
                realBilling[9] = realBilling[9] + economicAnalysis.month10;
                realBilling[10] = realBilling[10] + economicAnalysis.month11;
                realBilling[11] = realBilling[11] + economicAnalysis.month12;
              } else {
                estimatedBilling[0] = estimatedBilling[0] + economicAnalysis.month1;
                estimatedBilling[1] = estimatedBilling[1] + economicAnalysis.month2;
                estimatedBilling[2] = estimatedBilling[2] + economicAnalysis.month3;
                estimatedBilling[3] = estimatedBilling[3] + economicAnalysis.month4;
                estimatedBilling[4] = estimatedBilling[4] + economicAnalysis.month5;
                estimatedBilling[5] = estimatedBilling[5] + economicAnalysis.month6;
                estimatedBilling[6] = estimatedBilling[6] + economicAnalysis.month7;
                estimatedBilling[7] = estimatedBilling[7] + economicAnalysis.month8;
                estimatedBilling[8] = estimatedBilling[8] + economicAnalysis.month9;
                estimatedBilling[9] = estimatedBilling[9] + economicAnalysis.month10;
                estimatedBilling[10] = estimatedBilling[10] + economicAnalysis.month11;
                estimatedBilling[11] = estimatedBilling[11] + economicAnalysis.month12;
                estimatedAcumulate[0] = estimatedAcumulate[0] + economicAnalysis.month1;
                estimatedAcumulate[1] = estimatedAcumulate[1] + economicAnalysis.month2;
                estimatedAcumulate[2] = estimatedAcumulate[2] + economicAnalysis.month3;
                estimatedAcumulate[3] = estimatedAcumulate[3] + economicAnalysis.month4;
                estimatedAcumulate[4] = estimatedAcumulate[4] + economicAnalysis.month5;
                estimatedAcumulate[5] = estimatedAcumulate[5] + economicAnalysis.month6;
                estimatedAcumulate[6] = estimatedAcumulate[6] + economicAnalysis.month7;
                estimatedAcumulate[7] = estimatedAcumulate[7] + economicAnalysis.month8;
                estimatedAcumulate[8] = estimatedAcumulate[8] + economicAnalysis.month9;
                estimatedAcumulate[9] = estimatedAcumulate[9] + economicAnalysis.month10;
                estimatedAcumulate[10] = estimatedAcumulate[10] + economicAnalysis.month11;
                estimatedAcumulate[11] = estimatedAcumulate[11] + economicAnalysis.month12;
              }
            }
          });
        });
      });
    }
    realBilling.reduce((x, y, index) => (realBilling[index] = x + y));
    estimatedBilling.reduce((x, y, index) => (estimatedBilling[index] = x + y));
    const bestEstimated = [realBilling[last]];
    let acumulated = realBilling[last];
    estimatedAcumulate.forEach((value, index) => {
      if (index > last) {
        acumulated = acumulated + value;
        bestEstimated.push(acumulated);
      }
    });
    if (nextProps.periodId === nextProps.actualPeriod) {
      realBilling.forEach((real, index) => {
        if (index > last) {
          realBilling[index] = null;
        }
      });
    }
    const series = [
      { name: "Real", data: realBilling, color: "#1b2642" },
      { name: "Presupuestado", data: estimatedBilling, color: "#5477ce" }
    ];
    if (nextProps.periodId === nextProps.actualPeriod) {
      series.push({
        name: "Mejor estimado",
        data: bestEstimated,
        pointStart: last,
        color: "#dde4f5",
        zones: [{
          dashStyle: 'dot'
        }]
      });
    }
    const newState = { data: { ...this.state.data, series } };
    this.setState(newState);
  }

  render() {
    const period = this.props.periods.filter(p => p.id === this.props.periodId);
    return (
      <React.Fragment>
        <Card>
        {this.state.periodId && (
          <CardHeader>
            <ContainerTable>
              <Subtitle style={{color: "white"}}>{this.props.company.EconomicIndicator.indicator} Real vs Presupuestado de los Proyectos</Subtitle>
              <TextStrong style={{color: "white"}}>{period[0].year}</TextStrong>
            </ContainerTable>
          </CardHeader>
        )}
        <StyledDiv>
          {this.props.isLoading && (
            <CircularProgress size={100} thickness={6} />
          )}
        </StyledDiv>
        {this.state.data.series.length && !this.props.isLoading ? (
          <div style={{padding: "20px"}}>
            <ReactHighcharts config={this.state.data} ref="chart_grades" />
          </div>
        ) : (
            <h1>
              {!this.props.isLoading &&
                "No hay información registrada para el periodo seleccionado"}
            </h1>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

// Component State
function mapStateToProps(state) {
  return {
    company: state.companies.company,
    project: state.projects.project,
    periods: state.periods.periods,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    companyProjects: state.projects.companyProjects
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withCookies(AnnualBillingGraphComponent)));
