// Imports
import React, {Component} from 'react'
import { Redirect } from 'react-router-dom';
import { routes } from '../../../setup/routes';
import { withCookies } from 'react-cookie';
import { logout } from './api/actions';
import { connect } from 'react-redux';

// App Imports

// Component
class Logout extends Component {
  render() {
    const { cookies } = this.props;
    cookies.remove("token");
    return (
      <Redirect to={routes.login} />
    )
  }
}

export default connect(null, { logout })(withCookies(Logout));