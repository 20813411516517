import React from 'react';
import ListComponent from '../../common/project/ListComponent';
import ProjectPipelineComponent from '../../common/project/ProjectPipelineComponent';
import ProjectPipelineAreaComponent from '../../common/project/ProjectPipelineAreaComponent';

class ProjectList extends React.Component{
  render(){
    return <React.Fragment>
      <h1>Listado de proyectos</h1>
      <ListComponent />
      <ProjectPipelineComponent />
      <ProjectPipelineAreaComponent />
      <h4>https://www.highcharts.com/demo/area-stacked</h4>
    </React.Fragment>
  }
}

export default ProjectList;