import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import classNames from "classnames";
import {
  Grid,
  Divider,
  TextField,
  MenuItem,
  Button,
  AppBar,
  Toolbar,
  Paper,
  Typography,
  Hidden,
  Icon,
  IconButton
} from "@material-ui/core";
import {
  BigTitle,
  SubHeader,
  Title,
  SubtitleStrong
} from "../../../../helpers/Typography";

import { routesApi, routes } from "../../../../setup/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { withCookies } from "react-cookie";

const styles = theme => ({
  container: {
    display: "grid"
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing.unit
  },

  icon: {
    margin: theme.spacing.unit,
    fontSize: 32
  },

  divider: {
    backgroundColor: "#C6C6C6"
  },
  button: {
    margin: "1em 2em 0em 2.5em",
    textTransform: "none",
    borderWidth: "1px",
    borderColor: "#7894D5 !important",
    borderRadius: "10px",
    padding: "15px",
    backgroundColor: "#7894D5",
    color: "white",
    width: "200px"
  },

  textField: {
    marginLeft: "2.5em",
    marginRight: "2em",

    [`& fieldset`]: {
      borderRadius: "10px",
      borderWidth: "2px"
    }
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#7894D5 !important"
  },
  focused: {
    "& $notchedOutline": {
      borderColor: "#1B2543 !important"
    }
  }
});

const economicIndicator = [
  { id: 1, name: "EBITDA" },
  { id: 2, name: "EBIT" },
  { id: 3, name: "Margen Bruto" },
  { id: 4, name: "Resultado Operacional" },
  { id: 5, name: "Facturación" },
  { id: 6, name: "Utilidad" }
];

const months = [
  { id: 1, month: "Enero" },
  { id: 2, month: "Febrero" },
  { id: 3, month: "Marzo" },
  { id: 4, month: "Abril" },
  { id: 5, month: "Mayo" },
  { id: 6, month: "Junio" },
  { id: 7, month: "Julio" },
  { id: 8, month: "Agosto" },
  { id: 9, month: "Septiembre" },
  { id: 10, month: "Octubre" },
  { id: 11, month: "Noviembre" },
  { id: 12, month: "Diciembre" }
];

const countryCompany = [
  {
    value: "Chile",
    label: "Chile"
  },
  {
    value: "Perú",
    label: "Perú"
  },
  {
    value: "Perú",
    label: "Argentina"
  },
  {
    value: "Perú",
    label: "México"
  },
  {
    value: "Perú",
    label: "Bolivia"
  },
  {
    value: "Perú",
    label: "Estados Unidos"
  }
];

class NewCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      industries: [],
      currencies: [],
      name: "",
      categoryImpact1: 0,
      categoryImpact2: 0,
      currency: 1,
      industry: 1,
      indicator: 1,
      initialMonth: 1,
      area: "",
      spotlight: "",
      areas: [],
      spotlights: [],
      token: props.cookies.get("token"),
    };
    this.getData();
  }

  getData = async () => {
    try {
      const industries = await axios.get(routesApi.industries.base);
      const currencies = await axios.get(routesApi.currencies.base);
      this.setState({
        industries: industries.data.industries,
        currencies: currencies.data.currencies
      });
    } catch (error) {
      return;
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addCompany = async () => {
    const { name, currency, industry, indicator, initialMonth, categoryImpact1, categoryImpact2, areas, spotlights } = this.state;
    const headers = { Authorization: `Bearer ${this.state.token}` };
    try {
      const payload = { 
        name,
        currencyId: currency, 
        industryId: industry, 
        economicIndicatorId: indicator, 
        initialMonthId: initialMonth, 
        areas, 
        spotlights,
        categoryImpact1: 0, 
        categoryImpact2: 0, 
        detectPercentage: 6, 
        devisePercentage: 6, 
        designPercentage: 15, 
        prototypePercentage: 50, 
        escalatePercentage: 90,
        isActive: true,
        foundation: null, 
        phone: null 
      };
      await axios.post(routesApi.companies.base, payload, { headers });
      this.setState({ 
        name: "",
        categoryImpact1: 0,
        categoryImpact2: 0,
        currency: 1,
        industry: 1,
        indicator: 1,
        initialMonth: 1,
        area: "",
        spotlight: "",
        areas: [],
        spotlights: [],
      });
    } catch (error) {
      return;
    }
  };

  validateInputs = () => {
    if(!this.state.name) return true;
    if(!this.state.industry) return true;
    if(!this.state.initialMonth) return true;
    if(!this.state.currency) return true;
    if(!this.state.indicator) return true;
    // if(!this.state.categoryImpact1) return true;
    // if(!this.state.categoryImpact2) return true;
    if(!this.state.areas.length) return true;
    if(!this.state.spotlights.length) return true;
    return false;
  };

  addItem = itemType => {
    const { areas, spotlights } = this.state;
    if (itemType === "area" && this.state.area !== "") {
      areas.push(this.state.area);
      this.setState({ areas, area: "" });
    }
    if (itemType === "spotlight" && this.state.spotlight !== "") {
      spotlights.push(this.state.spotlight);
      this.setState({ spotlights, spotlight: "" });
    }
  };
  
  removeItem = (itemType, index) => {
    const { areas, spotlights } = this.state;
    if (itemType === "area") {
      areas.splice(index, 1);
      this.setState({ areas });
    }
    if (itemType === "spotlight") {
      spotlights.splice(index, 1);
      this.setState({ spotlights });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      currency,
      industry,
      indicator,
      initialMonth,
      currencies,
      industries,
      areas,
      categoryImpact1,
      categoryImpact2,
      spotlights,
      area,
      spotlight
    } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={24} style={{ backgroundColor: "#F9FAFC" }}>
          <Grid item xs={12}>
            <div style={{ margin: "50px 0px 8px 40px" }}>
              {name !== "" ? <BigTitle>{name}</BigTitle> : <br />}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider
              style={{ margin: "0em 3em 1em 3em" }}
              className={classes.divider}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container direction="column" alignItems="flex-start">
              <SubHeader style={{ margin: "0em 0em 0.5em 2.5em" }}>
                Nombre empresa
              </SubHeader>
              <Grid item md={6} xs={6}>
                <TextField
                  required
                  id="companyName"
                  label="Nombre"
                  name="name"
                  defaultValue="Hortifrut"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={name}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                />
              </Grid>
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                Rubro empresa
              </SubHeader>
              <Grid item md={6} xs={6}>
                <TextField
                  id="industry"
                  name="industry"
                  select
                  fullWidth={true}
                  label="Rubro"
                  className={classes.textField}
                  value={industry}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {industries.map(option => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                Moneda utilizada
              </SubHeader>
              <Grid item xs={4} md={6}>
                <TextField
                  id="currency"
                  name="currency"
                  select
                  fullWidth={true}
                  label="Moneda"
                  className={classes.textField}
                  value={currency}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {currencies.map(option => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                Indicador económico
              </SubHeader>
              <Grid item xs={4} md={6}>
                <TextField
                  id="indicator"
                  name="indicator"
                  select
                  fullWidth={true}
                  label="Indicador económico"
                  className={classes.textField}
                  value={indicator}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {economicIndicator.map(option => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                ¿Cuando comienza el año financiero?
              </SubHeader>
              <Grid item xs={4} md={4}>
                <TextField
                  id="initialMonth"
                  name="initialMonth"
                  select
                  fullWidth={true}
                  label="Mes de inicio"
                  className={classes.textField}
                  value={initialMonth}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {months.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.month}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* <SubHeader style={{ margin: "0em 0em 0.5em 2.5em" }}>
                Primer límite de impacto en régimen
              </SubHeader>
              <Grid item md={6} xs={6}>
                <TextField
                  required
                  id="categoryImpact1"
                  label="Primer límite"
                  name="categoryImpact1"
                  type="number"
                  defaultValue={0}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={categoryImpact1}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                />
              </Grid>
              <SubHeader style={{ margin: "0em 0em 0.5em 2.5em" }}>
                Segundo límite de impacto en régimen
              </SubHeader>
              <Grid item md={6} xs={6}>
                <TextField
                  required
                  id="categoryImpact2"
                  label="Segundo límite"
                  name="categoryImpact2"
                  type="number"
                  defaultValue={0}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={categoryImpact2}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container direction="column" alignItems="flex-start ">
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                Áreas de la empresa
              </SubHeader>
              <Grid item md={12} xs={12}>
                <TextField
                  id="area"
                  name="area"
                  label="Áreas"
                  defaultValue=""
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={area}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => this.addItem("area")}>
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                      </IconButton>
                    ),
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                />
                <div
                  style={{
                    margin: "1em 0em 0.5em 2.5em",
                    borderRadius: "25px",
                    border: "2px solid #7894D5 ",
                    padding: "20px",
                    width: "200px",
                    height: "200px",
                    overflowY: 'auto'
                  }}
                >
                  <SubHeader style={{ margin: "5px 0em 10px 10px" }}>
                    Áreas
                  </SubHeader>
                  <Divider
                    style={{ margin: "5px 0px 5px 2px" }}
                    className={classes.divider}
                  />
                  {areas.map((area, index) => (
                    <Grid container key={spotlight + index} alignItems="baseline">
                      <Grid item xs={10}>{area}</Grid>
                      <Grid item xs={2}>
                        <IconButton onClick={() => this.removeItem("area", index)}>
                          <FontAwesomeIcon icon={faMinus} size="xs" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
              <SubHeader style={{ margin: "1em 0em 0.5em 2.5em" }}>
                Focos de innovación
              </SubHeader>
              <Grid item md={12} xs={12}>
                <TextField
                  id="spotlight"
                  name="spotlight"
                  label="Foco de innovación"
                  defaultValue="Génetica"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={spotlight}
                  onChange={e => this.onChange(e)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => this.addItem("spotlight")}>
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                      </IconButton>
                    ),
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    }
                  }}
                />
                <div
                  style={{
                    margin: "1em 0em 0.5em 2.5em",
                    borderRadius: "25px",
                    border: "2px solid #7894D5 ",
                    padding: "20px",
                    width: "200px",
                    height: "200px",
                    overflowY: 'auto'
                  }}
                >
                  <SubHeader style={{ margin: "5px 0em 10px 10px" }}>
                    Focos
                  </SubHeader>
                  <Divider
                    style={{ margin: "5px 0px 5px 2px" }}
                    className={classes.divider}
                  />
                  {spotlights.map((spotlight, index) => (
                    <Grid container key={spotlight + index} alignItems="baseline">
                      <Grid item xs={10}>{spotlight}</Grid>
                      <Grid item xs={2}>
                        <IconButton onClick={() => this.removeItem("spotlight", index)}>
                          <FontAwesomeIcon icon={faMinus} size="xs" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            item
            md={12}
            xs={12}
          >
            <div style={{ margin: "10px 10px 30px 0px" }}>
              <Button 
                variant="contained" 
                className={classes.button} 
                disabled={this.validateInputs()} 
                onClick={() => this.addCompany()}
              >
                Crear empresa
              </Button>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

NewCompanyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default withStyles(styles)(withCookies(NewCompanyForm));
