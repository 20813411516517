import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  && {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #11ab99;
    margin-top: 50px;
    color: white;
    &&:hover {
      background-color: #89d6c7;
    }
  }
`;

class NotFoundView extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Container>
        <strong style={{ fontSize: 100 }}>404</strong>
        <strong style={{ fontSize: 50 }}>Página no encontrada</strong>
        <StyledButton onClick={() => this.props.history.goBack()}>Volver</StyledButton>
      </Container>
    )
  }
}

export default withRouter(NotFoundView)